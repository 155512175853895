import React, { useState ,useEffect} from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import API_BASE_URL from "api";
import axios from "axios";
 import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

import VisibilityIcon from '@mui/icons-material/Visibility';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MDBadge from "components/MDBadge";
import LoadingDialog from "components/Loading/LoadingDialog";

const Exam = () => {
    const [exams,setExams] =  useState([])
    const token =  localStorage.getItem("authToken");
    const targetId =  localStorage.getItem("targetId");
    const [loading,setLoading] = useState(true);

    const navigate = useNavigate()
    
    const [showAlert, setShowAlert] = useState(false);

    const handleViewDetails = (data) => {
      console.log("data:",data)
      navigate(`/exam/${data.exam_id}`,{ state: data });
     

    }
    useEffect(() => {
        const fetchExams = async () => {
          try {
            const response = await axios.get(`${API_BASE_URL}/student/${targetId}/exams`, {
              headers: { "Authorization": `Bearer ${token}` }
            });
            
            setExams(response.data);
          } catch (err) {
            console.error("Failed to fetch Exams:", err);
          } finally {
            setLoading(false)
          }
        };
        fetchExams();
      },[token,targetId]);
    
      useEffect(() => {
        let timer;
        if (showAlert) {
          timer = setTimeout(() => {
            setShowAlert(false);
          }, 5000); // 5000 milliseconds = 5 seconds
        }
        return () => clearTimeout(timer); // Clean up the timer
      }, [showAlert]);

      

      const columns = [
        {
          accessor: 'action',
          Header: '',  // You can leave this blank if you don't want a header
          width: 40,  // Explicit width in pixels
          padding:0,
          Cell: ({ row }) => (
            <PopupState popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                    <MoreVertIcon />
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                   
                  { !row.original.is_taken &&  <MenuItem onClick={() => {
                     
              handleViewDetails(row.original) ;
              popupState.close();
            }} style={{ color: '#1976d2' }}>
              <VisibilityIcon style={{ marginRight: 8 }} /> Take Exam
            </MenuItem> }
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          ),
          showSortIcons: false,
          
          
        },
        { Header: 'Exam Name', accessor: 'exam_name' },
        { Header: 'Exam Date', accessor: 'date',showSortIcons: false, },
        { Header: 'Duration', accessor: 'duration',showSortIcons: false, },
        { Header: 'Exam Type', accessor: 'exam_type',showSortIcons: false, },
        { Header: 'Course Number', accessor: 'course.course_number',showSortIcons: false, },
        { Header: 'Course Name', accessor: 'course.course_name',showSortIcons: false, },
        { Header: 'Instructor Name', accessor: 'instructor.full_name',showSortIcons: false, },
        { Header: 'Questions', accessor: 'questions.length',showSortIcons: false, },
        { Header: 'Status',
          accessor : 'is_taken',
          Cell : ({row}) => (
          
           row.original.is_taken  ?  
           
           <MDBadge  size="small" sx={{background:"#00274D",color:"white",borderRadius:2,p:0.7,fontSize:'10px'}}>
               Exam Taken 
              
            </MDBadge> : 
              <MDBadge   size="small" color="white" sx={{background:"red",opacity:0.7,borderRadius:2,p:0.7,fontSize:'10px'}}> Not Taken </MDBadge> 

           ),
          
          
          showSortIcons: false, },

       
      ];
    

  
  return (
    <DashboardLayout>
    <Card>
      <CardContent>
        <MDTypography variant="h5" fontWeight="medium">
           Exam
         </MDTypography>
        <>
         <MDBox>

 
    <DataTable
      table={{
        columns,
        rows: exams,
      }}
      canSearch={true}
      sortable={true}
      pagination
      rowsPerPage={5}
      showPagination
    />
     {!loading && exams.length === 0 && (
          <MDTypography    variant="h5" display="block" align="center" my={2}  sx={{
            fontStyle: "italic",
            fontWeight: "bold",
            color: "#9e9e9e", // Light grey color
            opacity: 0.7,
          }}>
            No exam available
          </MDTypography>
        )}
           


         </MDBox>
                
        
        
        
        
        </>
      </CardContent>
    </Card>
    <LoadingDialog open={loading} />
</DashboardLayout>
  )
}

export default Exam
import React from 'react';
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useNavigate } from "react-router-dom";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SchoolIcon from '@mui/icons-material/School';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const IntroPage = () => {
  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <MDBox p={3}>
        <Card>
          <MDBox p={3}>
            <MDTypography variant="h3" fontWeight="semibold" gutterBottom color="#00274D" mb={2}>
              Welcome to the Application Portal
            </MDTypography>
            <MDTypography variant="body2" gutterBottom>
              Before you proceed with the application, please prepare the following documents:
            </MDTypography>
           
            <MDBox>
              <MDTypography variant="body2" sx={{ mt: 1 }}>
              <MDBox display="flex" alignItems="center">
      <CheckCircleIcon sx={{ mr: 1, color: '#00274D' }} />
      <strong>A profile picture that clearly shows your face</strong>
    </MDBox>
                <MDBox ml={3}>
                  <ul style={{ listStyleType: 'none', padding: 0 }}>
                    <li>
                      <MDBox display="flex" alignItems="center" mb={1}>
                      <AccountBoxIcon sx={{ mr: 1, color: '#00274D' }} />
                      Profile picture must be ID size and recent
                      </MDBox>
                     
                    </li>
                    
                  </ul>
                </MDBox>
              </MDTypography>
            </MDBox>
            <MDBox>
              <MDTypography variant="body2" sx={{ mt: 1 }}>
              <MDBox display="flex" alignItems="center">
      <CheckCircleIcon sx={{ mr: 1, color: '#00274D' }} />
      <strong>Educational background documents</strong>
    </MDBox>
             
                <MDBox ml={3}>
                  <ul style={{ listStyleType: 'none', padding: 0 }}>
                    <li>
                      <MDBox display="flex" alignItems="center" mb={1}>
                      <SchoolIcon sx={{ mr: 1, color: '#00274D' }} />
                     Certificates for grades 8, 10, 12, and any degree(s)
                      </MDBox>
                     
                    </li>
                    
                  </ul>
                </MDBox>
              </MDTypography>
            </MDBox>
            <MDBox>
              <MDTypography variant="body2" sx={{ mt: 1 }}>
              <MDBox display="flex" alignItems="center">
      <CheckCircleIcon sx={{ mr: 1, color: '#00274D' }} />
      <strong>Other necessary identification documents</strong>
    </MDBox>
             
                <MDBox ml={3}>
                  <ul style={{ listStyleType: 'none', padding: 0 }}>
                    <li>
                      <MDBox display="flex" alignItems="center" mb={1}>
                      <AssignmentIndIcon sx={{ mr: 1, color: '#00274D' }} />
                      Personal statement and Testimony document
                      </MDBox>
                     
                    </li>
                    
                  </ul>
                </MDBox>
              </MDTypography>
            </MDBox>
            <MDBox>
              <MDTypography variant="body2" sx={{ mt: 1 }}>
              <MDBox display="flex" alignItems="center">
      <CheckCircleIcon sx={{ mr: 1, color: '#00274D' }} />
      <strong>Payment confirmation (you can pay using):</strong>
    </MDBox>
              
                <MDBox ml={3}>
                  <ul style={{ listStyleType: 'none', padding: 0 }}>
                    <li>
                      <MDBox display="flex" alignItems="center" mb={1}>
                        <AccountBalanceIcon sx={{ mr: 1, color: '#00274D' }} />
                        <strong>Cash:</strong>
                      </MDBox>
                      <ul style={{ listStyleType: 'none', paddingLeft: 24 }}>
                        <li>CBE: <>1000424242442</></li>
                        <li>Abyssinia: <>1000567890123</></li>
                        <li>Zemen: <>1000789456123</></li>
                      </ul>
                    </li>
                    <li>
                      <MDBox display="flex" alignItems="center" mt={2}>
                        <CreditCardIcon sx={{ mr: 1, color: '#00274D' }} />
                        <strong>Chapa</strong>
                      </MDBox>
                    </li>
                  </ul>
                </MDBox>
              </MDTypography>
            </MDBox>
            <MDBox mt={3}>
                
              <MDTypography variant="body2" color="textSecondary">
                Users accept the responsibility for supplying, checking, and verifying the accuracy and correctness of the information they provide. Incorrect or inaccurate information may result in forfeiture of the application. All fees are non-refundable. Fees shall be forfeited for applicants who fail to show up for their confirmed appointment, applicants who cancel their appointment, and applicants whose application is rejected due to incorrect information.
              </MDTypography>
            </MDBox>
            <MDBox mt={2}>
              <MDButton  variant="gradient"
                       size="small"
                       color="info" onClick={() => navigate('/application')} sx={{background:"#00274D"}}>
                Apply
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default IntroPage;
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import swal from "sweetalert";
import AddIcon from '@mui/icons-material/Add';
import { axiosInstance} from "api2";

const PostAnnouncement = ({ open, onClose, onAdd }) => {
    const targetId = localStorage.getItem("targetId");
   
  const [modalData, setModalData] = useState({
    announced_by: Number(targetId), // Change course_name to announced_by
    announcement_date: "",
    title: "",
    content: "",
  });

  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData({
      ...modalData,
      [name]: value,
    });
    
  };

  const handleModalSubmit = async () => {
    try {

      const response = await axiosInstance.post(`/announcements`, modalData);
      if (response.status === 201) {
        onAdd();
        onClose();
        swal({
          title: "Announcement Created Successfully!",
          icon: "success"
        });
        setModalData({
          
          announcement_date: "",
          title: "",
          content: "",
        });
      }
    } catch (error) {
        console.log("error:",error)
    //   setError(error.response?.data?.errors?.title?.[0]); // Adjusted to get errors for title
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle textAlign={"center"}><AddIcon /> Post Announcement</DialogTitle>
      <DialogContent>
        {/* {error && (
          <MDBox mx={2} mt={2} mb={2}>
            <Alert severity="error" variant="filled">
              {error}
            </Alert>
        </MDBox>
        )} */}
       
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Announcement Date
          </MDTypography>
          <MDInput
            type="date"
            name="announcement_date"
            fullWidth
            required
            value={modalData.announcement_date}
            onChange={handleModalChange}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Title
          </MDTypography>
          <MDInput
            type="text"
            name="title"
            fullWidth
            required
            value={modalData.title}
            onChange={handleModalChange}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Content
          </MDTypography>
          <MDInput
            type="text"
            name="content"
            fullWidth
            required
            multiline
            rows={3}
            value={modalData.content}
            onChange={handleModalChange}
          />
        </MDBox>
      </DialogContent>
      <DialogActions>
        <MDButton
          variant="gradient"
          size="small"
          color="info"
          sx={{ background: "#00274D" }}
          onClick={handleModalSubmit}
        >
          Submit
        </MDButton>
        <MDButton
          variant="gradient"
          size="small"
          color="black"
          sx={{ background: "grey" }}
          onClick={onClose}
        >
          Cancel
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default PostAnnouncement;

import React, { useState ,useEffect,useCallback} from "react";
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import API_BASE_URL from "api";
import axios from "axios";



const Assets = () => {
    const token =  localStorage.getItem("authToken");
    
    const [showAlert, setShowAlert] = useState(false);
    const[asset,setAsset] = useState([])
   

    useEffect(() => {
        let timer;
        if (showAlert) {
          timer = setTimeout(() => {
            setShowAlert(false);
          }, 5000); 
        }
        return () => clearTimeout(timer); 
      }, [showAlert]);
    
      const fetchAsset = useCallback(async () => {
        try {
          const response = await axios.get(`${API_BASE_URL}/assets`, {
            headers: { "Authorization": `Bearer ${token}` }
          });
          
         
        
         
          setAsset(response.data);
        } catch (err) {
          console.error(err);
        } 
       
      },[token]);
      useEffect(() => {

        fetchAsset();
      },[fetchAsset]);


      const columns = [
     
        
        { Header: 'Code', accessor: 'code',showSortIcons: false, },
        { Header: 'Name', accessor: 'name',showSortIcons: false, },
        { Header: 'Current Price', accessor: 'current_price',showSortIcons: false, },
        { Header: 'Status', accessor: 'status',showSortIcons: false, },
        { Header: 'Category', accessor: 'category.name',showSortIcons: false, },
        { Header: 'Location', accessor: 'location.name',showSortIcons: false, },
     
       
      ];
      
  return (


        

        <MDBox >
                <MDTypography variant="h5" fontWeight="medium">
                   Asset
                 </MDTypography>
                 <MDBox>
                
                <MDBox >
                    <DataTable
                      table={{
                        columns,
                        rows: asset
                      }}
                      canSearch={true}
                      sortable={true}
                      pagination
                      rowsPerPage={5}
                      showPagination
                      
                    />
                </MDBox>
                
                </MDBox>
                
        </MDBox>
     
      
  )
}

export default Assets
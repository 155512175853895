import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import React, { useEffect, useState, useCallback } from "react";
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import { axiosInstance } from 'api2';

const AdditionDeduction = ({ id }) => {
  const [remunerations, setRemunerations] = useState([]);

  const fetchRemunerations = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`employees/${id}/remuneration`);
      setRemunerations(response.data);
    } catch (err) {
      console.error(err);
    }
  }, [id]);

  useEffect(() => {
    fetchRemunerations();
  }, [fetchRemunerations]);

  const columns = [
    { Header: 'Title', accessor: 'title' },
    { Header: 'Amount', accessor: 'amount', showSortIcons: false },
    { Header: 'Is Percentage', accessor: 'is_percentage', showSortIcons: false },
    { Header: 'Is Addition', accessor: 'is_addition', showSortIcons: false },
    { Header: 'Is Archived', accessor: 'is_archived', showSortIcons: false },

    // Employee details
    { Header: 'Employee Name', accessor: 'employee.name', showSortIcons: false },
    { Header: 'First Name', accessor: 'employee.first_name', showSortIcons: false },
    { Header: 'Last Name', accessor: 'employee.last_name', showSortIcons: false },
  ];

  return (
    <MDBox>
      <Card>
        <CardContent>
          <MDTypography variant="h6">
            Addition/Deduction
          </MDTypography>
          <MDBox my={2}>
            <DataTable
              table={{
                columns,
                rows: remunerations // Update this to match the state for remunerations
              }}
              canSearch={true}
              sortable={true}
              pagination
              rowsPerPage={5}
              showPagination
            />
          </MDBox>
        </CardContent>
      </Card>
    </MDBox>
  );
}

export default AdditionDeduction;

import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  PDFDownloadLink
} from "@react-pdf/renderer";
import { targetId } from "api2";
import axios from "axios";
import ClearancePDF from 'components/ClearancePDF';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { formatDate } from "date-fns";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import { useCallback, useEffect, useState } from "react";
import API_BASE_URL from "../../../api";
import AddClearanceRequest from "./AddClearanceRequest";

const columns = [
  {
    Header: "",
    accessor: "department",
  },
  { Header: "Person In Charge", accessor: "person" },
  { Header: "Status", accessor: "status" },
  { Header: "Date of Approved", accessor: "date_approved" },
];

const departments = [
  "Dormitory",
  "Library",
  "Finance",
  "General Service",
  "Store",
  "Public Relations",
  "Administration",
  "Student Dean",
  "Academic Dean",
  "Registrar",
];

const ClearancePage = () => {
  const token = localStorage.getItem("authToken");

  const [openAdd, setOpenAdd] = useState(false);

  const [requests, setRequests] = useState([]);
  const [student, setStudent] = useState();
  const [currentYear, setCurrentYear] = useState();
  const [years, setYears] = useState();
  const [rows, setRows] = useState();
  const [allApproved, setAllApproved] = useState(false);

  const fetchRequests = useCallback(async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/student-clearance/${targetId}/requests`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        const { student, clearances } = response.data;
        setRequests(clearances);
        setStudent(student);
        setYears(Object.keys(clearances));
        setCurrentYear(Object.keys(clearances)[0]);
      }
    } catch (err) {
      console.error("Failed to fetch requests:", err);
    }
  }, [token]);

  const handleCloseDialog = () => {
    setOpenAdd(false);
  };

  const handleAddRequest = () => {
    fetchRequests();
  };

  const handleYearChange = (e) => {
    setCurrentYear(e.target.value);
  };

  useEffect(() => {
    fetchRequests();
  }, [fetchRequests]);

  useEffect(() => {
    const getApprovalDetails = (department) => {
      const approvalKey = `approval_${department
        .toLowerCase()
        .replace(" ", "_")}`;
      const approval =
        currentYear &&
        Object.keys(requests).length > 0 &&
        requests[currentYear][0][approvalKey];

      return {
        person:
          approval && approval.user ? approval.user.full_name || "N/A" : "N/A",
        status: approval ? approval.status || "Pending" : "Pending",
        date_approved: approval
          ? formatDate(approval.created_at, "yyyy-M-dd") || "N/A"
          : "N/A",
      };
    };

    const currentRows = departments.map((department) => ({
      department,
      ...getApprovalDetails(department),
    }));

    setRows(currentRows);

    // Check if all departments are approved
    const allApproved = currentRows.every((row) => row.status === "approved");
    setAllApproved(allApproved); // Set the allApproved state

  }, [currentYear, requests]);

  return (
    <DashboardLayout>
      <MDBox p={3}>
        <Card>
          <CardContent>
            <MDBox display="flex" justifyContent="flex-end" gap="12px" mb={2}>
               <Button size="small" sx={{
                  backgroundColor: "#00274D",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#001F3F",
                  },
                }} onClick={() => setOpenAdd(true)}>
                       Request
                    </Button>
              {allApproved && (
                <PDFDownloadLink
                  document={<ClearancePDF rows={rows} student={student} info={{date_requested: requests[currentYear][0].date_requested, year: requests[currentYear][0].year.year_name, reason: requests[currentYear][0].reason}} />}
                  fileName={`grade-report.pdf`}
                >
                  {({ blob, url, loading, error }) => (
                    <MDButton variant="gradient" size="small" color="info">
                      Download PDF
                    </MDButton>
                  )}
                </PDFDownloadLink>
              )}
            </MDBox>
            <MDBox display="flex" gap={5} mb={2}>
              <MDBox sx={{ minWidth: 150 }}>
                <MDTypography variant="h6" fontWeight="medium">
                  Year
                </MDTypography>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <Select
                      value={currentYear}
                      onChange={handleYearChange}
                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      {years &&
                        years.map((year, i) => {
                          return (
                            <MenuItem key={i} value={year}>
                              {year}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Box>
              </MDBox>
              <MDBox sx={{ minWidth: 150 }}>
                <MDTypography variant="h6" fontWeight="medium">
                  Date of Request
                </MDTypography>
                <MDTypography variant="body2" mt={1}>
                  {currentYear &&
                    Object.keys(requests).length > 0 &&
                    requests[currentYear][0].date_requested}
                </MDTypography>
              </MDBox>
              <MDBox sx={{ minWidth: 150 }}>
                <MDTypography variant="h6" fontWeight="medium">
                  Reason
                </MDTypography>
                <MDTypography variant="body2">
                  {currentYear &&
                    Object.keys(requests).length > 0 &&
                    requests[currentYear][0].reason}
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox>
              {currentYear && Object.keys(requests).length > 0 ? (
                <DataTable
                  table={{
                    columns,
                    rows: rows,
                  }}
                  sortable
                  pagination
                  entriesPerPage={{ defaultValue: 10 }}
                  showPagination
                />
              ) : (
                <MDTypography variant="body2">
                  You don't have any request yet.
                </MDTypography>
              )}
            </MDBox>
          </CardContent>
        </Card>
      </MDBox>
      <AddClearanceRequest
        open={openAdd}
        onClose={handleCloseDialog}
        onAdd={handleAddRequest}
      />
    </DashboardLayout>
  );
};

export default ClearancePage;

import React, { useState,useEffect } from 'react'
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import MDBox from "components/MDBox";
import DataTable from 'examples/Tables/DataTable';
import MDButton from "components/MDButton";

const EmployeeLoans = ({openView,onClose,loans}) => {
    const [repayments,setRepayments] = useState([])


    useEffect(() => {
        if (loans) {
          setRepayments(loans);
        }
      }, [loans]);


      const columns = [

        { Header: 'Repayment Amount', accessor: 'repayment_amount',showSortIcons: false,Cell: ({ value }) => {
            return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
          } },
    
        { Header: 'Repayment Date', accessor: 'repayment_date',showSortIcons: false, },
      
       
       
      ];


  return (
    <Dialog open={openView} onClose={onClose} maxWidth="sm">
    <DialogTitle textAlign={"center"}>Loan Repayments</DialogTitle>
    <DialogContent>
          
        
              <MDBox>
              <DataTable
      table={{
        columns,
        rows: repayments,
      }}
      canSearch={true}
      sortable={true}
      rowsPerPage={5}
      showPagination
      
    />
              </MDBox>
             
</DialogContent>
<DialogActions>
        
          <MDButton
            variant="gradient"
            size="small"
            color="black"
            sx={{ background: "grey" }}
            onClick={onClose}
          >
            Close
          </MDButton>
        </DialogActions>
      </Dialog>
      
  )
}

export default EmployeeLoans
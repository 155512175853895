import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from 'components/MDBox';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import React, { useEffect, useState ,useCallback} from "react";
// import ExpandableDescription from "./description";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddProgramDialog from "./addProgram";
import EditProgramsDialog from "./editProgram";

import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import swal from 'sweetalert';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LoadingDialog from 'components/Loading/LoadingDialog';


function AvailablePrograms() {
  const [search] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const [deletingStatus, setDeletingStatus] = useState(false); 
  const [openEdit, setOpenEdit] = useState(false);
  const[programs,setPrograms] = useState([])
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [openAdd, setOpenAdd] = useState(false);

  const token =  localStorage.getItem("authToken");

  useEffect(() => {
    let timer;
    if (showAlert) {
      timer = setTimeout(() => {
        setShowAlert(false);
      }, 5000); // 5000 milliseconds = 5 seconds
    }
    return () => clearTimeout(timer); // Clean up the timer
  }, [showAlert]);
  const fetchPrograms = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/programs`, {
        headers: { "Authorization": `Bearer ${token}` }
      });
      setPrograms(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  },[token]);
  useEffect(() => {
   
    fetchPrograms();
  },[fetchPrograms]);

  
  const handleAddProgram = (newProgram) => {
   fetchPrograms();
  };

  const handleEditProgram = (updatedProgram) => {
    fetchPrograms();

  };

  const handleDeleteProgram = async (id) => {
    const confirm = await swal({
      title: "Are you sure?",
      text: "You will not be able to recover this program!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (confirm) {
      setDeletingStatus(true);
      try {
        const response = await axios.delete(`${API_BASE_URL}/programs/${id}`,
          {
          headers: { "Authorization": `Bearer ${token}` }
        }
      );
        if(response.status === 200){
          fetchPrograms();
          swal({
            title: "Program Deleted Successfully!",
            icon: "success"
          });
          
        }
      } catch (error) {
      
        swal({
          title: `${error.response.data.message}`,
          icon: "warning"
        });
      }finally {
        setDeletingStatus(false); // End loading for deletion
      }
    }
  };

  const handleOpenEdit = (program) => {
    setSelectedProgram(program);
    setOpenEdit(true);
  };

  const handleCloseDialog = () => {
    setOpenAdd(false);
    setOpenEdit(false);
    setSelectedProgram(null);
  };

  const filteredData = programs.filter(program => {
    return (
      program.program_name.toLowerCase().includes(search.toLowerCase()) ||
      program.program_description.toLowerCase().includes(search.toLowerCase())   
    );
  });


  const columns = [
    {
      accessor: 'action',
      Header: '',  // You can leave this blank if you don't want a header
      width: 40,  // Explicit width in pixels
      padding:0,
      Cell: ({ row }) => (
        <PopupState variant="popover"  popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                <MoreVertIcon />
              </Button>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={() => {
                  handleOpenEdit(row.original);
                  popupState.close();
                }}  style={{ color: '#1976d2' }}>
                  <EditIcon style={{ marginRight: 8 }} /> Edit
                </MenuItem>
                <MenuItem onClick={() => {
                  handleDeleteProgram(row.original.id);
                  popupState.close();
                }}  style={{ color: '#d32f2f' }}>
                  <DeleteIcon style={{ marginRight: 8 }} /> Delete
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      ),
      showSortIcons: false,
      
      
    },
    { Header: 'Program Code', accessor: 'program_code' },
    
    { Header: 'Program Name', accessor: 'program_name'},
    { Header: 'Degree Award', accessor: 'degree_award'},
    {
      Header: 'Status',
      accessor: 'status',
     
      Cell: ({ value }) => (
        value === programs.is_active ? <CheckIcon style={{ color: 'green' }} /> : <CloseIcon style={{ color: 'red' }} />
      ),
      showSortIcons: false,
    },
   
  ];


  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="medium">
              Programs
            </MDTypography>
           
              <MDBox>
                <DataTable
                  table={{
                    columns,
                    rows: filteredData,
                  }}
                  canSearch={true}
                  sortable={true}
                  rowsPerPage={5}
                  showPagination
                  widgets = {
                    <MDBox ms={2}>
                   <MDButton 
                   variant="gradient"
                   ml="auto"
                       color="info"
                       size="small"
                       onClick={() => setOpenAdd(true)}
                       sx={{background:"#00274D", my:4,mx:3}}
                 >
                   <span>Add</span>
                 </MDButton>
                 </MDBox>
                  }
                />
                    {!loading && filteredData.length === 0 && (
          <MDTypography    variant="h5" display="block" align="center" my={2}  sx={{
            fontStyle: "italic",
            fontWeight: "bold",
            color: "#9e9e9e", // Light grey color
            opacity: 0.7,
          }}>
            No programs available
          </MDTypography>
        )}
              </MDBox>
              <AddProgramDialog open={openAdd} onClose={handleCloseDialog} onAdd={handleAddProgram} />
              {selectedProgram && (
              <EditProgramsDialog
                open={openEdit}
                onClose={handleCloseDialog}
                course={selectedProgram}
                onEdit={handleEditProgram}
              />
            )}

<LoadingDialog open={loading || deletingStatus} />

    </MDBox>
  );
}

export default AvailablePrograms;

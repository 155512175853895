/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React,{useState,useEffect} from "react";

// @mui material components
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example app
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import PieChart from "examples/Charts/PieChart";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import LoadingDialog from "components/Loading/LoadingDialog";
import { axiosInstance } from "api2";

import MDTypography from "components/MDTypography";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";

function Dashboard() {
  const [loading,setLoading]
 = useState(true);
 const [data,setData] = useState([])

 useEffect(() => {
  const fetchDashboardData = async () => {
    try {
      const response = await axiosInstance.get(`/admin-dashboard`);
      const data = await response.data;

      console.log("data:", data);

      setData({
        students: data.counts?.students ?? 0,  // Ensure counts exists and handle missing data
        courses: data.counts?.courses ?? 0,
        instructors: data.counts?.instructors ?? 0,
        employee: data.employees ?? [],  // Ensure employees exists
        leaveRequests: data.leave_count ?? 0,  // Ensure leave_count exists
        payroll: data.total_net_pay ?? 0,  // Ensure total_net_pay exists
        announcements: data.announcements ?? [],  // Ensure announcements exists
        payrolls: data.payrolls ?? [] , // Ensure payrolls exists,
        programData:data.programData,
        students2:data.latestStudents,
        enrollmentData:data.enrollmentData
      });
   
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    } finally {
      setLoading(false);
    }
  };

  fetchDashboardData();
}, []);


const columns = [
  {
    accessor: "action",
    Header: "",
    width: 10,
    padding: 0,
    showSortIcons: false,
    Cell: ({ row }) => (
      <PopupState popupId="demo-popup-menu">
        {(popupState) => (
          <React.Fragment>
            <Button
              color="secondary"
              {...bindTrigger(popupState)}
              style={{ padding: 0, minWidth: 0 }}
            >
              <MoreVertIcon />
            </Button>
            <Menu {...bindMenu(popupState)}>
              <MenuItem style={{ color: "#1976d2" }}>
                <EditIcon style={{ marginRight: 8 }} /> Edit
              </MenuItem>
              <MenuItem style={{ color: "#d32f2f" }}>
                <DeleteIcon style={{ marginRight: 8 }} /> Delete
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    ),
  },
  {
    Header: 'Name',
    accessor: row => `${row.first_name} ${row.last_name}`, // Custom accessor for full name
    id: 'full_name', // Optional: Use an id for the column
    sorted: "none",
  },
  { Header: "Email", accessor: "email_1" },
  { Header: "Phone", accessor: "phone" },
  { Header: "Gender", accessor: "gender", showSortIcons: false },
  { Header: "Program ", accessor: "program.program_name", showSortIcons: false },
  { Header: "Status", accessor: "status", showFilterIcons: false },
];

const total2 = [
    {
      label: "Total Students",
      count: data.students || 0, // Dynamically set the total students
      icon: "person",
      color: "info",
    },
    {
      label: "Total Courses",
      count: data.courses || 0, // Dynamically set the total courses
      icon: "library_books",
      color: "info",
    },
    {
      label: "Total Instructors",
      count: data.instructors || 0, // Dynamically set the total instructors
      icon: "school",
      color: "info",
    },
   
  ];


  const programData = {
    labels: data.programData?.map((method) => method.name), // Extract labels
    datasets: 
      {
        label: "Programs",
        backgroundColors: ["info", "primary", "success", "warning"],
        data: data.programData?.map((method) => method.value), // Extract values
      },
    
  };



  // Dynamically generate the labels and datasets
 const countReactDashboard2 = {
    labels: data.enrollmentData?.map((entry) => entry.year), // Extract years for the labels
    datasets: [
      {
        label: "Male",
        color: "info",
        data: data.enrollmentData?.map((entry) => entry.male), // Extract male enrollment counts
      },
      {
        label: "Female",
        color: "success",
        data: data.enrollmentData?.map((entry) => entry.female), // Extract female enrollment counts
      },
    ],
  };
  
  

  return (
    <DashboardLayout>
  

      {/* Dashboard */}
      <Grid container spacing={3} pt={6}>
        {/* Charts */}
        <Grid item xs={12} lg={9}>
          <Grid container spacing={3}>
            {total2.map((item, index) => {
              return (
                <Grid item xs={true} key={index}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color={item.color}
                      icon={item.icon}
                      title={item.label}
                      count={item.count}
                     
                    />
                  </MDBox>
                </Grid>
              );
            })}
          </Grid>
          <Grid container spacing={3} pt={6}>
            <Grid item xs={12} lg={8}>
              <VerticalBarChart
                icon={{ color: "info", component: "leaderboard" }}
                title="Total Students"
                description="Student Count by Each Year"
                chart={countReactDashboard2}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <PieChart
                icon={{ color: "info", component: "group" }}
                title="Programs"
                description="Total Programs in Each Branch"
                chart={programData}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Announcements */}
        <Grid item xs={12} lg={3}>
          <Grid item xs={12}>
            
            <Card>
            <CardContent>
              <MDTypography variant="h5" fontWeight="medium" sx={{ mb: 2 }}>
              Announcements
                        </MDTypography>
                {data.announcements?.map((item, index) => {
                  return (
                    <MDBox key={item.id} sx={{ mb: 2, p: 2, bgcolor: '#ffffff', borderRadius: 1, boxShadow: 1 }}>
                <MDTypography variant="h6" fontWeight="medium">
                  {item.title}
                </MDTypography>
                <MDTypography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                  <strong>Announced By:</strong> {item.announced_by} | <strong>Date:</strong> {new Date(item.announcement_date).toLocaleDateString()}
                </MDTypography>
                <MDTypography variant="body2" sx={{ color: 'text.primary' }}>
                  {item.content}
                </MDTypography>
              </MDBox>
                  );
                })}
            </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      {/* Table  */}
      <MDBox py={6}>
        <Card>
          <CardContent>
            <MDTypography variant="h6" gutterBottom>
              Students
            </MDTypography>
            <DataTable
              table={{
                columns,
                rows: data.students2 || [],
              }}
              canSearch={true}
              sortable={true}
              pagination
              rowsPerPage={5}
              showPagination
            />
          </CardContent>
        </Card>
      </MDBox>
      <LoadingDialog open={loading} />

      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;

import React from 'react';
import { CircularProgress } from '@mui/material';
import MDBox from 'components/MDBox';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';

const LoadingComponent = () => {
  return (
    <DashboardLayout>
      <MDBox display="flex" justifyContent="center" alignItems="center" height="200px">
        <CircularProgress />
      </MDBox>
    </DashboardLayout>
  );
};

export default LoadingComponent;

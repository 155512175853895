import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from 'components/MDBox';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { useEffect, useState } from "react";
import swal from 'sweetalert';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddInstructorDialog from "./addInstructor";
import EditInstructorDialog from "./editInstructor";
import LoadingDialog from 'components/Loading/LoadingDialog';


const Instructor = () => {
    const token =  localStorage.getItem("authToken");
    
    const [showAlert, setShowAlert] = useState(false);

  
    const [openEdit, setOpenEdit] = useState(false);
    const[instructor,setInstructor] = useState([])
    const [selectedInstructor, setSelectedInstructor] = useState(null);
    const [openAdd, setOpenAdd] = useState(false)
    const [loading, setLoading] = useState(true); // Loading state
    const [deletingStatus, setDeletingStatus] = useState(false); 

    useEffect(() => {
        let timer;
        if (showAlert) {
          timer = setTimeout(() => {
            setShowAlert(false);
          }, 5000); // 5000 milliseconds = 5 seconds
        }
        return () => clearTimeout(timer); // Clean up the timer
      }, [showAlert]);
    
      useEffect(() => {
        const fetchInstructor = async () => {
          try {
            const response = await axios.get(`${API_BASE_URL}/instructors`, {
              headers: { "Authorization": `Bearer ${token}` }
            });
            
           
    
            setInstructor(response.data);
          } catch (err) {
            console.error(err);
          } finally {
            setLoading(false)
          }
         
        };
        fetchInstructor();
      }, [token]);

      const handleAddInstructor = (newInstructor) => {
        
        setInstructor((prevInstructor) => [...prevInstructor, newInstructor]);
      };


      const handleEditInstructor = (updatedInstructor) => {
        setInstructor((prevInstructors) =>
          prevInstructors.map((p) =>
            p.id === updatedInstructor.id ? updatedInstructor : p
          )
        );
      };

      const handleDeleteInstructor = async (id) => {
        const confirm = await swal({
          title: "Are you sure?",
          text: "You will not be able to recover this Instructor!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        });
    
        if (confirm) {
          setDeletingStatus(true);
          try {
            const response = await axios.delete(`${API_BASE_URL}/instructors/${id}`,
              {
              headers: { "Authorization": `Bearer ${token}` }
            }
          );
            if(response.status === 204){
              setInstructor(instructor.filter((i) => i.id !== id));
              swal({
                title: "Instructor Deleted Successfully!",
                icon: "success"
              });
            }
          } catch (error) {
          
            swal({
              title: `${error.response.data.message}`,
              icon: "warning"
            });
          } finally {
            setDeletingStatus(false); // End loading for deletion
          } 
        }
      };

      const handleOpenEdit = (instructor) => {
        setSelectedInstructor(instructor);
        setOpenEdit(true);
      };

      const handleCloseDialog = () => {
        setOpenAdd(false);
        setOpenEdit(false);
        setSelectedInstructor(null);
      };

      const columns = [
        {
          accessor: 'action',
          Header: '',  
          width: 40, 
          padding:0,
          Cell: ({ row }) => (
            <PopupState variant="popover"  popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                    <MoreVertIcon />
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem onClick={() => {
                      handleOpenEdit(row.original);
                      popupState.close();
                    }}  style={{ color: '#1976d2' }}>
                      <EditIcon style={{ marginRight: 8 }} /> Edit
                    </MenuItem>
                    <MenuItem onClick={() => {
                      handleDeleteInstructor(row.original.id);
                      popupState.close();
                    }}  style={{ color: '#d32f2f' }}>
                      <DeleteIcon style={{ marginRight: 8 }} /> Delete
                    </MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          ),
          showSortIcons: false,
          
          
        },
        
        { Header: 'Full name', accessor: 'full_name',showSortIcons: false, },
        { Header: 'Email', accessor: 'email',showSortIcons: false, },
        { Header: 'Phone Number', accessor: 'phone_number',showSortIcons: false, },
        { Header: 'Qualification', accessor: 'qualification',showSortIcons: false, },
     
       
      ];
      
  return (
        <MDBox >
                <MDTypography variant="h5" fontWeight="medium">
                   Instructor
                 </MDTypography>
                 <MDBox>
                
                 <MDBox>

      <DataTable
        table={{
          columns,
          rows: instructor,
        }}
        canSearch={true}
        sortable={true}
        rowsPerPage={5}
        showPagination
        widgets={
          <MDBox mx={2}>
            <MDButton
              variant="gradient"
              color="info"
              ml="auto"
              onClick={() => setOpenAdd(true)}
              sx={{ background: "#00274D", my: 4, mx: 3 }}
            >
              Add
            </MDButton>
          </MDBox>
        }
      />
      {!loading && instructor.length === 0 && (
          <MDTypography    variant="h5" display="block" align="center" my={2}  sx={{
            fontStyle: "italic",
            fontWeight: "bold",
            color: "#9e9e9e", // Light grey color
            opacity: 0.7,
          }}>
            No data available
          </MDTypography>
        )}
   
</MDBox>

                
                </MDBox>
                <AddInstructorDialog
                  open={openAdd}
                  onAdd={handleAddInstructor}
                  onClose={handleCloseDialog}
                
                />
                {selectedInstructor && (
                <EditInstructorDialog
                  open={openEdit}
                  onClose={handleCloseDialog}
                  instructor={selectedInstructor}
                  setInstructor={setInstructor}
                  onEdit={handleEditInstructor}
                />
                )}
                 <LoadingDialog open={loading || deletingStatus} />
        </MDBox>
  )
}

export default Instructor
import AddIcon from "@mui/icons-material/Add";
import {
  Autocomplete,
  TextField
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import LoadingDialog from "components/Loading/LoadingDialog";

// Axios instance for API requests
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    "Content-Type": "application/json",
  },
});



const AddPositionDialog = ({ open, onClose, onAdd }) => {
  const [modalData, setModalData] = useState({
    position_title: "",
    job_description: "",
    salary_range_min: 0,
    salary_range_max: 0,
    reports_to: null,
  });

  const [positions, setPositions] = useState([]);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false); // State to track loading

  const token = localStorage.getItem("authToken");

  // Fetch positions for Autocomplete dropdown
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get("/positions");
        setPositions(response.data); // Set the positions data
      } catch (err) {
        console.error("Failed to fetch positions:", err);
      }
    };

    fetchData();
  }, []);

  // Handle form input changes
  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData({
      ...modalData,
      [name]: value,
    });
    setError({
      ...error,
      [name]: "", // Clear the error for this field when it's being modified
    });
  };

  // Handle form submission
  const handleModalSubmit = async () => {
    if (modalData.position_title=== "" ||
      modalData.job_description=== "" ||
      modalData.salary_range_min=== 0 ||
      modalData.salary_range_max=== 0) {
       alert("Please add all required fields to continue");
     }
     else{
    try {
      setLoading(true)
      
      const response = await axios.post(`${API_BASE_URL}/positions`, modalData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      

      if (response.status === 201) {
        onAdd(response.data);
        onClose();
        swal({
          title: "Position Created Successfully!",
          icon: "success",
        });

        // Reset form data
        setModalData({
          position_title: "",
          job_description: "",
          salary_range_min: 0,
          salary_range_max: 0,
          reports_to: null,
        });
        setError({});
      }
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const errors = err.response.data.error;
        setError(errors); // Set the error object
      } else {
        console.error("Error submitting form:", err);
      }
    } finally{
      setLoading(false)
    }
  }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle textAlign={"center"}>
        <AddIcon /> Add Position
      </DialogTitle>
      <DialogContent>
        {/* Position Title */}
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Position Name
          </MDTypography>
          <MDInput
            type="text"
            name="position_title"
            fullWidth
            required
            value={modalData.position_title}
            onChange={handleModalChange}
            error={Boolean(error.position_title)}
            helperText={error.position_title}
          />
        </MDBox>

        {/* Job Description */}
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Job Description
          </MDTypography>
          <MDInput
            type="text"
            name="job_description"
            fullWidth
            required
            value={modalData.job_description}
            onChange={handleModalChange}
            error={Boolean(error.job_description)}
            helperText={error.job_description}
          />
        </MDBox>

        {/* Minimum Salary */}
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Min Salary
          </MDTypography>
          <MDInput
            type="number"
            name="salary_range_min"
            fullWidth
            required
            value={modalData.salary_range_min}
            onChange={handleModalChange}
            error={Boolean(error.salary_range_min)}
            helperText={error.salary_range_min}
          />
        </MDBox>

        {/* Maximum Salary */}
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Maximum Salary
          </MDTypography>
          <MDInput
            type="number"
            name="salary_range_max"
            fullWidth
            required
            value={modalData.salary_range_max}
            onChange={handleModalChange}
            error={Boolean(error.salary_range_max)}
            helperText={error.salary_range_max}
          />
        </MDBox>

        {/* Reports To (Autocomplete for selecting a manager) */}
        <MDBox mb={2}>
        
  <Autocomplete
    options={positions}
    getOptionLabel={(option) => option.position_title} // Display the position title in the dropdown
    onChange={(event, newValue) => {
      setModalData({
        ...modalData,
        reports_to: newValue ? newValue.id : null, // Set reports_to to the selected position's id
      });
    }}
    renderInput={(params) => <TextField {...params} label="Reports To" />}
  />
</MDBox>

      </DialogContent>

      {/* Actions */}
      <DialogActions>
        <MDButton
          variant="gradient"
          size="small"
          color="info"
          sx={{ background: "#00274D" }}
          onClick={handleModalSubmit}
        >
          Submit
        </MDButton>
        <MDButton
          variant="gradient"
          size="small"
          color="black"
          sx={{ background: "grey" }}
          onClick={onClose}
        >
          Cancel
        </MDButton>
      </DialogActions>
      <LoadingDialog open={loading} />
    </Dialog>
  );
};

export default AddPositionDialog;

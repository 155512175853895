import { CardContent } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import {
  addEducationalBackground,
  deleteEducationalBackground
} from "../../../../../redux/features/employeeSlice";
import AddChildren from "../children";
import LoadingDialog from "components/Loading/LoadingDialog";

  
const EducationBackground = () => {
    const employees = useSelector((state) => state.employee);
    const [loading, setLoading] = useState(false);
    const token =  localStorage.getItem("authToken");
    const [currentEducation, setCurrentEducation] = useState({
      level: "",
      educational_institution: "",
      education_type: "",
      upto_level: "",
      attended_year: 2012,
      document_path: null
      });
      const [fileName, setFileName] = useState('');

  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const educationalBackgrounds = useSelector((state) => state.employee.educational_backgrounds);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentEducation((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFileName(file.name); 
    setCurrentEducation(prev => ({
      ...prev,
      document_path: file
    }));
  };

  const handleAddEducation = () => {
    
    if (currentEducation.attended_year === ""
     ||currentEducation.level === "" || currentEducation.upto_level === "" ||currentEducation.educational_institution === "" ||
    currentEducation.education_type === "" || currentEducation.document_path === null) {
      alert("Please add all required fields to continue");
    }
    else{      
    dispatch(addEducationalBackground(currentEducation));
    setCurrentEducation({
      level: "",
      educational_institution: "",
      education_type: "",
      upto_level: "",
      attended_year: 2012,
      document_path: null
    });

    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the file input
    }
  }
  };
  const handleDeleteEducation = (row) => {    
    dispatch(deleteEducationalBackground(row.values));
  };
  

  useEffect(() => {
    if (educationalBackgrounds.length > 0) {
    }
  }, [educationalBackgrounds]);

 
   const handleBack = () => {
     navigate("/employees/create/1");
   };



   const handleSubmit = async () => {
    setLoading(true)
    
     const formData = new FormData();
     Object.entries(employees).forEach(([key, value]) => {
       if (Array.isArray(value)) {
         value.forEach((item, index) => {
           for (const itemKey in item) {
             if (itemKey === "document_path" && item[itemKey] instanceof File) {
               formData.append(`${key}[${index}][${itemKey}]`, item[itemKey]);
             }
             if (itemKey === "birth_certificate" && item[itemKey] instanceof File) {
               formData.append(`${key}[${index}][${itemKey}]`, item[itemKey]);
             } else {
               formData.append(`${key}[${index}][${itemKey}]`, item[itemKey]);
             }
           }
         });
       } else {
         formData.append(key, value);
       }
     });
  
     try {
        const response = await axios.post(
            `${API_BASE_URL}/employees`,
            formData,
            {
                headers: { "Authorization": `Bearer ${token}` ,
                "Content-Type": "multipart/form-data", 

              }
            }
          ); 
          
          if (response.status === 201) {
            swal({
              title: "Employee Created Successfully!",
              icon: "success"
            }).then(() => {
              // Navigate to the employees page
              navigate('/employees');
              // Refresh the page
              window.location.reload();
            });
       
          navigate('/employees')

     }
     } catch (error) {
       console.error("Error creating employee:", error);
     }finally {
      setLoading(false); // Stop loading
    }
   }



   


  return (
    <DashboardLayout>
      <Card>
        <CardContent>
            <MDBox>
            <MDTypography variant="h5" fontWeight="bold" gutterBottom>
                  Educational Background
                </MDTypography>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={2}>
                      <MDTypography variant="caption" color="text" fontWeight="medium">
                        Level<span style={{ color: "red" }}>*</span>
                      </MDTypography>
                      <MDInput
                        type="text"
                        label="level"
                        name="level"
                        value={currentEducation.level}
                        onChange={handleInputChange}
                        fullWidth
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={2}>
                      <MDTypography variant="caption" color="text" fontWeight="medium">
                        Educational Institution <span style={{ color: "red" }}>*</span>
                      </MDTypography>
                      <MDInput
                        type="text"
                        label="Educational Institution"
                        name="educational_institution"
                        value={currentEducation.educational_institution}
                        onChange={handleInputChange}
                        fullWidth
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={2}>
                      <MDTypography variant="caption" color="text" fontWeight="medium">
                        Education Type <span style={{ color: "red" }}>*</span>
                      </MDTypography>
                      <MDInput
                        type="text"
                        label="education type"
                        name="education_type"
                        value={currentEducation.education_type}
                        onChange={handleInputChange}
                        fullWidth
                      />
                    </MDBox>
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={2}>
                      <MDTypography variant="caption" color="text" fontWeight="medium">
                        Up to Level <span style={{ color: "red" }}>*</span>
                      </MDTypography>
                      <MDInput
                        type="text"
                        label="up to level"
                        name="upto_level"
                        value={currentEducation.upto_level}
                        onChange={handleInputChange}
                        fullWidth
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={2}>
                      <MDTypography variant="caption" color="text" fontWeight="medium">
                        Years attended from <span style={{ color: "red" }}>*</span>
                      </MDTypography>
                      <MDInput
                        type="date"
                        name="attended_year"
                        value={currentEducation.attended_year}
                        onChange={handleInputChange}
                        fullWidth
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={2}>
                      <MDTypography variant="caption" color="text" fontWeight="medium">
                        Upload Document <span style={{ color: "red" }}>*</span>
                      </MDTypography>
                      <input
                        type="file"
                        name="document"
                        accept=".jpg,.jpeg,.png,.pdf"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        style={{ display: "block", marginTop: 8 }}
                      />
                    </MDBox>
                    {
                        fileName && (
                          <MDTypography variant="caption" color="text" fontWeight="medium" style={{ marginTop: 8 }}>
                           { fileName && <p>Uploaded File: {fileName}</p>}
                        </MDTypography>
                        )
                      }
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDButton variant="gradient" color="info" size="small" onClick={handleAddEducation}>
                      Add
                    </MDButton>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DataTable
                    table={{
                      columns: [
                        { Header: "level", accessor: "level", width: "25%" },
                        { Header: "Educational institution", accessor: "educational_institution", width: "30%" },
                        { Header: "education type", accessor: "education_type" },
                        { Header: "up to level", accessor: "upto_level", width: "12%" },
                        { Header: "Years", accessor: "attended_year", width: "12%" },
                        {
                          Header: "Document",
                          accessor: "document",
                          Cell: ({ row }) => {
                            const document = row.original.document_path; // Access the original document object
                            return <span>{document ? document.name : "No Document"}</span>; // Display the document name or a placeholder
                          },
                        },                    {
                          Header: "Actions",
                          accessor: "actions",
                          Cell: ({ row }) => (
                            <MDButton
                              variant="outlined"
                              color="error"
                              onClick={() => handleDeleteEducation(row)}
                            >
                              Delete
                            </MDButton>
                          ),
                        },
                      ],
                      rows: educationalBackgrounds,
                    }}
                  />
                </Grid>

            </MDBox>
            <AddChildren sx={{marginY:5}} />
            <MDBox
              sx={{ paddingY: 2,marginY: -2 }}
              position="absolute"
              bottom={{ xs: -8, md: 16 }}
              right={{ xs: 66, md: 76 }}
              display="flex"
              gap={2}
              flexDirection={{ xs: "column", md: "row" }}
              alignItems={{ xs: "center", md: "flex-start" }}
            >
              <MDButton
                variant="gradient"
                color="light"
                size="small"
                onClick={handleBack}
              >
                Go Back
              </MDButton>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                onClick={handleSubmit}
              >
                Submit
              </MDButton>
            </MDBox>
            <LoadingDialog open={loading} />
        </CardContent>
      </Card>
    </DashboardLayout>
  )
}

export default EducationBackground
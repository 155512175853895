import { Assignment, Book, People, School } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Avatar, Button, Card, CardContent, Grid, Menu, MenuItem } from '@mui/material';
import API_BASE_URL, { STORAGE_BASE_URL } from 'api';
import axios from 'axios';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { useEffect, useState,useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Bar, BarChart, CartesianGrid, Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const PrincipalDashboard = () => {
  const navigate = useNavigate();
  const [search] = useState('');
  const[applicants,setApplicants] = useState([])

  const token =  localStorage.getItem("authToken");
  const [announcements, setAnnouncements] = useState([]);
   const [enrollmentData,setEnrollmentData] = useState([]);
   const [programData,setProgramData] = useState([]);
   const [counts,setCountsData] = useState([]);


  useEffect(() => {
    const fetchApplicants = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/applicant-student`, 
          
          {
          headers: { "Authorization": `Bearer ${token}` }
        });
        setApplicants(response.data);
      } catch (err) {
      } finally {
      }
    };
    fetchApplicants();
  }, [token]);
  
  const filteredData = applicants.filter(registrar => {
    return (
      registrar.first_name.toLowerCase().includes(search.toLowerCase()) ||
      registrar.email_1.toLowerCase().includes(search.toLowerCase()) 
     
    );

  });
  const handleViewDetail = (person) => {
    navigate('/viewApplicantDetail', { state: { person } });

  }

  const handleTransaction = async (payment) => {    

    if (payment != null && payment.transaction_id !== null ){
      try {
         if (payment.payment_method === "Chapa") {

        await axios.get(`${API_BASE_URL}/callback/${payment.transaction_id}`);    

   
         }
         else{
    


         }

      } catch (err) {
        console.error(err);
      }
    }
  };

  // eslint-disable-next-line no-sparse-arrays
  const columns = [ 
    {
      accessor: 'action',
      Header: '', 
      width:40,
      Cell: ({ row }) => (
        <PopupState popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                <MoreVertIcon />
              </Button>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={popupState.close}>Enter Entrance Result</MenuItem>
                <MenuItem onClick={popupState.close}>Enter Interview Result</MenuItem>
                <MenuItem onClick={popupState.close}>Approve</MenuItem>
                <MenuItem onClick={() => {
                  handleViewDetail(row.original);
                  popupState.close();
                }}>View Detail</MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      ),
      showSortIcons: false,
      
    },
    {
      Header: "Profile",
      id: "profile",
      accessor: row  => (
        <Avatar src={`${STORAGE_BASE_URL}/${row.profile_picture}`} />
      ),
      sortable: false,
    },
    {
      Header: 'Name',
      accessor: row => `${row.first_name} ${row.last_name}`, // Custom accessor for full name
      id: 'full_name', // Optional: Use an id for the column
      sorted: "none",
    },
    { Header: 'Telephone', accessor: 'phone', showSortIcons: false, },
    { Header: 'Gender', accessor: 'gender' },
    { Header: 'Email', accessor: 'email_1' },
    { Header: 'Application Date', accessor: 'application_date' },

    {
      Header: 'Application Program',
      id:'application_program',
      accessor: row => (
        <Link to={`/programs/${row.program.id}`}>
          {row.program ? row.program.program_name : null}
        </Link>
      ),
      sortable: true,
    },  
    
    { Header: 'Postal Address', accessor: 'postal_address' },
    {
      Header: 'Application Fee',
      id: 'application_fee',
      accessor: row => (
        <MDButton onClick={() => handleTransaction(row.payments.length ? row.payments[0] : null)}>
          {row.payments.length ? row.payments[0].transaction_id : 'No Transaction'}
        </MDButton>
      ),
    }

  ];
  

const [visibleData, setVisibleData] = useState({
  male: true,
  female: true,
});



// Toggle visibility
const handleLegendClick = (dataKey) => {
  setVisibleData((prevState) => ({
      ...prevState,
      [dataKey]: !prevState[dataKey],
  }));
};
const fetchData = useCallback(async () => {
  const response = await axios.get(`${API_BASE_URL}/principal-dashboard`,{
    headers:{
      "Authorization":`Bearer ${token}`
    }
  });
  
  setEnrollmentData(response.data.enrollmentData);
  setCountsData(response.data.counts);
  setProgramData(response.data.programData);
  setAnnouncements(response.data.announcements)

},[token])
useEffect(() => {
  fetchData();
},[fetchData])



// Border color for the cards
const borderColor = '#3f51b5'; // Change this color as needed
  return (
    <DashboardLayout>
     
    <Grid container spacing={3} justifyContent="center">
        {/* Dashboard Header */}
            {/* Row 1: Four Stats Cards */}
            <Grid item xs={12} sm={12} md={12} lg={9}>

            <Grid container item spacing={3}>
                {/* Students Card */}
                <Grid item xs={12} sm={3}>
                    <Link to={"/students"}>
                    <Card style={{ borderLeft: `5px solid ${borderColor}` }}>
                        <CardContent style={{ textAlign: 'left' }}>
                            <People style={{ fontSize: 40, color: borderColor }} />
                            <MDTypography variant="h5" component="h2" gutterBottom>
                                {counts.students}
                            </MDTypography>
                            <MDTypography variant="body2">Students</MDTypography>
                        </CardContent>
                    </Card>
                    </Link>

                </Grid>

                {/* Courses Card */}
                <Grid item xs={12} sm={3}>
                <Link to={"/coursesAcademics"}>
                    <Card style={{ borderLeft: `5px solid ${borderColor}` }}>
                        <CardContent style={{ textAlign: 'left' }}>
                            <Book style={{ fontSize: 40, color: borderColor }} />
                            <MDTypography variant="h5" component="h2" gutterBottom>
                                {counts.employees}
                            </MDTypography>
                            <MDTypography variant="body2">Employees</MDTypography>
                        </CardContent>
                    </Card>
                    </Link>
                </Grid>
                

                {/* Instructors Card */}
                <Grid item xs={12} sm={3}>
                <Link to={{ pathname: "/coursesAcademics", state: { active: 6 } }}>     
                               <Card style={{ borderLeft: `5px solid ${borderColor}` }}>
                        <CardContent style={{ textAlign: 'left' }}>
                            <School style={{ fontSize: 40, color: borderColor }} />
                            <MDTypography variant="h5" component="h2" gutterBottom>
                                {counts.instructors}
                            </MDTypography>
                            <MDTypography variant="body2">Instructors</MDTypography>
                        </CardContent>
                    </Card>
                    </Link>
                </Grid>

                {/* Applications Card */}
                <Grid item xs={12} sm={3}>
                <Link to={"/applicantMangement"}>

                    <Card style={{ borderLeft: `5px solid ${borderColor}` }}>
                        <CardContent style={{ textAlign: 'left' }}>
                            <Assignment style={{ fontSize: 40, color: borderColor }} />
                            <MDTypography variant="h5" component="h2" gutterBottom>
  {new Intl.NumberFormat('en-ET').format(counts.payments)} Birr
</MDTypography>



                            <MDTypography variant="body2">Total Income</MDTypography>
                        </CardContent>
                    </Card>
                    </Link>
                </Grid>
            </Grid>

            {/* Other content like charts and applicant list can follow here */}

        {/* Row 1: Two Charts */}
        <Grid container item spacing={3} mt={1}>
            {/* First Chart: Enrollment Trends */}
            <Grid item xs={12} sm={6} md={6}>
            <Card>
                <CardContent>
                    <MDTypography variant="h5" gutterBottom>
                        Enrollment Trends
                    </MDTypography>
                    <ResponsiveContainer width="100%" height={300}>
    <BarChart data={enrollmentData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="year" />
        <YAxis />
        <Tooltip />
        <Legend
            onClick={({ dataKey }) => handleLegendClick(dataKey)}
            formatter={(value, entry) => (
                <span
                    style={{
                        textDecoration: visibleData[entry.dataKey] ? 'none' : 'underline',
                        cursor: 'pointer',
                        color: entry.color,
                    }}
                >
                    {value}
                </span>
            )}
        />
        {visibleData.male && (
            <Bar dataKey="male" fill="#3f51b5" name="Male" />
        )}
        {visibleData.female && (
            <Bar dataKey="female" fill="#e91e63" name="Female" />
        )}
    </BarChart>
</ResponsiveContainer>
                </CardContent>
            </Card>
        </Grid>
            {/* Second Chart: Program Distribution */}
            <Grid item xs={12} sm={6} md={6}>
                <Card>
                    <CardContent>
                        <MDTypography variant="h5" gutterBottom>
                            Branch Distribution
                        </MDTypography>
                        <ResponsiveContainer width="100%" height={300}>

                        <ResponsiveContainer width="100%" height={300}>
    <PieChart>
        <Pie
            data={programData}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={entry => entry.name}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
        >
            {programData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={['#ff6384', '#36a2eb', '#ffce56', '#4bc0c0'][index % 4]} />
            ))}
        </Pie>
        <Tooltip />
        <Legend />
    </PieChart>
</ResponsiveContainer>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        </Grid>
        {/* <Grid item xs={12} sm={12} md={12} lg={3}>
          <Grid item xs={12} md={12}>
                  <MDTypography variant="h4" align="center" gutterBottom>
                      Top Products
                  </MDTypography>
              </Grid>
        </Grid> */}
        <Grid item xs={12} sm={12} md={12} lg={3}>
        {/* Announcements Header */}
        <Card sx={{my:2}}>
        <CardContent>
        <MDTypography variant="h4" align="center" gutterBottom>
                      Top Products
                  </MDTypography>
          </CardContent>
          </Card>
        <Card>
          <CardContent>
      

            <Grid item xs={12} md={12}>
                <MDTypography variant="h4" align="center" gutterBottom>
                    Announcements
                </MDTypography>
            </Grid>

            {/* Announcements List */}
            {announcements.map((announcement, index) => (
                <Grid item xs={12} sm={12} md={12} key={index}>
                    <Card style={{ borderLeft: '5px solid #3f51b5', marginBottom: '18px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                        <CardContent>
                            <MDTypography variant="h6" gutterBottom>
                                {announcement.title}
                            </MDTypography>
                            <MDTypography variant="body2" color="textSecondary" gutterBottom>
                                <strong>Announced By:</strong> {announcement.user.full_name}
                            </MDTypography>
                            <MDTypography variant="body2" color="textSecondary" gutterBottom>
                                <strong>Date:</strong> {announcement.announcement_date}
                            </MDTypography>
                            <MDTypography variant="body1">
                                {announcement.content}
                            </MDTypography>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
                        
          </CardContent>
        </Card>
        </Grid>

        {/* Row 2: List of Latest Applicants */}
        <Grid item xs={12}>
            <Card>
                <CardContent>
                <MDTypography variant="h5" fontWeight="medium">
              Applicants
            </MDTypography>
              <MDBox mt={1}>
                <DataTable
                  table={{
                    columns,
                    rows: filteredData,
                  }}
                  canSearch={true}
                  sortable={true}
                  rowsPerPage={5}
                  showPagination
                />
              </MDBox>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    </DashboardLayout>
  )
}

export default PrincipalDashboard
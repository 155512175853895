import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import React, { useEffect, useState, useCallback } from "react";
import AddIcon from '@mui/icons-material/Add';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { Card, CardContent } from '@mui/material';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import LoanRepayment from './loanRepayment';
import { axiosInstance } from 'api2';
import ViewRepayments from './viewRepayments';

import VisibilityIcon from '@mui/icons-material/Visibility';
import LoadingDialog from 'components/Loading/LoadingDialog';


const LoanRequest = () => {
    const [loanRequests, setLoanRequests] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const targetId = localStorage.getItem('targetId')
    const [openAdd, setOpenAdd] = useState(false);
    const [selectedLoan, setSelectedLoan] = useState(null);
    const [openView, setOpenView] = useState(false);
    const [employeeLoans, setEmployeeLoans] = useState([]);
    const [loading,setLoading] = useState(true);
    


    useEffect(() => {
        let timer;
        if (showAlert) {
          timer = setTimeout(() => {
            setShowAlert(false);
          }, 5000); // 5000 milliseconds = 5 seconds
        }
        return () => clearTimeout(timer); // Clean up the timer
      }, [showAlert]);
    
      const fetchLoans = useCallback(async () => {
        try {
          const response = await axiosInstance.get(`/employees/${targetId}/loans`);
        
          setLoanRequests(response.data);
        } catch (err) {
          console.error(err);
        } finally {
          setLoading(false)
        }
      }, [targetId]);
    
      useEffect(() => {
        fetchLoans();
      }, [fetchLoans]);

      const handleViewDetails = (data) => {
        console.log("data:",data.repayments)
        setEmployeeLoans(data.repayments);
        setOpenView(true);
      };

      const handleAddLoans = () => {
        fetchLoans();
      };

      const handleCloseDialog = () => {
        setOpenAdd(false);
        // setOpenEdit(false);
        setOpenView(false);
        setSelectedLoan(null);
      };

      const handleOpenAdd = (loan) => {
        setSelectedLoan(loan);
        setOpenAdd(true);
      };

      const updatedLoansData = loanRequests.map((loan) => {
        const totalRepayment = loan.repayments.reduce((total, repayment) => {
          return total + parseFloat(repayment.repayment_amount);
        }, 0);
        
        const loanAmount = parseFloat(loan.loan_amount);
        const remaining = loanAmount - totalRepayment;
      
        return {
          ...loan,
          remaining: remaining.toFixed(2), // Format to 2 decimal places
        };
      });

      const columns = [
        {
          accessor: 'action',
          Header: '',
          width: 40,
          padding: 0,
          Cell: ({ row }) => (
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                    <MoreVertIcon />
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                  <MenuItem onClick={() => {
                      handleOpenAdd(row.original);
                      popupState.close();
                    }} style={{ color: '#1976d2' }}>
                      <AddIcon style={{ marginRight: 8 }} /> Pay Loan
                    </MenuItem>
                    <MenuItem onClick={() => {
                  handleViewDetails(row.original);
                  popupState.close();
                }} style={{ color: '#1976d2' }}>
                  <VisibilityIcon style={{ marginRight: 8 }} /> View Details
                </MenuItem>
                    
                    
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          ),
          showSortIcons: false,
        },
        {
            Header: 'Employee Name',
            accessor: 'employee',
            showSortIcons: false,
            Cell: ({ row }) => {
              const employee = row.original.employee;
              return employee ? `${employee.first_name} ${employee.last_name}` : 'N/A';
            }
          },
        { Header: 'Loan Amount', accessor: 'loan_amount',showSortIcons:false, Cell: ({ value }) => {
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
      }},
        { Header: 'Loan Interest Rate', accessor: 'loan_interest_rate',showSortIcons:false },
        { Header: 'Loan Start Date', accessor: 'loan_start_date',showSortIcons:false },
        { Header: 'Loan End Date', accessor: 'loan_end_date',showSortIcons:false },
        // loan_status
        {
            Header: 'Loan Status',
            accessor: 'loan_status',
            showSortIcons: false,
            Cell: ({ value }) => {
              let color;
              
              // Determine the color based on loan status
              if (value === 'paid_off') {
                color = 'orange'; // Color for paid_off
              } else if (value === 'active') {
                color = 'green'; // Color for active
              } else {
                color = 'yellow'; // Default color for other statuses
              }
          
              return <span style={{ color }}>{value}</span>;
            }
          },
        
        {
            Header: "Remaining",
            accessor: 'repayments',
            showSortIcons: false,
            Cell: ({ row }) => {
              const loanAmount = parseFloat(row.original.loan_amount);
            
              const totalRepayment = row.original.repayments.reduce((sum, repayment) => {
                return sum + parseFloat(repayment.repayment_amount);
              }, 0);
              const remainingAmount = loanAmount - totalRepayment;
        
              return <span>{remainingAmount.toFixed(2)}</span>;
            }
          },
          { Header: 'Remark', accessor: 'remark',showSortIcons:false },
          { 
            Header: 'Remaining', 
            accessor: 'remaining',
            showSortIcons: false,
            Cell: ({ value }) => {
              return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
            }
          }
      ];
    
  return (
    <DashboardLayout>
      <Card>
        <CardContent>
          <MDBox>
            <MDTypography variant="h5" fontWeight="medium">
              Loans
            </MDTypography>
            <MDBox>
        
              <DataTable
                table={{
                  columns,
                  rows: updatedLoansData,
                }}
                canSearch={true}
                sortable={true}
                rowsPerPage={5}
                showPagination
               
              />
               {!loading &&  updatedLoansData.length === 0 && (
          <MDTypography    variant="h5" display="block" align="center" my={2}  sx={{
            fontStyle: "italic",
            fontWeight: "bold",
            color: "#9e9e9e", // Light grey color
            opacity: 0.7,
          }}>
            Leave Balances not available
          </MDTypography>
        )}
            </MDBox>
            {/* Add Loan Dialog */}
            <LoanRepayment open={openAdd} onClose={handleCloseDialog} onAdd={handleAddLoans} loan={selectedLoan} />
          
            {
              employeeLoans && (
                <ViewRepayments loans={employeeLoans} openView={openView} onClose={handleCloseDialog} />
              )
            }
           
          </MDBox>
        </CardContent>
        <LoadingDialog open={loading} />
      </Card>
    </DashboardLayout>
  )
}

export default LoanRequest
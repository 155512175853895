import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClearIcon from '@mui/icons-material/Clear';
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addEducationalBackground,
  deleteEducationalBackground
} from "../../../redux/features/educationalBackgroundsSlice";
import { updateStudentInfo } from "../../../redux/features/studentInfoSlice";



function ApplicationsPage2() {
  const [currentEducation, setCurrentEducation] = useState({
    country: "",
    educational_institution: "",
    study_program: "",
    major: "",
    class_of_degree: "",
    attended_year: 0,
    document: null,
  });
  const [fileName, setFileName] = useState('');
  const [branches,setBranches] = useState([])

  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const educationalBackgrounds = useSelector((state) => state.educationalBackgrounds);
  const [educationList, setEducationList] = useState([] || educationalBackgrounds);

  const student = useSelector((state) => state.studentInfo);

  const [selectedPrograms, setSelectedPrograms] = useState({});

  const programId= student.programId
  


  useEffect(() => {
    const fetchBranches = async () => {
      try{
      const response = await axios.get(`${API_BASE_URL}/branches-list`);
      console.log("branches:",response.data)
      setBranches(response.data);
      }catch(err){
        console.log(err)
      }
      
    }
    fetchBranches()
  }, []);

  useEffect(() => {
    console.log("Current programId:", programId);

    // Initialize selectedPrograms based on the program_id from Redux
    if (programId) {
      const selectedCampus = branches.find(campus =>
        campus.programs.some(program => program.id === programId)
      );
      if (selectedCampus) {
        setSelectedPrograms((prevState) => ({
          ...prevState,
          [selectedCampus.id]: programId,
        }));
      }
    }
  }, [programId, branches]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentEducation((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFileName(file.name); 
    setCurrentEducation(prev => ({
      ...prev,
      document: file
    }));
  };

  const handleChange = (campusId, programId) => {
    // setSelectedPrograms((prev) => ({ ...prev, [programId]: true }));
    setSelectedPrograms((prevState) => ({
      ...prevState,
      [campusId]: programId,
    }));

    dispatch(
      updateStudentInfo({
        program_id: programId,
      })
    );
  };

  const handleAddEducation = () => {
    
    if (currentEducation.attended_year === "" || currentEducation.class_of_degree === "" ||  currentEducation.class_of_degree === 0
     ||currentEducation.name === "" ||currentEducation.educational_institution === "" ||
    currentEducation.major === "" || currentEducation.document === null) {
      alert("Please add all required fields to continue");
    }
    else{      
    dispatch(addEducationalBackground(currentEducation));
    setCurrentEducation({
      country: "",
      educational_institution: "",
      study_program: "",
      major: "",
      class_of_degree: "",
      attended_year: 0,
      document: null,
    });

    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the file input
    }
  }
  };
  const handleDeleteEducation = (row) => {     
    dispatch(deleteEducationalBackground(row.values));
  };
  

  useEffect(() => {
    if (educationalBackgrounds.length > 0) {
      setEducationList(educationalBackgrounds);
    }
  }, [educationalBackgrounds]);


  const handleNext = () => {

   if (educationList.length <= 0 ) {
      alert("please add at least one education")
   } else if (student.program_id === null){
    alert("please select at least one program")
   } else {
    navigate("/applicationPage3");

   }
  };

  const handleBack = () => {
    navigate("/application");
  };

 

  return (
    <DashboardLayout>
      <Card>
        <MDBox pt={4} pb={3} px={3} position="relative">
          <MDBox component="form" id="studentForm2" role="form">
            <MDTypography variant="h5" fontWeight="bold" gutterBottom>
              Educational Background
            </MDTypography>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    City/Country<span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="text"
                    label="City"
                    name="country"
                    value={currentEducation.country}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Educational Institution <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="text"
                    label="Educational Institution"
                    name="educational_institution"
                    value={currentEducation.educational_institution}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Study Program <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="text"
                    label="Study Program"
                    name="study_program"
                    value={currentEducation.study_program}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Majors in <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="text"
                    label="Majors"
                    name="major"
                    value={currentEducation.major}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Class of Degree <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="text"
                    label="Class of Degree"
                    name="class_of_degree"
                    value={currentEducation.class_of_degree}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Years attended from <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="date"
                    name="attended_year"
                    value={currentEducation.attended_year}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Upload Document <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <input
                    type="file"
                    name="document"
                    accept=".jpg,.jpeg,.png,.pdf"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: "block", marginTop: 8 }}
                  />
                </MDBox>
                {
                    fileName && (
                      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ marginTop: 8 }}>
                       { fileName && <p>Uploaded File: {fileName}</p>}
                    </MDTypography>
                    )
                  }
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDButton variant="gradient" color="info" size="small" onClick={handleAddEducation}>
                  Add
                </MDButton>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <DataTable
                table={{
                  columns: [
                    { Header: "City/Country", accessor: "country", width: "25%" ,},
                    { Header: "Educational institution", accessor: "educational_institution", width: "30%" },
                    { Header: "Study program", accessor: "study_program" },
                    { Header: "Majors", accessor: "major", width: "12%" ,showSortIcons:false},
                    { Header: "Class of Degree", accessor: "class_of_degree", width: "12%",showSortIcons:false },
                    { Header: "Years", accessor: "attended_year", width: "12%",showSortIcons:false },
                    {
                      Header: "Document",
                      accessor: "document",
                      showSortIcons:false,
                      Cell: ({ row }) => {
                        const document = row.original.document; // Access the original document object
                        return <span>{document ? document.name : "No Document"}</span>; // Display the document name or a placeholder
                      },
                    },                    {
                      Header: "Actions",
                      accessor: "actions",
                      showSortIcons:false,
                      Cell: ({ row }) => (
                        <MDButton
                          variant="outlined"
                          color="error"
                          onClick={() => handleDeleteEducation(row)}
                        >
                          Delete
                        </MDButton>
                      ),
                    },
                  ],
                  rows: educationList,
                }}
              />
            </Grid>
              <MDTypography variant="h6" fontWeight="semibold" gutterBottom>
                Application For
              </MDTypography>

              <Grid container spacing={3}>
                {branches.map((campus) => {
                  const isAnyProgramSelected = Object.values(selectedPrograms).some(value => value !== "");
                  const isProgramSelectedForThisCampus = selectedPrograms[campus.id] !== undefined && selectedPrograms[campus.id] !== "";
                  return (
                  <Grid item xs={12} md={6}  key={campus.id}>
                  <MDBox mb={2}>
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                      {campus.name} {campus.branch_category.toLowerCase()}
                    </MDTypography>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <InputLabel id={`${campus.name.toLowerCase()}-label`}  sx={{ 
        backgroundColor: '#f5f5f5', 
        px: 1,
        mt: 0.7,
        transform: 'translate(14px, -9px) scale(0.75)',
        '&.Mui-focused': {
          transform: 'translate(14px, -9px) scale(0.75)',
        },
        '&.MuiInputLabel-shrink': {
          transform: 'translate(14px, -9px) scale(0.75)',
        }
      }}>
                        Select Program
                      </InputLabel>
                      <Select
                        labelId={`${campus.name.toLowerCase()}-label`}
                        id={`${campus.name.toLowerCase()}-select`}
                        label={`${campus.name}`}
                        value={selectedPrograms[campus.id] || ""}
                        onChange={(e) => handleChange(campus.id, e.target.value)}
                        disabled={isAnyProgramSelected && !isProgramSelectedForThisCampus}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#f5f5f5",
                          padding: "10px",
                          borderRadius: 1,
                          "& .MuiSelect-select": {
                            display: "flex",
                            alignItems: "center",
                            paddingRight: "32px",
                          },
                        }}
                        IconComponent={() => (
                          <ArrowDropDownIcon
                            sx={{
                              position: "absolute",
                              right: 8,
                              pointerEvents: "none",
                            }}
                          />
                        )}
                        endAdornment={
                          isProgramSelectedForThisCampus && (
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                handleChange(campus.id, "");
                              }}
                              sx={{
                                position: 'absolute',
                                right: 32,
                                top: '50%',
                                transform: 'translateY(-50%)',
                              }}
                            >
                              <ClearIcon />
                            </IconButton>
                          )
                        }
                      >
                        {campus.programs.length > 0 ? (
                          campus.programs.map((program) => (
                            <MenuItem
                              key={program.id}
                              value={program.id || student.program_id}
                              disabled={
                                selectedPrograms &&
                                Object.values(selectedPrograms).some(
                                  (selectedId) => selectedId === program.id
                                )
                              }
                            >
                              {program.program_name}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>No programs available</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </MDBox>
                </Grid>
                )})}
              </Grid>

              <MDBox
                position="absolute"
                // style={{ float: "left" }}
                bottom={{ xs: 6, md: 40 }}
                right={76}
                display="flex"
                gap={2}
              >
                <Grid item xs={12} md={6} lg={4}>
                  <MDButton variant="gradient" color="light" size="small" onClick={handleBack}>
                    Back
                  </MDButton>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <MDButton variant="gradient" color="info" size="small" onClick={handleNext}>
                    Next
                  </MDButton>
                </Grid>
              </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default ApplicationsPage2;

import React, { useState ,useEffect,useCallback} from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MDBox from 'components/MDBox';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import API_BASE_URL from "api";
import axios from "axios";
import swal from 'sweetalert';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddAssetDialog from "./create";
import EditAssetDialog from "./edit";
import LoadingDialog from "components/Loading/LoadingDialog";


const Asset = () => {
    const token =  localStorage.getItem("authToken");
    
    const [showAlert, setShowAlert] = useState(false);
    const [loading, setLoading] = useState(false); 
    const [openEdit, setOpenEdit] = useState(false);
    const[asset,setAsset] = useState([])
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [openAdd, setOpenAdd] = useState(false)

    useEffect(() => {
        let timer;
        if (showAlert) {
          timer = setTimeout(() => {
            setShowAlert(false);
          }, 5000); // 5000 milliseconds = 5 seconds
        }
        return () => clearTimeout(timer); // Clean up the timer
      }, [showAlert]);
    
      const fetchAsset = useCallback(async () => {
     setLoading(true);
        try {
          const response = await axios.get(`${API_BASE_URL}/assets`, {
            headers: { "Authorization": `Bearer ${token}` }
          });
          
         
        
         
          setAsset(response.data);
        } catch (err) {
          console.error(err);
        } finally{
          setLoading(false);
        }
       
      },[token]);
      useEffect(() => {

        fetchAsset();
      },[fetchAsset]);

      const handleAddAsset = (newAsset) => {
        
        setAsset((prevAsset) => [...prevAsset, newAsset]);
        fetchAsset();
      };


      const handleEditAsset = (updatedAsset) => {
        setAsset((prevAssets) =>
          prevAssets.map((p) =>
            p.id === updatedAsset.id ? updatedAsset : p
          )
        );
        fetchAsset();

      };

      const handleDeleteAsset = async (id) => {
        const confirm = await swal({
          title: "Are you sure?",
          text: "You will not be able to recover this Asset!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        });
    
        if (confirm) {
          setLoading(true);
          try {
            const response = await axios.delete(`${API_BASE_URL}/assets/${id}`,
              {
              headers: { "Authorization": `Bearer ${token}` }
            }
          );
            if(response.status === 204){
              setAsset(asset.filter((i) => i.id !== id));
              swal({
                title: "Asset Deleted Successfully!",
                icon: "success"
              });
            }
          } catch (error) {
          
            swal({
              title: `${error.response.data.message}`,
              icon: "warning"
            });
          }finally{
            setLoading(false);
          }
        }
      };

      const handleOpenEdit = (asset) => {
        setSelectedAsset(asset);
        setOpenEdit(true);
      };

      const handleCloseDialog = () => {
        setOpenAdd(false);
        setOpenEdit(false);
        setSelectedAsset(null);
      };

      const columns = [
        {
          accessor: 'action',
          Header: '', 
          width: 40,  
          padding:0,
          Cell: ({ row }) => (
            <PopupState popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                    <MoreVertIcon />
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem onClick={() => {
                      handleOpenEdit(row.original);
                      popupState.close();
                    }}  style={{ color: '#1976d2' }}>
                      <EditIcon style={{ marginRight: 8 }} /> Edit
                    </MenuItem>
                    <MenuItem onClick={() => {
                      handleDeleteAsset(row.original.id);
                      popupState.close();
                    }}  style={{ color: '#d32f2f' }}>
                      <DeleteIcon style={{ marginRight: 8 }} /> Delete
                    </MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          ),
          showSortIcons: false,
          
          
        },
        
        { Header: 'Code', accessor: 'code',showSortIcons: false, },
        { Header: 'Name', accessor: 'name',showSortIcons: false, },
        { Header: 'Current Price', accessor: 'current_price',showSortIcons: false, },
        { Header: 'Status', accessor: 'status',showSortIcons: false, },
        { Header: 'Category', accessor: 'category.name',showSortIcons: false, },
        { Header: 'Location', accessor: 'location.name',showSortIcons: false, },
     
       
      ];
      
  return (

    <DashboardLayout>
        <Card>
            <CardContent>

        <MDBox >
                <MDTypography variant="h5" fontWeight="medium">
                   Asset
                 </MDTypography>
                 <MDBox>
                
                <MDBox >
                    <DataTable
                      table={{
                        columns,
                        rows: asset
                      }}
                      canSearch={true}
                      sortable={true}
                      pagination
                      rowsPerPage={5}
                      showPagination
                      widgets = {
                        <MDBox mx={2}>
                       <MDButton
                        variant="gradient"
                        color="info"
                        ml="auto"
                        onClick={() => setOpenAdd(true)}
                        sx={{background:"#00274D", my:4,mx:3}}
                        >Add
                        </MDButton>
                        </MDBox>
                      }
                    />
                </MDBox>
                
                </MDBox>
                <AddAssetDialog
                  open={openAdd}
                  onAdd={handleAddAsset}
                  onClose={handleCloseDialog}
                
                />
                {selectedAsset && (
                <EditAssetDialog
                  open={openEdit}
                  onClose={handleCloseDialog}
                  asset={selectedAsset}
                  setAsset={setAsset}
                  onEdit={handleEditAsset}
                />
                )}
        </MDBox>
                        
        </CardContent>
        </Card>
        <LoadingDialog open={loading} />
        </DashboardLayout>
  )
}

export default Asset
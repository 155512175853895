import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Autocomplete, Avatar, TextField, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import API_BASE_URL, { STORAGE_BASE_URL } from 'api';
import axios from 'axios';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LoadingDialog from 'components/Loading/LoadingDialog';
const RegisteredStudentList = () => {
  const [search, setSearch] = useState('');
  const[applicants,setApplicants] = useState([])
  const [loading,setLoading] = useState(true);
  const navigate = useNavigate();
  const token =  localStorage.getItem("authToken");
 
  const [years, setYears] = useState([]);
  const [year, setYear] = useState(null);
  const [semesters, setSemesters] = useState([]);
  const [semester, setSemester] = useState(null);


  const axiosInstance = useMemo(() => {
    return axios.create({
      baseURL: API_BASE_URL,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`, // Fetch the token from localStorage
        'Content-Type': 'application/json',
      },
    });
  }, []); 
  
  useEffect(() => {
    const fetchData = async () => {
      const [semestersData, yearsData,] = await Promise.all([
        axiosInstance.get('/semesters'),
        axiosInstance.get('/years'),
      ]);
      setSemesters(semestersData.data);
      setYears(yearsData.data);
    };

    fetchData();
  },[axiosInstance]);

 
  useEffect(() => {
    const fetchRegisteredStudents = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/student/registration`, 
          
          {
          headers: { "Authorization": `Bearer ${token}` }
        });
        
        setApplicants(response.data);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    };
    fetchRegisteredStudents();
  }, [token]);
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filteredData = useMemo(() => {
    return applicants.filter(registrar => {
      
      const searchLower = search.toLowerCase();
      const yearMatch = year ? registrar.class === year.year_name : true;

      return (
        (registrar.first_name?.toLowerCase().includes(searchLower) ||
         registrar.last_name?.toLowerCase().includes(searchLower) ||
         registrar.email_1?.toLowerCase().includes(searchLower) ||
         `${registrar.first_name} ${registrar.last_name}`.toLowerCase().includes(searchLower)) &&
        yearMatch

      );
    });
  });

  const handleViewDetail = (person) => {
    navigate('/viewApplicantDetail', { state: { person } });

  }




  // eslint-disable-next-line no-sparse-arrays
  const columns = [
    {
      accessor: 'action',
      Header: '', 
      width: 40,
      Cell: ({ row }) => (
        <PopupState variant="popover"   popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                <MoreVertIcon />
              </Button>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={() => {
                  handleViewDetail(row.original);
                  popupState.close();
                }}>View Detail</MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      ),
      showSortIcons: false,
    },
    {
      Header: "Profile",
      id: "profile",
      accessor: row  => (
        <Avatar src={`${STORAGE_BASE_URL}/${row.profile_picture}`} />
      ),
      sortable: false,
    },
    {
      Header: 'Name',
      accessor: row => `${row.first_name} ${row.last_name}`, // Custom accessor for full name
      id: 'full_name', // Optional: Use an id for the column
      sorted: "none",
    },
    { Header: 'Telephone', accessor: 'phone', showSortIcons: false, },
    { Header: 'Gender', accessor: 'gender' },
    { Header: 'Email', accessor: 'email_1' },
    { Header: 'Application Date', accessor: 'application_date' },

    {
      Header: 'Application Program',
      id:'application_program',
      accessor: row => (
        <Link to={`/programs/${row.program.id}`}>
          {row.program ? row.program.program_name : null}
        </Link>
      ),
      sortable: true,
    },  
    
    { Header: 'Postal Address', accessor: 'postal_address' },
  ];

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  // ... (previous useEffect and other functions remain the same)

  const renderWidgets = () => (
    <MDBox display="flex" flexDirection={isSmallScreen ? 'column' : 'row'} width="100%">
      <Autocomplete
        fullWidth
        sx={{ 
          mx: isSmallScreen ? 0 : 2, 
          my: 2, 
          width: isSmallScreen ? '100%' : '200px' 
        }}
        options={semesters}
        getOptionLabel={(option) => option.semester_code}
        onChange={(event, newValue) => setSemester(newValue)}
        value={semester}
        renderInput={(params) => <TextField {...params} label="Semester" />}
      />
      <Autocomplete
        fullWidth
        sx={{ 
          mx: isSmallScreen ? 0 : 2, 
          my: 2, 
          width: isSmallScreen ? '100%' : '200px' 
        }}
        options={years}
        getOptionLabel={(option) => option.year_name}
        onChange={(event, newValue) => setYear(newValue)}
        value={year}
        renderInput={(params) => <TextField {...params} label="Year" />}
      />
    </MDBox>
  );

  return (
    <DashboardLayout>
      <MDBox>
        <Card>
          <CardContent>
            <MDTypography variant="h5" fontWeight="medium">
              Students
            </MDTypography>
            <MDBox mt={1}>
            
                <DataTable
                table={{
                  columns,
                  rows: filteredData,
                }}
                canSearch={true}
                onSearchChange={(value) => setSearch(value)}
                sortable={true}
                rowsPerPage={5}
                showPagination
                widgets={renderWidgets()}
              />

{!loading && filteredData.length === 0 && (
          <MDTypography    variant="h5" display="block" align="center" my={2}  sx={{
            fontStyle: "italic",
            fontWeight: "bold",
            color: "#9e9e9e", // Light grey color
            opacity: 0.7,
          }}>
            No students available
          </MDTypography>
        )}
             
            </MDBox>
          </CardContent>
        </Card>
      </MDBox>
      <LoadingDialog open={loading} />
    </DashboardLayout>
  );
};

export default RegisteredStudentList;
import React, { useState,useEffect } from 'react';
import Card from '@mui/material/Card';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import LoadingDialog from 'components/Loading/LoadingDialog';
import { axiosInstance } from 'api2';



const OnlineLearning = () => {
  const [loading,setLoading] = useState(true);
  const targetId = localStorage.getItem('targetId')
  const [zoomMeeting, setZoomMeeting] = useState(null);



  useEffect(() => {

    const fetchZoom = async () => {
      setLoading(true); // Start loading
    
      try {
        const response = await axiosInstance.get(`/zoom`,);
        console.log("response:",response.data)
        const filteredMeetings = response.data.filter(meeting =>
          meeting.students.some(student => student.id === Number(targetId))
        );

        

        setZoomMeeting(filteredMeetings); 
        // setZoom(response.data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false); // End loading after fetching data
      } 
    }
  
  
    fetchZoom();
  }, [targetId]);

  const columns = [
    { Header: 'Topic', accessor: 'topic' }, // Added Topic column
    
    { Header: 'Start Time', accessor: 'start_time' }, // Added Start Time column
    { Header: 'Duration (min)', accessor: 'duration', showSortIcons: false }, 
    { Header: 'Status', accessor: 'status', 
        Cell: ({ value }) => (
          value === 'waiting' ? <CheckIcon style={{ color: 'green' }} /> : <CloseIcon style={{ color: 'red' }} />
        ),
        showSortIcons: false,
      },
      {
        Header: 'Action',
        accessor: 'join_url',
        showSortIcons: false,
        Cell: ({ row }) => {
            const currentTime = new Date();
            const startTime = new Date(row.original.start_time); // Convert start_time to Date object
            
            // Check if the current time is past the start time
            if (currentTime > startTime) {
              return <MDTypography style={{ color: 'red',fontSize:'1rem',opacity:0.7 }}>Date Passed</MDTypography>; 
            }

            return (
                <Button
                    variant="contained"
                    style={{ backgroundColor: '#D4AF37', color: '#fff' }}
                    onClick={() => window.open(row.original.join_url, '_blank')} // Open in a new tab
                >
                    Join
                </Button>
            );
        },
    },
    
  
  ];
  return (
    <DashboardLayout>
        <Card>
            <CardContent>
                <MDBox>
                <MDTypography variant="h5" fontWeight="medium">
                  Zoom
                </MDTypography>
                <MDBox>
                  <DataTable
                    table={{
                      columns,
                      rows: zoomMeeting || [],
                    }}
                    canSearch={true}
                    sortable={true}
                    rowsPerPage={5}
                    showPagination
                   
                  />
                  {!loading &&  (
                    <MDTypography    variant="h5" display="block" align="center" my={2}  sx={{
                      fontStyle: "italic",
                      fontWeight: "bold",
                      color: "#9e9e9e", // Light grey color
                      opacity: 0.7,
                    }}>
                      No Zoom available
                    </MDTypography>
                  )}
                </MDBox>
                
               
                <LoadingDialog open={loading } />
                  </MDBox>
            </CardContent>
        </Card>
    </DashboardLayout>
  );
};

export default OnlineLearning;

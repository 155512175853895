import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from 'components/MDBox';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import React, { useEffect, useState,useCallback } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import swal from 'sweetalert';
import { Card,CardContent } from '@mui/material';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddPayrollDialog from "./AddPayrollDialog"; 
import EditPayrollDialog from './EditPayrollDialog';
import ViewEmployeesPayroll from './viewEmployeesPayroll';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LoadingDialog from 'components/Loading/LoadingDialog';
const Payroll = () => {
    const [search] = useState('');
    const token = localStorage.getItem("authToken");

    const [showAlert, setShowAlert] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openView, setOpenView] = useState(false);
    const [payrolls, setPayrolls] = useState([]);
    const [selectedPayroll, setSelectedPayroll] = useState(null);
    const [openAdd, setOpenAdd] = useState(false);
    const [employeesPayroll,setEmployeesPayroll] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deletingStatus, setDeletingStatus] = useState(false); 

    useEffect(() => {
        let timer;
        if (showAlert) {
            timer = setTimeout(() => {
                setShowAlert(false);
            }, 5000); // 5000 milliseconds = 5 seconds
        }
        return () => clearTimeout(timer); // Clean up the timer
    }, [showAlert]);

    const fetchPayrolls = useCallback(async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/payrolls`, {
                headers: { "Authorization": `Bearer ${token}` }
            });
            setPayrolls(response.data);
        } catch (err) {
            console.error(err);
        } finally{
            setLoading(false)
        }
    },[token]);
    useEffect(() => {
        fetchPayrolls();
    }, [fetchPayrolls]);


  const handleViewDetails = (data) => {
   
    setEmployeesPayroll(data.employee_payrolls)

    setOpenView(true);
    
  }

    const handleAddPayrolls = (newPayroll) => {
       fetchPayrolls()
    };

    const handleEditPayrolls = (updatedPayroll) => {
       fetchPayrolls()
    };

    const handleDeletePayroll = async (id) => {
        const confirm = await swal({
            title: "Are you sure?",
            text: "You will not be able to recover this payroll!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        });

        if (confirm) {
            setDeletingStatus(true)
            try {
                const response = await axios.delete(`${API_BASE_URL}/payrolls/${id}`, {
                    headers: { "Authorization": `Bearer ${token}` }
                });
                if (response.status === 200) {
                    setPayrolls(payrolls.filter((payroll) => payroll.id !== id));
                    swal({
                        title: "Payroll Deleted Successfully!",
                        icon: "success"
                    });
                }
            } catch (error) {
                swal({
                    title: `${error.response.data.message}`,
                    icon: "warning"
                });
            } finally {
                setDeletingStatus(false)
            }
        }
    };

    const handleOpenEdit = (payroll) => {
        setSelectedPayroll(payroll);
        setOpenEdit(true);
    };

    const handleCloseDialog = () => {
        setOpenAdd(false);
        setOpenEdit(false);
        setOpenView(false);
        setSelectedPayroll(null);
    };

    const filteredData = payrolls.filter(payroll => {
        return (
            payroll.remark.toLowerCase().includes(search.toLowerCase())
        );
    });
   

    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    

    const columns = [
        {
            accessor: 'action',
            Header: '',
            width: 40,
            padding: 0,
            Cell: ({ row }) => (
                <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                        <React.Fragment>
                            <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                                <MoreVertIcon />
                            </Button>
                            <Menu {...bindMenu(popupState)}>
                                <MenuItem onClick={() => {
                                    handleOpenEdit(row.original);
                                    popupState.close();
                                }} style={{ color: '#1976d2' }}>
                                    <EditIcon style={{ marginRight: 8 }} /> Edit
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    handleDeletePayroll(row.original.id);
                                    popupState.close();
                                }} style={{ color: '#d32f2f' }}>
                                    <DeleteIcon style={{ marginRight: 8 }} /> Delete
                                </MenuItem>
                                <MenuItem onClick={() => {
              handleViewDetails(row.original);
              popupState.close();
            }} style={{ color: '#1976d2' }}>
              <VisibilityIcon style={{ marginRight: 8 }} /> View Details
            </MenuItem>
                            </Menu>
                        </React.Fragment>
                    )}
                </PopupState>
            ),
            showSortIcons: false,
        },
        { Header: 'Year', accessor: 'year',showSortIcons:false },
        { Header: 'Month', accessor: 'month' ,Cell: ({ value }) => monthNames[value - 1] || 'Invalid month', },
        // { Header: 'Employee', accessor: 'month' },
        { Header: 'Payroll Status', accessor: 'payroll_status',showSortIcons:false },
     
        { Header: 'Generated By', accessor: 'generated_by_full_name',showSortIcons:false },
        {
            Header: 'Generated Date',
            accessor: 'generated_date',
            showSortIcons:false,
            Cell: ({ value }) => {
                // Create a new Date object from the value
                const date = new Date(value);
        
                // Define options for formatting the date
                const options = { year: 'numeric', month: 'short', day: '2-digit' };
        
                // Format the date to "Jul 21, 2024"
                return date.toLocaleDateString('en-US', options).replace(',', '');
            },
        },
        {
            Header: 'Approved By',
            accessor: 'approved_by_full_name',
           
          },
        { Header: 'Approved Date', accessor: 'approved_date',showSortIcons:false },
        { Header: 'Remark', accessor: 'remark' },
    ];

    return (
        <DashboardLayout>
            <Card>
                <CardContent>
                    <MDBox>
                        <MDTypography variant="h5" fontWeight="medium">
                            Payrolls
                        </MDTypography>
                        <MDBox>
                            <DataTable
                                table={{
                                    columns,
                                    rows: filteredData,
                                }}
                                canSearch={true}
                                sortable={true}
                                rowsPerPage={5}
                                showPagination
                                widgets={
                                    <MDBox ms={2}>
                                        <MDButton
                                            variant="gradient"
                                            ml="auto"
                                            color="info"
                                            size="small"
                                            onClick={() => setOpenAdd(true)}
                                            sx={{background:"#00274D", my:4,mx:3}}
                                        >
                                            <span>Add </span>
                                        </MDButton>
                                    </MDBox>
                                }
                            />
                        </MDBox>
                        {/* Add Payroll Dialog */}
                        <AddPayrollDialog open={openAdd} onClose={handleCloseDialog} onAdd={handleAddPayrolls} />
                        {/* Edit Payroll Dialog */}
                        <EditPayrollDialog open={openEdit} onClose={handleCloseDialog} selectedPayroll={selectedPayroll} onEdit={handleEditPayrolls} />
                        {
                employeesPayroll &&(
                  <ViewEmployeesPayroll employees={employeesPayroll} openView={openView} onClose={handleCloseDialog}/>
                )
              }
                    </MDBox>
                    <LoadingDialog open={loading || deletingStatus} />
                </CardContent>
            </Card>
        </DashboardLayout>
    );
};

export default Payroll;

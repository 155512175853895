import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from 'components/MDBox';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import React, { useEffect, useState, useCallback } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import swal from 'sweetalert';
import { Card, CardContent } from '@mui/material';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import VisibilityIcon from '@mui/icons-material/Visibility';
import AddLoan from './addLoan';
import EditLoan from './editLoan';
import EmployeeLoans from './employeeLoans';
import LoadingDialog from "components/Loading/LoadingDialog";

const Loans = () => {
 
  const token = localStorage.getItem("authToken");

  const [showAlert, setShowAlert] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [loans, setLoans] = useState([]);
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [openAdd, setOpenAdd] = useState(false);
  const [employeeLoans, setEmployeeLoans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deletingStatus, setDeletingStatus] = useState(false); 


  useEffect(() => {
    let timer;
    if (showAlert) {
      timer = setTimeout(() => {
        setShowAlert(false);
      }, 5000); // 5000 milliseconds = 5 seconds
    }
    return () => clearTimeout(timer); // Clean up the timer
  }, [showAlert]);

  const fetchLoans = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/loans`, {
        headers: { "Authorization": `Bearer ${token}` }
      });
      setLoans(response.data);
    } catch (err) {
      console.error(err);
    }  finally{
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchLoans();
  }, [fetchLoans]);

  const handleViewDetails = (data) => {
    setEmployeeLoans(data.repayments);
    setOpenView(true);
  };

  const handleAddLoans = () => {
    fetchLoans();
  };

  const handleEditLoans = () => {
    fetchLoans();
  };

  const handleDeleteLoan = async (id) => {
    const confirm = await swal({
      title: "Are you sure?",
      text: "You will not be able to recover this loan!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (confirm) {
      setDeletingStatus(true);
      try {
        const response = await axios.delete(`${API_BASE_URL}/loans/${id}`, {
          headers: { "Authorization": `Bearer ${token}` }
        });
        if (response.status === 200) {
          setLoans(loans.filter((loan) => loan.id !== id));
          swal({
            title: "Loan Deleted Successfully!",
            icon: "success"
          });
        }
      } catch (error) {
        swal({
          title: `${error.response.data.message}`,
          icon: "warning"
        });
      }finally {
        setDeletingStatus(false); // End loading for deletion
      } 
    }
  };

  const handleOpenEdit = (loan) => {
    setSelectedLoan(loan);
    setOpenEdit(true);
  };

  const handleCloseDialog = () => {
    setOpenAdd(false);
    setOpenEdit(false);
    setOpenView(false);
    setSelectedLoan(null);
  };

  const updatedLoansData = loans.map((loan) => {
    const totalRepayment = loan.repayments.reduce((total, repayment) => {
      return total + parseFloat(repayment.repayment_amount);
    }, 0);
    
    const loanAmount = parseFloat(loan.loan_amount);
    const remaining = loanAmount - totalRepayment;
  
    return {
      ...loan,
      remaining: remaining.toFixed(2), // Format to 2 decimal places
    };
  });
  const columns = [
    {
      accessor: 'action',
      Header: '',
      width: 40,
      padding: 0,
      Cell: ({ row }) => (
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                <MoreVertIcon />
              </Button>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={() => {
                  handleOpenEdit(row.original);
                  popupState.close();
                }} style={{ color: '#1976d2' }}>
                  <EditIcon style={{ marginRight: 8 }} /> Edit
                </MenuItem>
                <MenuItem onClick={() => {
                  handleDeleteLoan(row.original.id);
                  popupState.close();
                }} style={{ color: '#d32f2f' }}>
                  <DeleteIcon style={{ marginRight: 8 }} /> Delete
                </MenuItem>
                <MenuItem onClick={() => {
                  handleViewDetails(row.original);
                  popupState.close();
                }} style={{ color: '#1976d2' }}>
                  <VisibilityIcon style={{ marginRight: 8 }} /> View Details
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      ),
      showSortIcons: false,
    },
    {
        Header: 'Employee Name',
        accessor: 'employee',
        showSortIcons: false,
        Cell: ({ row }) => {
          const employee = row.original.employee;
          return employee ? `${employee.first_name} ${employee.last_name}` : 'N/A';
        }
      },
    { Header: 'Loan Amount', accessor: 'loan_amount',showSortIcons:false, Cell: ({ value }) => {
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
      } },
    { Header: 'Loan Interest Rate', accessor: 'loan_interest_rate',showSortIcons:false },
    { Header: 'Loan Start Date', accessor: 'loan_start_date',showSortIcons:false },
    { Header: 'Loan End Date', accessor: 'loan_end_date',showSortIcons:false },
    {
        Header: 'Loan Status',
        accessor: 'loan_status',
        showSortIcons: false,
        Cell: ({ value }) => {
          let color;
          
          // Determine the color based on loan status
          if (value === 'paid_off') {
            color = 'orange'; // Color for paid_off
          } else if (value === 'active') {
            color = 'green'; // Color for active
          } else {
            color = 'yellow'; // Default color for other statuses
          }
      
          return <span style={{ color }}>{value}</span>;
        }
      },
    { Header: 'Remark', accessor: 'remark',showSortIcons:false },
    { 
        Header: 'Remaining', 
        accessor: 'remaining',
        showSortIcons: false,
        Cell: ({ value }) => {
          return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
        }
      }
  ];

  return (
    <DashboardLayout>
      <Card>
        <CardContent>
          <MDBox>
            <MDTypography variant="h5" fontWeight="medium">
              Loans
            </MDTypography>
            <MDBox>
              <DataTable
                table={{
                  columns,
                  rows: updatedLoansData,
                }}
                canSearch={true}
                sortable={true}
                rowsPerPage={5}
                showPagination
                widgets={
                  <MDBox ms={2}>
                    <MDButton
                      variant="gradient"
                      ml="auto"
                      color="info"
                      size="small"
                      onClick={() => setOpenAdd(true)}
                      sx={{ background: "#00274D", my: 4, mx: 3 }}
                    >
                      <span>Add</span>
                    </MDButton>
                  </MDBox>
                }
              />
            </MDBox>
            {/* Add Loan Dialog */}
            <AddLoan open={openAdd} onClose={handleCloseDialog} onAdd={handleAddLoans} />
            {/* Edit Loan Dialog */}
            <EditLoan open={openEdit} onClose={handleCloseDialog} loan={selectedLoan} onEdit={handleEditLoans} />
            {
              employeeLoans && (
                <EmployeeLoans loans={employeeLoans} openView={openView} onClose={handleCloseDialog} />
              )
            }
          </MDBox>
          <LoadingDialog open={loading || deletingStatus} />
        </CardContent>
      </Card>
    </DashboardLayout>
  );
};

export default Loans;

import React, { useState, useEffect, useCallback } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import API_BASE_URL from 'api';
import axios from 'axios';
import MDButton from 'components/MDButton';
import SubmitAssignment from './submitAssignment';
import FileViewer from 'components/FileViewer';
import { isBefore } from 'date-fns';
import LoadingDialog from 'components/Loading/LoadingDialog';

const Assessment = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [assessmentData, setAssessmentData] = useState([]);
  const [loading, setLoading] = useState(true); 
  const token = localStorage.getItem("authToken");
  const targetId = localStorage.getItem("targetId");
  const [openAdd, setOpenAdd] = useState(false);
  const [id, setId] = useState(null);

  const fetchAssessmentData = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/student/${targetId}/assessment`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAssessmentData(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false); // Stop loading after data is fetched
    }
  }, [token, targetId]);

  useEffect(() => {
    fetchAssessmentData();
  }, [fetchAssessmentData]);

  const handleCloseDialog = () => {
    setOpenAdd(false);
  };

  const handleViewFile = (filePath) => {
    const extension = filePath.split('.').pop().toLowerCase();
    setSelectedFile(filePath);
    setFileType(extension === "pdf" ? "pdf" : ["jpg", "jpeg", "png", "gif"].includes(extension) ? "image" : "other");
    setOpenDialog(true);
  };

  const handleAddAssessment = (data) => {
    setOpenAdd(true);
    setId(data.assessment_id);
    fetchAssessmentData();
  };

  const handleAddResponse = () => {
    fetchAssessmentData();
  };


  const columns = [
    { Header: 'Assignment Name', accessor: 'assignment_name' },
    { Header: 'Course Name', accessor: 'course.course_name' },
    { Header: 'Instructor Name', accessor: 'instructor.full_name' },
    { Header: 'Due Date', accessor: 'due_date', showSortIcons: false },
    { Header: 'Score', accessor: 'score', showSortIcons: false },
    {
      Header: 'Actions',
      accessor: 'id',
      showSortIcons: false,
      Cell: ({ row }) => {
        const dueDate = new Date(row.original.due_date);
        const currentDate = new Date();
        const isDueDatePassed = isBefore(dueDate, currentDate);
        const isSubmitted = !!row.original.submitted_date;
        const hasAssessments = row.original.assessments && row.original.assessments.length > 0; // Check if assessments exist

        if (isSubmitted) {
          return <MDTypography variant="caption">Already Submitted</MDTypography>;
        }

        if (isDueDatePassed) {
          return <MDTypography variant="caption" color="error">Due Date Passed</MDTypography>;
        }

        if (hasAssessments) {
          return (
            <MDButton
              variant="contained"
              size="small"
              color="white"
              sx={{
                backgroundColor: '#D4AF37',
                '&:hover': {
                  backgroundColor: '#D4AF37',
                  opacity: 0.8,
                },
              }}
              onClick={() => handleAddAssessment(row.original)}
            >
              Submit
            </MDButton>
          );
        }
      },
    },
    {
      Header: 'File Path',
      accessor: 'assignment_file_path',
      Cell: ({ row, value }) => {
        const hasAssessments = row.original.assignment_file_path && row.original.assignment_file_path.length > 0;
    
        if (hasAssessments) {
          return (
            <MDButton
              variant="body2"
              component="a"
              onClick={() => handleViewFile(value)}
              sx={{
                cursor: 'pointer',
                textDecoration: 'underline',
                boxShadow: 1,
                transition: 'box-shadow 0.3s ease-in-out',
                '&:hover': {
                  color: 'secondary.main',
                  boxShadow: 4,
                },
              }}
            >
              View File
            </MDButton>
          );
        }
    
       
      },
    }
    
  ];



  return (
    <DashboardLayout>
      <MDBox p={3}>
        <Card>
          <CardContent>
            <MDTypography variant="h5" fontWeight="medium">
              Assessments
            </MDTypography>

            <MDBox mt={2}>
   
            
                <DataTable
                  table={{
                    columns,
                    rows: assessmentData,
                  }}
                  canSearch
                  sortable
                  rowsPerPage={5}
                  showPagination
                  noEndBorder
                />
              {!loading && assessmentData.length === 0 && (
          <MDTypography    variant="h5" display="block" align="center" my={2}  sx={{
            fontStyle: "italic",
            fontWeight: "bold",
            color: "#9e9e9e", // Light grey color
            opacity: 0.7,
          }}>
            No assessment available
          </MDTypography>
        )}
            

            </MDBox>

            {assessmentData.length > 0 && (
              <SubmitAssignment
                open={openAdd && assessmentData.length > 0}
                onClose={handleCloseDialog}
                onAdd={handleAddResponse}
                id={id}
              />
            )}

            {assessmentData.length > 0 && openDialog && (
              <FileViewer
                open={openDialog && assessmentData.length > 0}
                onClose={() => setOpenDialog(false)}
                filePath={selectedFile}
                fileType={fileType}
              />
            )}
          </CardContent>
        </Card>
      </MDBox>
      <LoadingDialog open={loading} />
    </DashboardLayout>
  );
};

export default Assessment;

import AddIcon from '@mui/icons-material/Add';
import {
  Autocomplete,
  TextField
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import Switch from '@mui/material/Switch';
import swal from "sweetalert";
import LoadingDialog from 'components/Loading/LoadingDialog';

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    "Content-Type": "application/json",
  },
});

const AddStaffDialog = ({ open, onClose, onAdd }) => {
  const [modalData, setModalData] = useState({
    name: "",
    description: "",
    staff_head: "",
    is_taxable_free: 0,
    
  });
  const [loading,setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);

  const [error, setError] = useState({});

  const token = localStorage.getItem("authToken");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get("/employees");
        setEmployees(response.data); // Set the Employees data
      } catch (err) {
        console.error("Failed to fetch employees:", err);
      }
    };

    fetchData();
  }, []);

 

  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData({
      ...modalData,
      [name]: value,
    });
    setError({
      ...error,
      [name]: "", // Clear the error for this field when it's being modified
    });
  };

  const handleModalSubmit = async () => {
   
    if (modalData.name=== "" ||
      modalData.description=== "" 
     
    ) {
       alert("Please add all required fields to continue");
     }
     else{
    try {
      setLoading(true)
      const response = await axios.post(`${API_BASE_URL}/staff`, modalData, {
        headers: { Authorization: `Bearer ${token}` },
      });
     

      if (response.status === 201) {
        onAdd(response.data);
        onClose();
        swal({
          title: "Staff Created Successfully!",
          icon: "success",
        });

        setModalData({
          name: "",
          description: "",
          staff_head: "",
          is_taxable_free: 0,
        });
        setError({});
      }
    } catch (error) {
      if (error.response.status === 400) {
        const errors = error.response.data.error;
        setError(errors); // Set the error object
      }
    } finally{
      setLoading(false)
    }
  }
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
    <DialogTitle textAlign={"center"}>
      <AddIcon /> Add Staff
    </DialogTitle>
    <DialogContent>
      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Staff Name
        </MDTypography>
        <MDInput
          type="text"
          name="name"
          fullWidth
          required
          value={modalData.name}
          onChange={handleModalChange}
          error={!!error.name} // MUI error handling
        />
        {error.name && (
          <MDTypography variant="caption" color="error">
            {error.name[0]} {/* Show the error message for the 'name' field */}
          </MDTypography>
        )}
      </MDBox>


      <MDBox mb={2}>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          Description
        </MDTypography>
        <MDInput
          type="text"
          name="description"
          fullWidth
          required
          multiline
          rows={5}
          value={modalData.description}
          onChange={handleModalChange}
          error={!!error.description}
        />
        {error.description && (
          <MDTypography variant="caption" color="error">
            {error.description[0]} {/* Show the error message for the 'address' field */}
          </MDTypography>
        )}
      </MDBox>
      <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Is Taxable Free
          </MDTypography>
          <Switch
            checked={modalData.is_taxable_free === 1}
            onChange={(e) =>
              setModalData({
                ...modalData,
                is_taxable_free: e.target.checked ? 1 : 0,
              })
            }
          />
        </MDBox>

      <MDBox mb={2}>
      <Autocomplete
        options={employees}
        getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
        renderInput={(params) => <TextField {...params} label="Staff Head" />}
        onChange={(event, newValue) => {
          setModalData({
            ...modalData,
            staff_head: newValue ? newValue.id : null, // Set reports_to to the selected position's id
          });
        }}
        // value={getEmployeeById(value)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
    </MDBox>


    

      
    </DialogContent>

    <DialogActions>
      <MDButton
        variant="gradient"
        size="small"
        color="info"
        sx={{ background: "#00274D" }}
        onClick={handleModalSubmit}
      >
        Submit
      </MDButton>
      <MDButton
        variant="gradient"
        size="small"
        color="black"
        sx={{ background: "grey" }}
        onClick={onClose}
      >
        Cancel
      </MDButton>
      <LoadingDialog open={loading} />
    </DialogActions>
  </Dialog>
  )
}

export default AddStaffDialog



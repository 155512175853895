import { Alert, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import API_BASE_URL from "api";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useState, useEffect } from "react";
import swal from "sweetalert";
import LoadingDialog from "components/Loading/LoadingDialog";


function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [error, setError] = useState(null);
  const [forgotPasswordDialogOpen, setForgotPasswordDialogOpen] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [forgotPasswordError, setForgotPasswordError] = useState(null);
  const [loading,setLoading] = useState(false);

  // Load stored email and password from localStorage if "Remember Me" was previously checked
  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    const storedPassword = localStorage.getItem("password");
    if (storedEmail && storedPassword) {
      setFormData({
        email: storedEmail,
        password: storedPassword,
      });
      setRememberMe(true);
    }
  }, []);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setError(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}/login`, formData);
      if (response.status === 200) {
        const token = response.data.token.split("|")[1];
        localStorage.setItem("targetId", response.data.target_id);
        localStorage.setItem("userId", response.data.id);
        localStorage.setItem("authToken", token);
        localStorage.setItem("fullName", response.data.full_name);
        localStorage.setItem("profile_picture", response.data.profile_picture);
        if (response.data.roles.length > 0) {
          localStorage.setItem("role", response.data.roles[0].name);
          const permissions = response.data.roles[0].permissions;
          const permissionNames = permissions.map(
            (permission) => permission.name
          );
          localStorage.setItem("permissions", JSON.stringify(permissionNames));
        }

        // Store email and password in localStorage if "Remember Me" is checked
        if (rememberMe) {
          localStorage.setItem("email", formData.email);
          localStorage.setItem("password", formData.password);
        } else {
          // Clear stored email and password if "Remember Me" is unchecked
          localStorage.removeItem("email");
          localStorage.removeItem("password");
        }
        

        window.location.href = "/dashboard";
      }
    } catch (error) {
      console.log("error:",error.response.data)
      setError(error.response.data.message);
    }
  };

  const handleForgotPasswordOpen = () => {
    setForgotPasswordDialogOpen(true);
    setForgotPasswordError(null); // Reset any previous errors
   // Reset any previous success messages
  };

  const handleForgotPasswordClose = () => {
    setForgotPasswordDialogOpen(false);
    setForgotPasswordEmail(""); // Reset email field
  };

  const handleForgotPasswordChange = (e) => {
    setForgotPasswordEmail(e.target.value);
    setForgotPasswordError(null);
  };

  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)

    try {
      const response = await axios.post(`${API_BASE_URL}/forgot-password`, { email: forgotPasswordEmail });
    

      if(response.status === 200) {
        swal({
          title: "Password Sent Successfully!",
          text: "Check your email for further instructions.",
          icon: "success",
          buttons: false,
          timer: 3000, // Optional: auto-close after 3 seconds
        });
         // Close the dialog and reset the email field
      setForgotPasswordDialogOpen(false);
      setForgotPasswordEmail(""); // Reset email field
      setForgotPasswordError(null); // Reset error state
      
      }
      
    } catch (error) {
      console.log(error.message);
      console.log(error.response.data);
      setForgotPasswordError(error.response.data.message); // Set error message
      // Reset success state
    } finally {
      setLoading(false)
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        {error && (
          <MDBox mx={2} mt={2} mb={2}>
            <Alert severity="error" variant="filled">
              {error}
            </Alert>
          </MDBox>
        )}
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <MDInput
                type="email"
                name="email"
                label="Email"
                value={formData.email}
                fullWidth
                onChange={handleChange}
                required
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                name="password"
                label="Password"
                value={formData.password}
                fullWidth
                required
                onChange={handleChange}
              />
            </MDBox>
           
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                Sign in
              </MDButton>
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
              <MDBox pl={6}>

              <MDTypography
                variant="h6"
                fontWeight="regular"
                color="info"
                onClick={handleForgotPasswordOpen}
                sx={{ cursor: "pointer", userSelect: "none" }}
              >
                Forgot password ?
              </MDTypography>
            </MDBox>
              
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>

      {/* Forgot Password Dialog */}
      <Dialog fullWidth open={forgotPasswordDialogOpen} onClose={handleForgotPasswordClose}>
        <DialogTitle sx={{fontSize: "1rem"}}>Forgot Password</DialogTitle>
        <DialogContent>
          <MDBox component="form" role="form" onSubmit={handleForgotPasswordSubmit}>
            <MDBox >
              <MDInput
              my={1}
                type="email"
                name="forgotPasswordEmail"
                label="Enter your email"
                value={forgotPasswordEmail}
                fullWidth
                onChange={handleForgotPasswordChange}
                required
              />
            </MDBox>
            {forgotPasswordError && (
              <MDBox mb={2}>
                <Alert severity="error" variant="filled">
                  {forgotPasswordError}
                </Alert>
              </MDBox>
            )}
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleForgotPasswordClose} variant="gradient"
              size="small"
              color="black"
              sx={{ background: "grey" }}
           
              >

            Cancel
          </MDButton>
          <MDButton type="submit" variant="gradient"
            size="small"
            color="info"
            sx={{ background: "#00274D" }}
            onClick={handleForgotPasswordSubmit}
            >
              
            Send
          </MDButton>
        </DialogActions>
        <LoadingDialog open={loading} />
      </Dialog>
    </BasicLayout>
  );
}

export default Basic;

import React, { useState, useRef } from 'react';

import { Dialog, DialogTitle, DialogContent, TextField } from '@mui/material';
import DataTable from 'examples/Tables/DataTable';
import DialogActions from "@mui/material/DialogActions";
import MDButton from "components/MDButton";
import axios from 'axios';  
import API_BASE_URL from 'api';  
import swal from 'sweetalert';
import FileViewer from "components/FileViewer";
import MDTypography from 'components/MDTypography';

const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        'Content-Type': 'application/json',
    },
});

const StudentAssignmentGiven = ({ openView, onClose, students, id }) => {  
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileType, setFileType] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [marks, setMarks] = useState({});  
    const markInputRefs = useRef({});

    const handleMarkChange = (studentId, newMark) => {
        
        const numericMark = parseFloat(newMark);
        setMarks((prev) => ({
            ...prev,
            [studentId]: isNaN(numericMark) ? '' : numericMark,  
        }));

        setTimeout(() => {
            if (markInputRefs.current[studentId]) {
              markInputRefs.current[studentId].focus();
            }
          }, 0);
    };
    const handleViewFile = (filePath) => {
        const extension = filePath.split('.').pop().toLowerCase();
        setSelectedFile(filePath);
        setFileType(extension === "pdf" ? "pdf" : ["jpg", "jpeg", "png", "gif"].includes(extension) ? "image" : "other");
        setOpenDialog(true);
      };
    
    const handleSubmitMarks = async () => {
        try {
            
            const transformMarks = () => {
                return Object.entries(marks).map(([studentId, mark]) => {
                    
                    const score = parseFloat(mark);
                    const student_id = parseInt(studentId);
    
                    // Validate score
                    if (isNaN(score)) {
                        console.error(`Invalid score for student_id ${student_id}: ${mark}`);
                    }
    
                    return { score, student_id };
                });
            };
    
            const formattedMarks = transformMarks();
            console.log("Submitting marks:", formattedMarks);
    
            // Check for any invalid entries
            const invalidMarks = formattedMarks.filter(mark => isNaN(mark.score));
            if (invalidMarks.length > 0) {
                
                swal({
                    icon: 'error',
                    title: 'Validation Error',
                    text: 'Some scores are invalid.',
                    confirmButtonText: 'OK'
                });
                return;
            }
    
            // Send each student score as a separate POST request
            await Promise.all(formattedMarks.map(mark =>
                axiosInstance.post(`assessments/${id}/update-student-score`, mark)
            ));
    
            swal({
                icon: 'success',
                title: 'Success!',
                text: 'Marks submitted successfully!',
                confirmButtonText: 'OK'
            });
            onClose();
        } catch (error) {
            console.error("Error submitting marks:", error);
            swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Failed to submit marks.',
                confirmButtonText: 'OK'
            });
        }
    };

   
    
    const columns = [
        {
            Header: 'Student Name',
            accessor: (row) => `${row.first_name} ${row.last_name}`,  // Combine first_name and last_name
        },
        { Header: 'Submitted Date', accessor: 'pivot.submitted_date' },
        {
            Header: 'Mark',
            accessor: 'mark',
            Cell: ({ row }) => {
                const isDisabled = !row.original.pivot.file_path || !row.original.pivot.submitted_date;
    
                return (
                    <TextField
                        type="number"
                        value={marks[row.original.id] || ''}
                        inputRef={(el) => (markInputRefs.current[row.original.id] = el)} 
                        onChange={(e) => handleMarkChange(row.original.id, e.target.value)}
                        disabled={isDisabled}  // Disable if no file_path or submitted_date
                        size="small"
                    />
                );
            }
        },
        {
            Header: 'Assignment File',
            accessor: 'pivot.file_path',
            Cell: ({ value }) => (
                value ? (
                    <MDButton
                      variant="body2"
                      color="primary"
                      component="a"
                      onClick={() => handleViewFile(value)}
                      style={{ cursor: 'pointer' }}
                      sx={{ 
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        boxShadow: 1, 
                        transition: 'box-shadow 0.3s ease-in-out',
                        '&:hover': {
                          color: 'secondary.main',
                          boxShadow: 4,
                        }
                      }}
                    >
                      View File
                    </MDButton>
                ) : (
                    <MDTypography variant="body2" color="text.secondary">
                      No File Submitted
                    </MDTypography>
                )
            ),
        },
        
    ];

    return (
        <Dialog open={openView} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle textAlign="center">Assigned Students</DialogTitle>
            <DialogContent>
                <DataTable
                    table={{
                        columns,
                        rows: students,  
                    }}
                    canSearch={true}
                    sortable={true}
                    pagination
                    rowsPerPage={5}
                    showPagination
                />
            </DialogContent>
            <DialogActions>
                <MDButton
                    variant="gradient"
                    size="small"
                    color="info"
                    sx={{background:"#00274D"}} 
                    onClick={handleSubmitMarks}  
                >
                    Submit Marks
                </MDButton>
                <MDButton
                    variant="gradient"
                    size="small"
                    color="black"
                    sx={{ background: "grey" }}
                    onClick={onClose}
                >
                    Close
                </MDButton>
            </DialogActions>
            <FileViewer
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        filePath={selectedFile}
        fileType={fileType}
      />
        </Dialog>
    );
};

export default StudentAssignmentGiven;

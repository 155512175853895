import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import DataTable from 'examples/Tables/DataTable';
import DialogActions from "@mui/material/DialogActions";
import MDButton from "components/MDButton";
import LoadingDialog from 'components/Loading/LoadingDialog';
import { axiosInstance } from 'api2';
import swal from "sweetalert";
import Switch from '@mui/material/Switch';

const Students = ({ open, onClose, students,id,onView }) => {
    const [loading, setLoading] = useState(false);
    const [participatingStudents, setParticipatingStudents] = useState([]);

    useEffect(() => {
        if (students) {
            setParticipatingStudents(students
                .filter(student => student.pivot.is_participated === 1)
                .map(student => student.id)
            );
        }
    }, [students]);

    console.log("participatingStudents:",students)
    
    const handleToggleParticipation = (studentId) => {
        // Toggle the participation status
        setParticipatingStudents((prev) => {
            if (prev.includes(studentId)) {
                // Remove from participatingStudents (set to 0)
                return prev.filter((id) => id !== studentId);
            } else {
                // Add to participatingStudents (set to 1)
                return [...prev, studentId];
            }
        });
    };
    

    const columns = [
        { 
            Header: 'Full Name', 
            accessor: (row) => `${row.first_name} ${row.last_name || ''}`, 
            id: 'fullName' 
        },
        { 
            Header: 'Email', 
            accessor: 'email_1' 
        },
        { 
            Header: 'Phone', 
            accessor: 'phone', 
            showSortIcons: false 
        },
        { 
            Header: 'Class', 
            accessor: 'class', 
            showSortIcons: false 
        },
          
        {
            Header: 'Is Participated',
            accessor: 'pivot.is_participated',
            showSortIcons: false,
            Cell: ({ row }) => {
                return (
                    <Switch
                        checked={participatingStudents.includes(row.original.id)}
                        onChange={() => handleToggleParticipation(row.original.id)}
                        color="primary"
                    />
                );
            }
        }
        
    ];

    const rows = students.map(student => ({
        ...student,
    }));

 
    const handleSubmit = async () => {
        try {
            setLoading(true);
            const studentParticipation = students.map(student => ({
                id: student.id,
                is_participated: participatingStudents.includes(student.id) ? 1 : 0
            }));
    
            const response = await axiosInstance.patch(`meetings/${id}/students`, {
                students: studentParticipation
            });
    
            if (response.status === 200) {
                onView();
                onClose();
                swal({
                    title: "Participant Status Updated Successfully!",
                    icon: "success"
                });
            }
        } catch (error) {
            console.log(error);
            swal({
                title: "Error",
                text: "Failed to update participant status",
                icon: "error"
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle textAlign="center">
                Students
            </DialogTitle>
            <DialogContent>
                <DataTable
                    table={{
                        columns,
                        rows
                    }}
                    canSearch={true}
                    sortable={true}
                    pagination
                    rowsPerPage={5}
                    showPagination
                />
            </DialogContent>
            <DialogActions>
                <MDButton
                    variant="gradient"
                    size="small"
                    color="black"
                    sx={{ background: "grey" }}
                    onClick={onClose}
                >
                    Close
                </MDButton>
                <MDButton
                    color="info"
                    sx={{background:"#00274D"}}
                    onClick={handleSubmit} // Call the submit function
                    size="small"
                >
                    Submit
                </MDButton>
            </DialogActions>
            <LoadingDialog open={loading} />
        </Dialog>
    );
};

export default Students;

import React, { useState, useEffect, useCallback } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { Card, CardContent } from '@mui/material';
import MDTypography from 'components/MDTypography';
import AddEvents from './addEvents';
import interactionPlugin from "@fullcalendar/interaction";
import { axiosInstance } from 'api2';
import { checkPermission,fetchAndStorePermissions } from "utils/checkPermission";
import LoadingDialog from 'components/Loading/LoadingDialog';


// Function to generate random color
const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const Calendar = () => {
  const [events, setEvents] = useState([]);
  const token = localStorage.getItem("authToken");
  const [openAdd, setOpenAdd] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null); // Store the selected date
  const [loading,setLoading] = useState(true)

  useEffect(() => {
    const initializePermissions = async () => {
      await fetchAndStorePermissions();
    };
    initializePermissions();
  }, []);

  const fetchCalendar = useCallback(async () => {
    try{
    const response = await axiosInstance.get(`/academic-calendar`, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    });

    // Map through the events and assign random background colors
    const mappedEvents = response.data.map(event => ({
      title: event.event,
      date: event.date,
      backgroundColor: getRandomColor(),
      borderColor: getRandomColor(), // Optional: matching border color
    }));

    setEvents(mappedEvents);
  }catch(err){
    console.log("err",err)
  }finally{
    setLoading(false)
  }
  }, [token]);

  useEffect(() => {
    fetchCalendar();
  }, [fetchCalendar]);

  const handleAddEvent = (newEvent) => {
    fetchCalendar();
  };

  const handleCloseDialog = () => {
    setOpenAdd(false);
    setSelectedDate(null); 
  };

  const handleDateClick = (arg) => {
    setSelectedDate(arg.dateStr); 
    setOpenAdd(true);
  };

  return (
    <DashboardLayout>
      <Card>
        <CardContent>

          <MDTypography variant="h5" fontWeight="medium">
            Student Calendar
          </MDTypography>
          <MDTypography variant="caption" fontWeight="regular">
            Overview of upcoming meetings, exams, and events
          </MDTypography>

          <FullCalendar
            aspectRatio={1.5} 
           sx={{ width: '100%' }}
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            events={events}
            dateClick={checkPermission('announcement-create') && handleDateClick}
            eventContent={(arg) => {
              const title = arg.event.title.length > 15 
                ? arg.event.title.substring(0, 15) + '...' 
                : arg.event.title;

              return (
                <div title={arg.event.title} style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '110px'
                }}>
                  {title}
                </div>
              );
            }}
            headerToolbar={{
              left: 'prev,next today',
              right: 'dayGridMonth,dayGridWeek,dayGridDay',
            }}
          />


          <AddEvents 
            open={openAdd} 
            onClose={handleCloseDialog} 
            onAdd={handleAddEvent} 
            selectedDate={selectedDate} 
          />

          <LoadingDialog open={loading} />
        </CardContent>
      </Card>
    </DashboardLayout>
  );
};

export default Calendar;

import React, { useState, useEffect } from "react";
import { Grid, Card, CardContent, Pagination } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import API_BASE_URL from "api";
import axios from "axios";
import LoadingComponent from "components/Loading";


function AvailablePrograms() {
  const [programsAvailable, setProgramsAvailable] = useState([]);
  const [loading, setLoading] = useState(true); // State to track loading status
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/available-programs`);
        console.log("response:", response.data);
        setProgramsAvailable(response.data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };
    fetchPrograms();
  }, []);

  const totalPages = Math.ceil(programsAvailable.length / itemsPerPage);
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentPrograms = programsAvailable.slice(startIndex, startIndex + itemsPerPage);

  if (loading) {
    return (
      <LoadingComponent />
    );
  }


  return (
    <DashboardLayout>
      <MDBox p={6}>
        {programsAvailable.length === 0 ? (
          <MDTypography
            variant="h4"
            color="textSecondary"
            align="center"
            mt={10}
            sx={{
              fontStyle: "italic",
              fontWeight: "bold",
              color: "#9e9e9e", // Light grey color
              opacity: 0.7,
            }}
          >
            No programs available
          </MDTypography>
        ) : (
          <>
            <MDTypography variant="h4" fontWeight="medium" gutterBottom>
              Available Programs
            </MDTypography>
            <Grid container spacing={3}>
              {currentPrograms.map((program) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={program.id}>
                  <Card
                    sx={{
                      height: "150px",
                      p: 1,
                      boxShadow: 3,
                      "&:hover": {
                        boxShadow: 6, // Shadow intensity on hover
                      },
                    }}
                  >
                    <CardContent>
                      <MDTypography variant="h6" fontWeight="bold">
                        {program.program_name}
                      </MDTypography>
                      <MDTypography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontSize: "0.9rem",
                          fontFamily: "Courier New, monospace", // Change font family
                          fontWeight: "lighter", // Change font weight
                        }}
                      >
                        {program.degree_award}
                      </MDTypography>
                      <MDTypography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontSize: "0.9rem",
                          fontFamily: "Courier New, monospace", // Change font family
                          fontWeight: "lighter", // Change font weight
                        }}
                      >
                        {program.branch.name} branch
                      </MDTypography>
                      <MDTypography
                        variant="body1"
                        color="textPrimary"
                        sx={{ fontSize: ".9rem", fontFamily: "Georgia, serif" }}
                      >
                        {program.description}
                      </MDTypography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <MDBox display="flex" justifyContent="center" mt={3}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="secondary"
              />
            </MDBox>
          </>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default AvailablePrograms;

// ZoomCreate.js
import React, { useState,useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Alert,  Autocomplete,
    TextField, } from '@mui/material';
import swal from "sweetalert";
import AddIcon from '@mui/icons-material/Add';
import LoadingDialog from 'components/Loading/LoadingDialog';
import Switch from '@mui/material/Switch';
import { axiosInstance } from "api2";



const ZoomCreate = ({ open, onClose, onAdd }) => {
    const targetId = localStorage.getItem("targetId");
    const [modalData, setModalData] = useState({
        agenda: "",
        topic: "",
        duration: "",
        timezone: "",
        password: "",
        start_time: "",
        pre_schedule: 0,
        instructor_id: targetId, // Assuming this is set to a default instructor
        students: [], // Assuming this can be set based on your needs
    });
    const [courses, setCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [courseHistory,setCourseHistory] = useState([]);
    const [students,setStudents] = useState([])
    const [loading,setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const [courseHistory] = await Promise.all([
            //   axiosInstance.get(`instructor/${targetId}/courses`),
              axiosInstance.get(`instructor/${targetId}/students`),

            ]);
         
            setCourseHistory(courseHistory.data[0].course_history);

            const fetchedStudents = courseHistory.data[0].course_history.flatMap(course =>
                course.registrations.map(registration => ({
                    id: registration.student.id,
                    full_name: `${registration.student.first_name} ${registration.student.last_name}`,
                    email: registration.student.email_1,
                    phone_number: registration.student.phone,
                    qualification: registration.student.qualification // Assuming this property exists
                }))
            );

            setStudents(fetchedStudents);

            
            const coursesData = courseHistory.data[0].course_history.map(course => {
               
                return {
                  id: course.id, // Adjust the property names according to your data structure
                  name: course.course.course_name,
                  // Add any other properties you want to extract
                };
              });
           
              setCourses(coursesData);
            // setCourseHistory(courseHistory.data);
          } catch (error) {
            console.error("Error fetching semesters and years:", error);
          } finally{
            setLoading(false);
          }
        };
        fetchData();
      }, [targetId]);
    
    const [error, setError] = useState(null);
 
    const [isGroup, setIsGroup] = useState(false);

    const handleModalChange = (e) => {
        const { name, value } = e.target;
        setModalData({
            ...modalData,
            [name]: value,
        });
        setError(null);
    };

    useEffect(() => {
  
        if (selectedCourse) {
       
          // Filter course history based on the selected year and semester
          const filteredCourses = courseHistory.filter(course => {
            
            return Number(course.course_id) === Number(selectedCourse);
          });

          
          if (filteredCourses.length > 0) {
            // Collect student IDs from registrations
            const studentIds = filteredCourses.flatMap(course => {

                return course.registrations.map(registration => {
                   
                    return registration.student?.id; // Return the student ID
                });
            });


            // Update modalData with the student IDs
            setModalData(prevModalData => ({
                ...prevModalData,
                students: studentIds, // Set the students array with the collected student IDs
            }));

        }  else {
            console.log("No courses found for the selected year and semester.");
            setCourses([]); // Reset courses if no match is found
          }
        }
      
          
       
      }, [courseHistory,selectedCourse]);

    const handleModalSubmit = async () => {
        setLoading(true);
        
        try {
            const response = await axiosInstance.post(`/zoom`, modalData);
        
            if (response.status === 201) {
                onAdd(response.data);
                onClose();
                swal({
                    title: "Zoom Meeting Created Successfully!",
                    icon: "success"
                });
                setModalData({
                    agenda: "",
                    topic: "",
                    duration: "",
                    timezone: "",
                    password: "",
                    start_time: "",
                    pre_schedule: 0,
                    instructor_id: 1,
                    students: [],
                });
            }
        } catch (error) {
            setError(error.response?.data?.errors?.status_code[0]);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm">
            <DialogTitle textAlign={"center"}><AddIcon /> Create Zoom</DialogTitle>
            <DialogContent>
                {error && (
                    <MDBox mx={2} mt={2} mb={2}>
                        <Alert severity="error" variant="filled">
                            {error}
                        </Alert>
                    </MDBox>
                )}
                 <MDBox mb={2}>
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                        {isGroup ? "Group" : "Private"}
                    </MDTypography>
                    <Switch
                        checked={isGroup}
                        onChange={() => setIsGroup(prev => !prev)} // Toggle the group mode
                    />
                </MDBox>
                {isGroup && (
                 <MDBox mb={2}>
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                        Courses
                    </MDTypography>
                <Autocomplete
          options={courses}
          sx={{ my: 2, width: '100%' }}
          getOptionLabel={(option) => option.name}
          onChange={(event, newValue) => {
            setSelectedCourse(newValue?.id);
           
          }}
          renderInput={(params) => (
            <TextField {...params} label="Select Course" fullWidth required />
          )}
        />
        </MDBox>)}
                <MDBox mb={2}>
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                        Agenda
                    </MDTypography>
                    <MDInput
                        type="text"
                        name="agenda"
                        fullWidth
                        required
                        value={modalData.agenda}
                        onChange={handleModalChange}
                    />
                </MDBox>
                <MDBox mb={2}>
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                        Topic
                    </MDTypography>
                    <MDInput
                        type="text"
                        name="topic"
                        fullWidth
                        required
                        value={modalData.topic}
                        onChange={handleModalChange}
                    />
                </MDBox>
                <MDBox mb={2}>
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                        Duration (minutes)
                    </MDTypography>
                    <MDInput
                        type="number"
                        name="duration"
                        fullWidth
                        required
                        value={modalData.duration}
                        onChange={handleModalChange}
                    />
                </MDBox>

                <MDBox mb={2}>
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                        Password
                    </MDTypography>
                    <MDInput
                        type="password"
                        name="password"
                        fullWidth
                        required
                        value={modalData.password}
                        onChange={handleModalChange}
                    />
                </MDBox>
                <MDBox mb={2}>
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                        Start Time
                    </MDTypography>
                    <MDInput
                        type="datetime-local"
                        name="start_time"
                        fullWidth
                        required
                        value={modalData.start_time}
                        onChange={handleModalChange}
                    />
                </MDBox>
                {!isGroup && (
                                   <MDBox mb={2}>
                                   <MDTypography variant="caption" color="text" fontWeight="medium">
                                       {isGroup ? "Select Students" : "Student ID"}
                                   </MDTypography>
                                   
                                   <Autocomplete
                                            multiple
                                            options={students} // Assuming you have a list of students
                                            sx={{ my: 2, width: '100%' }}
                                            getOptionLabel={(option) => option.full_name} // Display full name
                                            onChange={(event, newValue) => {
                                                // Extract student IDs from the selected students
                                                const selectedStudentIds = newValue.map(student => student.id);
                                                
                                                // Push the new IDs into the existing students array in modalData
                                                setModalData((prevData) => ({
                                                ...prevData,
                                                students: [...prevData.students, ...selectedStudentIds], // Push new student IDs to the array
                                                }));
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Select Student(s)" fullWidth />
                                            )}
                                            />


                                   
                               </MDBox>
                    
                )
 
}

            
                <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
           Pre Schedule
          </MDTypography>
          <Switch
            checked={modalData.pre_schedule === 1}
            onChange={(e) =>
              setModalData({
                ...modalData,
                pre_schedule: e.target.checked ? 1 : 0,
              })
            }
          />
        </MDBox>
                
            </DialogContent>
            <DialogActions>
                <MDButton 
                    variant="gradient"
                    size="small"
                    color="info"
                    sx={{ background: "#00274D" }} 
                    onClick={handleModalSubmit}
                >
                    Submit
                </MDButton>
                <MDButton 
                    variant="gradient"
                    size="small"
                    color="black"
                    sx={{ background: "grey" }} 
                    onClick={onClose}
                >
                    Cancel
                </MDButton>
            </DialogActions>
            <LoadingDialog open={loading} />
        </Dialog>
    );
}

export default ZoomCreate;

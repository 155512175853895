import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Avatar, Button, Grid, Menu, MenuItem } from '@mui/material';
import API_BASE_URL, { STORAGE_BASE_URL } from 'api';
import axios from 'axios';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { useEffect, useState,useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LoadingDialog from 'components/Loading/LoadingDialog';
import DataTable from "examples/Tables/DataTable";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import PieChart from "examples/Charts/PieChart";
import MDBox from 'components/MDBox';


const RegistrarDashboard = () => {
  const navigate = useNavigate();
  const [search] = useState('');
  const[applicants,setApplicants] = useState([])

  const token =  localStorage.getItem("authToken");
  const [announcements, setAnnouncements] = useState([]);
   const [enrollmentData,setEnrollmentData] = useState([]);
   const [programData,setProgramData] = useState([]);
   const [counts,setCountsData] = useState([]);
   const [loading, setLoading] = useState(true);
 


  useEffect(() => {
    const fetchApplicants = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/applicant-student`, 
          
          {
          headers: { "Authorization": `Bearer ${token}` }
        });
       
        setApplicants(response.data);
      } catch (err) {
        console.log(err)
      }
    };
    fetchApplicants();
  }, [token]);
  
  const filteredData = applicants.filter(registrar => {
    return (
      registrar.first_name.toLowerCase().includes(search.toLowerCase()) ||
      registrar.email_1.toLowerCase().includes(search.toLowerCase()) 
     
    );

  });
  const handleViewDetail = (person) => {
    navigate('/viewApplicantDetail', { state: { person } });

  }

  const handleTransaction = async (payment) => {    

    if (payment != null && payment.transaction_id !== null ){
      try {
         if (payment.payment_method === "Chapa") {

        await axios.get(`${API_BASE_URL}/callback/${payment.transaction_id}`);    

   
         }
         else{
    


         }

      } catch (err) {
        console.error(err);
      }
    }
  };

  // eslint-disable-next-line no-sparse-arrays
  const columns = [ 
    {
      accessor: 'action',
      Header: '', 
      width:40,
      Cell: ({ row }) => (
        <PopupState popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                <MoreVertIcon />
              </Button>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={popupState.close}>Enter Entrance Result</MenuItem>
                <MenuItem onClick={popupState.close}>Enter Interview Result</MenuItem>
                <MenuItem onClick={popupState.close}>Approve</MenuItem>
                <MenuItem onClick={() => {
                  handleViewDetail(row.original);
                  popupState.close();
                }}>View Detail</MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      ),
      showSortIcons: false,
      
    },
    {
      Header: "Profile",
      id: "profile",
      accessor: row  => (
        <Avatar src={`${STORAGE_BASE_URL}/${row.profile_picture}`} />
      ),
      sortable: false,
      showSortIcons:false
    },
    {
      Header: 'Name',
      accessor: row => `${row.first_name} ${row.last_name}`, // Custom accessor for full name
      id: 'full_name', // Optional: Use an id for the column
      sorted: "none",

    },
    { Header: 'Telephone', accessor: 'phone', showSortIcons: false },
    { Header: 'Gender', accessor: 'gender',showSortIcons:false },
    { Header: 'Email', accessor: 'email_1' ,showSortIcons:false},
    { Header: 'Application Date', accessor: 'application_date' ,showSortIcons:false},

    {
      Header: 'Application Program',
      id:'application_program',
      accessor: row => (
        <Link to={`/programs/${row.program.id}`}>
          {row.program ? row.program.program_name : null}
        </Link>
      ),
      sortable: true,
      showSortIcons:false
    },  
    
    { Header: 'Postal Address', accessor: 'postal_address',showSortIcons:false },
    {
      Header: 'Application Fee',
      id: 'application_fee',
      showSortIcons:false,
      accessor: row => (
        <MDButton onClick={() => handleTransaction(row.payments.length ? row.payments[0] : null)}>
          {row.payments.length ? row.payments[0].transaction_id : 'No Transaction'}
        </MDButton>
      ),
    }

  ];
  




// Toggle visibility

const fetchData = useCallback(async () => {
  setLoading(true);
  try {
  const response = await axios.get(`${API_BASE_URL}/registrar-dashboard`,{
    headers:{
      "Authorization":`Bearer ${token}`
    }
  });


  
  setEnrollmentData(response.data.enrollmentData);
  setCountsData(response.data.counts);
  setProgramData(response.data.programData);
  setAnnouncements(response.data.announcements)
//   setStudents(response.data.latestStudents)
}catch (err) {
  console.error(err);
} finally {
  setLoading(false); // Set loading to false after fetching
}

},[token]);
useEffect(() => {
  fetchData();
},[fetchData]);

const total2 = [
    {
      label: "Total Students",
      count: counts.students || 0, // Dynamically set the total students
      icon: "person",
      color: "primary",
    },
    {
      label: "Total Courses",
      count:counts.courses || 0, // Dynamically set the total courses
      icon: "library_books",
      color: "secondary",
    },
    {
      label: "Total Instructors",
      count: counts.instructors || 0, // Dynamically set the total instructors
      icon: "school",
      color: "error",
    },
    {
        label: "Total Applications",
        count: counts.applications || 0, // Dynamically set the total instructors
        icon: "school",
        color: "success",
      },
   
  ];

const countReactDashboard = {
    labels:enrollmentData?.map((entry) => entry.year), // Extract years for the labels
    datasets: [
      {
        label: "Male",
        color: "info",
        data: enrollmentData?.map((entry) => entry.male), // Extract male enrollment counts
      },
      {
        label: "Female",
        color: "success",
        data: enrollmentData?.map((entry) => entry.female), // Extract female enrollment counts
      },
    ],
  };

  const programDatas = {
    labels: programData?.map((method) => method.name), // Extract labels
    datasets: 
      {
        label: "Programs",
        backgroundColors: ["info", "primary", "success", "warning"],
        data: programData?.map((method) => method.value), // Extract values
      },
    
  };
  





  return (
    <DashboardLayout>
    <Grid container spacing={3} mt={4}>

        <Grid item xs={12} >
            <Grid item xs={12} >
            <Grid container spacing={3}>
         
            {total2.map((item, index) => {
              return (
                <Grid item xs={true} key={index}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color={item.color}
                      icon={item.icon}
                      title={item.label}
                      count={item.count}
                     
                    />
                  </MDBox>
                </Grid>
              );
            })}
          </Grid>
          
            </Grid>

            {/* Other content like charts and applicant list can follow here */}

            {/* Row 1: Two Charts */}
            <Grid container spacing={3} mt={1}>
  {/* Charts */}
  <Grid item xs={12} sm={12} md={9} lg={9}>
    <Grid container spacing={3}>
      {/* First Chart: Enrollment Trends */}
      <Grid item xs={12} sm={6} md={6}>
        <>
          {enrollmentData.length === 0 ? (
            <MDTypography
              variant="h6"
              color="textSecondary"
              align="center"
              sx={{
                fontStyle: "italic",
                fontWeight: "bold",
                color: "#9e9e9e",
                opacity: 0.7,
              }}
            >
              No Enrollment Data Available
            </MDTypography>
          ) : (
            <VerticalBarChart
              icon={{ color: "info", component: "leaderboard" }}
              title="Enrollment Data"
              description="Total Enrollment in each year"
              chart={countReactDashboard}
            />
          )}
        </>
      </Grid>

      {/* Second Chart: Program Distribution */}
      <Grid item xs={12} sm={6} md={6}>
        <>
          {programData.length === 0 ? (
            <MDTypography
              variant="h6"
              color="textSecondary"
              align="center"
              sx={{
                fontStyle: "italic",
                fontWeight: "bold",
                color: "#9e9e9e",
                opacity: 0.7,
              }}
            >
              No Program Data Available
            </MDTypography>
          ) : (
            <PieChart
              icon={{ color: "info", component: "group" }}
              title="Programs"
              description="Total Programs in Each Branch"
              chart={programDatas}
            />
          )}
        </>
      </Grid>
    </Grid>
  </Grid>

  {/* Announcements Section */}
  <Grid item xs={12} sm={12} md={3} lg={3}>
    {/* Announcements Header */}
    <MDBox sx={{ flex: 1, p: 2, bgcolor: '#f9f9f9', borderRadius: 2, boxShadow: 1 }}>
      <MDTypography variant="h5" fontWeight="medium" sx={{ mb: 2 }}>
        Announcements
      </MDTypography>
      {announcements && announcements.map((item) => (
        <MDBox key={item.id} sx={{ mb: 2, p: 2, bgcolor: '#ffffff', borderRadius: 1, boxShadow: 1 }}>
          <MDTypography variant="h6" fontWeight="medium">
            {item.title}
          </MDTypography>
          <MDTypography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
            <strong>Announced By:</strong> {item.announced_by} | <strong>Date:</strong> {new Date(item.announcement_date).toLocaleDateString()}
          </MDTypography>
          <MDTypography variant="body2" sx={{ color: 'text.primary' }}>
            {item.content}
          </MDTypography>
        </MDBox>
      ))}
    </MDBox>
  </Grid>
</Grid>

        </Grid>

      

        <Grid item xs={12}>
        {applicants.length > 0 ? ( 
        <DataTable
              table={{
                columns,
                rows: filteredData,
              }}
              canSearch={true}
              sortable={true}
              pagination
              rowsPerPage={5}
              showPagination
            />
          ) : (
            <MDTypography>No Applicant available.</MDTypography> // Fallback message
          )}
          </Grid>
    </Grid>
    <LoadingDialog open={loading}/>
</DashboardLayout>


  )
}

export default RegistrarDashboard
import React, { useState ,useEffect,useCallback} from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MDBox from 'components/MDBox';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import API_BASE_URL from "api";
import axios from "axios";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import swal from 'sweetalert';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddRoom from "./addRoom";
import EditRoom from "./editRoom";


const Rooms = () => {
  
    const token =  localStorage.getItem("authToken");
    
    const [showAlert, setShowAlert] = useState(false);
    
  
    const [openEdit, setOpenEdit] = useState(false);
    const[rooms,setRooms] = useState([])
    const [selectedRooms, setSelectedRooms] = useState(null);

    const [openAdd, setOpenAdd] = useState(false);

    useEffect(() => {
        let timer;
        if (showAlert) {
          timer = setTimeout(() => {
            setShowAlert(false);
          }, 5000); 
        }
        return () => clearTimeout(timer); 
      }, [showAlert]);

      const fetchRooms = useCallback(async () => {
        try {
          const response = await axios.get(`${API_BASE_URL}/dorms`, {
            headers: { "Authorization": `Bearer ${token}` }
          });
          setRooms(response.data);
        } catch (err) {
          console.error(err);
        }
      }, [token]);
      
      useEffect(() => {
        fetchRooms();
      }, [fetchRooms]);
      

      const handleAddRoom = () => {
        
        fetchRooms();
      };
      
    
      const handleEditRooms= (updatedRooms) => {
        fetchRooms();
      };

    
      const handleDeleteRooms = async (id) => {
        const confirm = await swal({
          title: "Are you sure?",
          text: "You will not be able to recover this rooms!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        });
    
        if (confirm) {
          try {
            const response = await axios.delete(`${API_BASE_URL}/dorms/${id}`,
              {
              headers: { "Authorization": `Bearer ${token}` }
            }
          );
            if(response.status === 204){
              setRooms(rooms.filter((room) => room.id !== id));
              swal({
                title: "Dorm Deleted Successfully!",
                // text: "Course Created Successfully!",
                icon: "success"
              });
            }
          } catch (error) {
          
            swal({
              title: `${error.response.data.message}`,
              // text: "Course Created Successfully!",
              icon: "warning"
            });
          }
        }
      };

      const handleOpenEdit = (rooms) => {
       
        setSelectedRooms(rooms);
        setOpenEdit(true);
      };
    
      const handleCloseDialog = () => {
        setOpenAdd(false);
        setOpenEdit(false);
        setSelectedRooms(null);
      };


      const columns = [
        {
          accessor: 'action',
          Header: '', 
          width: 40,  
          padding: 0,
          Cell: ({ row }) => (
            <PopupState popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                    <MoreVertIcon />
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem onClick={() => {
                      handleOpenEdit(row.original);
                      popupState.close();
                    }} style={{ color: '#1976d2' }}>
                      <EditIcon style={{ marginRight: 8 }} /> Edit
                    </MenuItem>
                    <MenuItem onClick={() => {
                      handleDeleteRooms(row.original.id);
                      popupState.close();
                    }} style={{ color: '#d32f2f' }}>
                      <DeleteIcon style={{ marginRight: 8 }} /> Delete
                    </MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          ),
          showSortIcons: false,
        },
        { Header: 'Room Number', accessor: 'room_number', showSortIcons: true },  
        { Header: 'Building Name', accessor: 'building_name', showSortIcons: true }, 
        { Header: 'Floor Number', accessor: 'floor_number', showSortIcons: false },   
        { Header: 'Capacity', accessor: 'capacity', showSortIcons: false },          
        { Header: 'Current Quantity', accessor: 'current_quantity', showSortIcons: false },
        {
          Header: 'Available',
          accessor: 'is_available',
          Cell: ({ value }) =>  {
            
            return value ? (
              <CheckIcon style={{ color: 'green' }} />
            ) : (
              <CloseIcon style={{ color: 'red' }} />
            );
          },
          showSortIcons: false,   
        }
      ];

    
      
  return (
    <DashboardLayout>
        <Card>
            <CardContent>
                <MDBox>
                <MDTypography variant="h5" fontWeight="medium">
                   Rooms
                 </MDTypography>
                 <MDBox>
                        <DataTable
                          table={{
                            columns,
                            rows: rooms,
                          }}
                          canSearch={true}
                          sortable={true}
                          pagination
                          rowsPerPage={5}
                          showPagination
                          widgets = {
                            <MDBox ms={2}>
                           <MDButton
                           variant="gradient"
                           ml="auto"
                               color="info"
                               size="small"
                               onClick={() => setOpenAdd(true)}
                               sx={{background:"#00274D", my:4,mx:3}}
                         >
                           <span>Add</span>
                         </MDButton>
                         </MDBox>
                          }
                        />
                 </MDBox>
                 <AddRoom open={openAdd} onClose={handleCloseDialog} onAdd={handleAddRoom} />
                
                {selectedRooms && (
                <EditRoom
                    open={openEdit}
                    onClose={handleCloseDialog}
                    rooms={selectedRooms}
                    onEdit={handleEditRooms}
                />
                )}
                
                
                </MDBox>
            </CardContent>
        </Card>
    </DashboardLayout>
  )
}

export default Rooms
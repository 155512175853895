import React, { useState ,useEffect} from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MDBox from 'components/MDBox';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import API_BASE_URL from "api";
import axios from "axios";
import LoadingComponent from "components/Loading";

import swal from 'sweetalert';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddPermissionDialog from "./addPermission";
import EditPermissionDialog from "./editPermission";
import LoadingDialog from "components/Loading/LoadingDialog";

const Permission = () => {
    const [search] = useState('');
  const token =  localStorage.getItem("authToken");
  const [loading, setLoading] = useState(true);

  const [openEdit, setOpenEdit] = useState(false);
  const[permissions,setPermissions] = useState([])
  const [selectedPermission, setSelectedPermission] = useState(null);
  const [openAdd, setOpenAdd] = useState(false);
  const [deleting,setDeleting] = useState(false);



  useEffect(() => {
    const fetchPermissions = async () => {
      try {
      
        const response = await axios.get(`${API_BASE_URL}/permissions`, {
          headers: { "Authorization": `Bearer ${token}` }
        });
      

        setPermissions(response.data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
      
    };
    fetchPermissions();
  }, [token]);


  const handleAddPermissions = (newPermissions) => {
    setPermissions((prevPermissions) => [...prevPermissions, newPermissions]);
  };
  

  const handleEditPermission = (updatedPermission) => {
    setPermissions(permissions.map((permission) =>
      permission.id === updatedPermission.id ? updatedPermission : permission
    ));
  };

  const handleDeletePermission = async (id) => {
    setDeleting(true);
    const confirm = await swal({
      title: "Are you sure?",
      text: "You will not be able to recover this permissions!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
    if (confirm) {
     
      try {
        const response = await axios.delete(`${API_BASE_URL}/permissions/${id}`,
          {
          headers: { "Authorization": `Bearer ${token}` }
        }
      );
        if(response.status === 200){
          setPermissions(permissions.filter((semester) => semester.id !== id));
          swal({
            title: "Permissions Deleted Successfully!",
        
            icon: "success"
          });
        }
      } catch (error) {
      
        swal({
          title: `${error.response.data.message}`,
      
          icon: "warning"
        });
      }finally{
        setDeleting(false)
    }
};
  };

  const handleOpenEdit = (permission) => {
    setSelectedPermission(permission);
    setOpenEdit(true);
  };

  const handleCloseDialog = () => {
    setOpenAdd(false);
    setOpenEdit(false);
    setSelectedPermission(null);
  };

  const filteredData = search
  ? permissions && permissions.filter(permission => 
      permission.name &&
      permission.name.toLowerCase().includes(search.toLowerCase())
    )
  : permissions;


  const columns = [
    {
      accessor: 'action',
      Header: '',  
      width: 40,  
      padding:0,
      Cell: ({ row }) => (
        <PopupState popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                <MoreVertIcon />
              </Button>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={() => {
                  handleOpenEdit(row.original);
                  popupState.close();
                }}  style={{ color: '#1976d2' }}>
                  <EditIcon style={{ marginRight: 8 }} /> Edit
                </MenuItem>
                <MenuItem onClick={() => {
                  handleDeletePermission(row.original.id);
                  popupState.close();
                }}  style={{ color: '#d32f2f' }}>
                  <DeleteIcon style={{ marginRight: 8 }} /> Delete
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      ),
      showSortIcons: false,
      
      
    },
    { Header: 'name', accessor: 'name' },
    // { Header: 'Description', accessor: 'description',showSortIcons: false, },
   
  ];




  return (
    <DashboardLayout>
    <Card>
      <CardContent>
        <MDBox>
        <MDTypography variant="h5" fontWeight="medium">
               Permission
             </MDTypography>
        
               <MDBox>
               {loading ? (
                <LoadingComponent />
              ) : <DataTable
              table={{
                columns,
                rows: filteredData,
              }}
              canSearch={true}
              sortable={true}
              pagination
              rowsPerPage={5}
              showPagination
              widgets = {
                <MDBox ms={2}>
               <MDButton
               variant="gradient"
               ml="auto"
                   color="info"
                   size="small"
                   onClick={() => setOpenAdd(true)}
                   sx={{background:"#00274D", my:4,mx:3}}
             >
               <span>Add Permission</span>
             </MDButton>
             </MDBox>
              }
            />
            }
               </MDBox>
               <AddPermissionDialog open={openAdd} onClose={handleCloseDialog} onAdd={handleAddPermissions} />
        
               {selectedPermission && (
                 <EditPermissionDialog
                   open={openEdit}
                   onClose={handleCloseDialog}
                   permission={selectedPermission}
                   onEdit={handleEditPermission}
                 />
               )}
        
         </MDBox>
         </CardContent>
        </Card>
        <LoadingDialog open={deleting}/>
        </DashboardLayout>
  )
}

export default Permission
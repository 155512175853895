import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  Autocomplete,
  TextField,
  Card,CardContent
} from '@mui/material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { axiosInstance } from 'api2';
import swal from 'sweetalert';
import { makeStyles } from '@mui/styles';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import LoadingDialog from 'components/Loading/LoadingDialog';


const useStyles = makeStyles(() => ({
  radio: {
    fontWeight: 'bold',
    color: '#1976d2',
    '&:checked': {
      color: '#1976d2',
    },
  },
  tableContainer: {
    marginTop: '1rem',
    border: '1px solid #ccc',
    borderRadius: '8px',
    overflowX: 'auto', // Make table horizontally scrollable
  },
  tableHeader: {
    display: 'flex',
    backgroundColor: '#f0f0f0',
    padding: '1rem',
    borderBottom: '1px solid #ccc',
  },
  tableRow: {
    display: 'flex',
    borderBottom: '1px solid #ccc',
    padding: '0.75rem',
  },
  tableCell: {
    flex: 1,
    textAlign: 'center',
    padding: '0.5rem',
  },
  tableItem: {
    flex: 2.5,
    padding: '0.75rem',
    fontSize: '1.1rem',
  },
  radioControl: {
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    borderRadius: '50%',
    padding: '2px',
  },
  submitButton: {
    backgroundColor: '#00274D',
    marginTop: '2rem',
  },
}));


const TeacherEvaluation = () => {
  const classes = useStyles();
  const targetId = localStorage.getItem("targetId");
  const [evaluations, setEvaluations] = useState([]);
  const [years, setYears] = useState([]);
  const [semesters, setSemesters] = useState([]);
  const [instructorName, setInstructorName] = useState("");
  const [modalData, setModalData] = useState({
    student_id: targetId,
    semester: "",
    year: "",
    instructor_id: null,
    course_id: null
  });
  const [courses, setCourses] = useState([]);
  const [courseHistory, setCourseHistory] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [semestersData, yearsData,courseHistory] = await Promise.all([
          axiosInstance.get('/semesters'),
          axiosInstance.get('/years'),
          axiosInstance.get('/course-history'),
        ]);
        setSemesters(semestersData.data);
        setYears(yearsData.data);
        setCourseHistory(courseHistory.data);
      } catch (error) {
        console.error("Error fetching semesters and years:", error);
      } finally{
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchEvaluations = async () => {
      try {
        const response = await axiosInstance.get('/evaluations');
        setEvaluations(response.data);
      } catch (error) {
        console.error('Error fetching evaluations:', error);
      }
    };
    fetchEvaluations();
  }, []);

  const handleRadioChange = (event, id) => {
    setSelectedValues({
      ...selectedValues,
      [id]: event.target.value,
    });
  };

  useEffect(() => {
  
    if (modalData.year && modalData.semester) {
   
      // Filter course history based on the selected year and semester
      const filteredCourses = courseHistory.filter(course => 
        Number(course.year) === Number(modalData.year) &&
        String(course.semester) === String(modalData.semester)
      );
      
      if (filteredCourses.length > 0) {
        // Map the filtered courses to the desired structure
        const coursesData = filteredCourses.map(course => ({
          course_id:course.course?.id,
          course_number: course.course?.course_number,
          course_name: course.course?.course_name,
          course_description: course.course?.course_description,
          credit_hour: course.course?.credit_hour,
          instructor: course.instructor?.full_name,
          instructor_id:course.instructor?.id,
          is_approved: course.course?.is_approved,
          is_active: course.course?.is_active
        }));
  
        setCourses(coursesData);  // Assuming you have a state to store courses
        
      } else {
        console.log("No courses found for the selected year and semester.");
        setCourses([]); // Reset courses if no match is found
      }
    }
  
      
   
  }, [courseHistory,modalData.year, modalData.semester]);

  

  const handleSubmit = async () => {
    const evaluationIds = Object.keys(selectedValues);
    const points = Object.values(selectedValues);
    console.log("modalData:",modalData)

    const formData = {
      evaluation_id: evaluationIds.map(id => parseInt(id)),
      point: points.map(point => parseInt(point)),
      student_id: Number(modalData.student_id),
      instructor_id: modalData.instructor_id,
      evaluation_date: new Date().toISOString().split('T')[0],
      course_id: modalData.course_id,
      year: parseInt(modalData.year),
      semester: modalData.semester,
    };

    try {
      const response = await axiosInstance.post('/teacher-evaluations', formData);
      if (response.status === 201) {
        swal({
          title: "Evaluation Submitted Successfully!",
          icon: "success",
        });
        window.location.reload();
      }
    } catch (error) {
      console.error('Error submitting evaluation:', error);
    }
  };





  return (
    <DashboardLayout>
    
    
      <Card>
        <CardContent>
        <MDTypography variant="h5" fontWeight="medium" mt={2}>
        Instructor Evaluation
    </MDTypography>
  
  <Box sx={{ padding: 3 }}>
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <Typography variant="caption" color="text.secondary" fontWeight="medium">
          Semester
        </Typography>
        <Autocomplete
          options={semesters}
          sx={{ mx: 2, my: 2, width: '100%' }}
          getOptionLabel={(option) => option.semester_name}
          onChange={(event, newValue) => {
            setModalData({
              ...modalData,
              semester: newValue ? newValue.semester_code : null,
            });
          }}
          renderInput={(params) => (
            <TextField {...params} label="Select Semester" fullWidth required />
          )}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <Typography variant="caption" color="text.secondary" fontWeight="medium">
          Year
        </Typography>
        <Autocomplete
          options={years}
          sx={{ mx: 2, my: 2, width: '100%' }}
          getOptionLabel={(option) => option.year_name}
          onChange={(event, newValue) => {
            setModalData({
              ...modalData,
              year: newValue ? newValue.year_name : null,
            });
          }}
          renderInput={(params) => (
            <TextField {...params} label="Select Year" fullWidth required />
          )}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <Typography variant="caption" color="text.secondary" fontWeight="medium">
          Courses
        </Typography>
        <Autocomplete
          options={courses}
          sx={{ mx: 2, my: 2, width: '100%' }}
          getOptionLabel={(option) => `${option.course_number} - ${option.course_name}`}
          onChange={(event, newValue) => {
            if (newValue) {
              setModalData({
                ...modalData,
                course_id: newValue.course_id,
                instructor_id: newValue.instructor_id,
              });
              setInstructorName(newValue.instructor || '');
            } else {
              setModalData({
                ...modalData,
                course_id: null,
                instructor_id: null,
              });
              setInstructorName('');
            }
          }}
          renderInput={(params) => <TextField {...params} label="Course" />}
        />
      </Grid>
    </Grid>

    <Typography variant="caption" color="text.secondary" fontWeight="medium" sx={{ mt: 3 }}>
      Instructor: {instructorName || "Select a course to see the instructor"}
    </Typography>

    {evaluations.length > 0 ? (
      <Box sx={{ mt: 2, border: '1px solid #ccc', borderRadius: 1, overflowX: 'auto' }}>
        <Box sx={{ minWidth: '600px' }}> {/* Wrapper to ensure minimum width */}
          <Box sx={{ display: 'flex', backgroundColor: '#f0f0f0', padding: 1, fontSize: '1.1rem' }}>
            <Box sx={{ flex: 2, padding: 1 }}>
              <strong>Evaluation Item</strong>
            </Box>
            {[1, 2, 3, 4, 5].map((value) => (
              <Box key={value} sx={{ flex: 1, textAlign: 'center', padding: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <strong>{value}</strong>
              </Box>
            ))}
          </Box>
        </Box>

        {evaluations.map((evalItem) => (
          <Box key={evalItem.id} sx={{ display: 'flex', borderBottom: '1px solid #ccc', padding: 1, minWidth: '600px' }}>
            <Box sx={{ flex: 2.3, fontSize: '0.9rem', display: 'flex', alignItems: 'center' }}>
              {evalItem.description}
            </Box>
            {[1, 2, 3, 4, 5].map((value) => (
              <Box key={value} sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 1 }}>
                <RadioGroup
                  row
                  value={selectedValues[evalItem.id] || ''}
                  onChange={(e) => handleRadioChange(e, evalItem.id)}
                >
                  <FormControlLabel
                    sx={{
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                      borderRadius: '50%',
                      padding: '2px',
                      margin: '0 auto',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    value={String(value)}
                    control={<Radio />}
                    label={null}
                  />
                </RadioGroup>
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    ) : (
      <Box display="flex" justifyContent="center" alignItems="center" height="200px" mt={2}>
        <Typography variant="h6" color="textSecondary">
          No Evaluation Items Available
        </Typography>
      </Box>
    )}

    {evaluations.length > 0 && (
      <MDButton
        variant="gradient"
        color="info"
        size="small"
        className={classes.submitButton}
        onClick={handleSubmit}
        sx={{ background: "#00274D", my: 2 }}
      >
        Submit
      </MDButton>
    )}
  </Box>

       <LoadingDialog open={loading}/>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
};

export default TeacherEvaluation;

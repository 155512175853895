import React, { useState ,useEffect,useCallback} from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import API_BASE_URL from "api";
import axios from "axios";
import AddLeaveRequest from "./addLeaveRequest";
import EditLeaveRequest from "./editLeaveRequest";
import LoadingDialog from 'components/Loading/LoadingDialog';


const LeaveRequest = () => {
    const targetId = localStorage.getItem("targetId");
    const token =  localStorage.getItem("authToken");
    const [loading,setLoading] = useState(true)
    const [openEdit, setOpenEdit] = useState(false);
    const[leaveRequests,setLeaveRequests] = useState([])
    const [selectedLeaveRequest, setSelectedLeaveRequest] = useState(null);
  


    const [openAdd, setOpenAdd] = useState(false);
      const fetchLeaveRequests = useCallback(async () => {
        try {
          const response = await axios.get(`${API_BASE_URL}/employee/${targetId}/leaves`, {
            headers: { "Authorization": `Bearer ${token}` }
          });
         
          setLeaveRequests(response.data);
        } catch (err) {
          console.error(err);
        }finally {
          setLoading(false)
        }
      }, [token,targetId]);
      
      useEffect(() => {
        fetchLeaveRequests();
      }, [fetchLeaveRequests]);
      
      const handleAddLeaveRequest = (data) => {
        setLeaveRequests([...leaveRequests, data]);
        
       
      };

      const handleEditLeaveRequest = ( ) => {
        fetchLeaveRequests();
      };

  

  
      const handleCloseDialog = () => {
        setOpenAdd(false);
        setOpenEdit(false);
        setSelectedLeaveRequest(null);
      };
     
      const columns = [
        // {
        //   accessor: 'action',
        //   Header: '',
        //   width: 40,
        //   padding: 0,
        //   Cell: ({ row }) => (
        //     <PopupState variant="popover" popupId="demo-popup-menu">
        //       {(popupState) => (
        //         <React.Fragment>
        //           <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
        //             <MoreVertIcon />
        //           </Button>
        //           <Menu {...bindMenu(popupState)}>
        //             <MenuItem onClick={() => {
        //               handleOpenEdit(row.original);
        //               popupState.close();
        //             }} style={{ color: '#1976d2' }}>
        //               <EditIcon style={{ marginRight: 8 }} /> Edit
        //             </MenuItem>
        //             <MenuItem onClick={() => {
        //               handleDeleteLeaveRequest(row.original.id);
        //               popupState.close();
        //             }} style={{ color: '#d32f2f' }}>
        //               <DeleteIcon style={{ marginRight: 8 }} /> Delete
        //             </MenuItem>
        //           </Menu>
        //         </React.Fragment>
        //       )}
        //     </PopupState>
        //   ),
        //   showSortIcons: false,
        // },
        // { Header: 'Employee ID', accessor: 'employee_id', showSortIcons: true },
        { Header: 'Leave Type Name', accessor: 'leave_type.leave_type_name', showSortIcons: true },
        { Header: 'Start Date', accessor: 'start_date', showSortIcons: false },
        { Header: 'End Date', accessor: 'end_date', showSortIcons: false },
        { Header: 'Reason', accessor: 'reason', showSortIcons: false },
        { Header: 'Status', accessor: 'status', showSortIcons: false },
        { Header: 'Applicable For', accessor: 'leave_type.applicable_for', showSortIcons: false },
      
      ];
      
    
      
  return (
    <DashboardLayout>
    <Card>
        <CardContent>
            <MDBox>
            <MDTypography my={2} variant="h5" fontWeight="medium">
               Leave Requests
             </MDTypography>
             <MDBox>
          
                          <DataTable
                              table={{
                                  columns,
                                  rows: leaveRequests,
                              }}
                              canSearch={true}
                              sortable={true}
                              rowsPerPage={5}
                              showPagination
                          />  
                          {!loading && leaveRequests.length === 0 && (
                            <MDTypography    variant="h5" display="block" align="center" my={2}  sx={{
                              fontStyle: "italic",
                              fontWeight: "bold",
                              color: "#9e9e9e", // Light grey color
                              opacity: 0.7,
                            }}>
                              Leave Requests not available
                            </MDTypography>
)}
   
             </MDBox>
             <AddLeaveRequest open={openAdd} onClose={handleCloseDialog} onAdd={handleAddLeaveRequest} />
            
            {selectedLeaveRequest && (
            <EditLeaveRequest
                open={openEdit}
                onClose={handleCloseDialog}
                leaveRequests={selectedLeaveRequest}
                onEdit={handleEditLeaveRequest}
            />
            )}
            
            
            </MDBox>
        </CardContent>
        <LoadingDialog open={loading} />
    </Card>
</DashboardLayout>
  )
}

export default LeaveRequest
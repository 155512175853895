import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import React, { useEffect, useState } from "react";

import LoadingDialog from 'components/Loading/LoadingDialog';
import { axiosInstance } from 'api2';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';

const Evaluation = () => {
    const [evaluation, setEvaluation] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchStatus = async () => {
          setLoading(true); // Start loading
        
          try {
            const response = await axiosInstance.get(`/evaluations`
            );
            console.log("response;",response.data)
            setEvaluation(response.data);
          } catch (err) {
            console.error(err);
          } finally {
            setLoading(false); // End loading after fetching data
          } 
        }
         
    fetchStatus();
}, []);

const columns = [
    { 
      Header: 'Status Name', 
      accessor: 'status_name' 
    },
    { 
      Header: 'Status Code', 
      accessor: 'status_code', 
      showSortIcons: false 
    },
   
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'Poor Description',
      accessor: 'poor_description',
    },
    {
      Header: 'Excellent Description',
      accessor: 'excellent_description',
    },
    {
      Header: 'Type',
      accessor: 'type',
    },

  ];
  
      
  return (
    <DashboardLayout>
    <MDBox>
    <MDTypography variant="h5" fontWeight="medium">
      Evaluation
    </MDTypography>

    <MDBox>
   

      <DataTable
        table={{
          columns,
          rows: evaluation,
        }}
        canSearch={true}
        sortable={true}
        rowsPerPage={5}
        showPagination
        
      />

      {!loading && evaluation.length === 0 && (
        <MDTypography    variant="h5" display="block" align="center" my={2}  sx={{
          fontStyle: "italic",
          fontWeight: "bold",
          color: "#9e9e9e", // Light grey color
          opacity: 0.7,
        }}>
          No Evaluation available
        </MDTypography>
      )}
    </MDBox>

    

    
    <LoadingDialog open={loading } />
  </MDBox>
  </DashboardLayout>
  )
}

export default Evaluation
import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import swal from "sweetalert";
import { axiosInstance } from "api2";
import LoadingDialog from "components/Loading/LoadingDialog";

const EditLeaveType = ({ open, onClose, leaveTypes, onEdit }) => {
  const [modalData, setModalData] = useState({
    leave_type_name: leaveTypes?.leave_type_name || "",
    description: leaveTypes?.description || "",
    accrual_rate: leaveTypes?.accrual_rate || 0.0,
    maximum_carryover: leaveTypes?.maximum_carryover || 0,
  });
  const [loading,setLoading] = useState(false);

  const [error, setError] = useState(null);

  // Update modal data when leaveTypes prop changes
  useEffect(() => {
    if (leaveTypes) {
      setModalData({
        leave_type_name: leaveTypes.leave_type_name || "",
        description: leaveTypes.description || "",
        accrual_rate: leaveTypes.accrual_rate || 0.0,
        maximum_carryover: leaveTypes.maximum_carryover || 0,
        id: leaveTypes.id,
      });
    }
  }, [leaveTypes]);

  // Handle input change
  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData({
      ...modalData,
      [name]: value,
    });
    setError(null); // Reset error state on change
  };

  // Handle form submission
  const handleModalSubmit = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.put(`/leave-types/${modalData.id}`, modalData);
      if (response.status === 200) {
        onEdit(); // Call onEdit to update the list
        onClose(); // Close the modal
        swal({
          title: "Leave Type Updated Successfully!",
          icon: "success",
        });
      }
    } catch (error) {
      console.log(error.response);
      setError("An error occurred");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle textAlign="center">Edit Leave Type</DialogTitle>
      <DialogContent>
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Leave Type Name
          </MDTypography>
          <MDInput
            type="text"
            name="leave_type_name"
            fullWidth
            required
            value={modalData.leave_type_name}
            onChange={handleModalChange}
          />
          {error && error.leave_type_name && (
            <MDTypography variant="caption" color="error">
              {error.leave_type_name[0]}
            </MDTypography>
          )}
        </MDBox>

        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Description
          </MDTypography>
          <MDInput
            type="text"
            name="description"
            fullWidth
            value={modalData.description || ""}
            onChange={handleModalChange}
          />
          {error && error.description && (
            <MDTypography variant="caption" color="error">
              {error.description[0]}
            </MDTypography>
          )}
        </MDBox>

        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Accrual Rate
          </MDTypography>
          <MDInput
            type="number"
            name="accrual_rate"
            fullWidth
            required
            value={modalData.accrual_rate}
            onChange={handleModalChange}
          />
          {error && error.accrual_rate && (
            <MDTypography variant="caption" color="error">
              {error.accrual_rate[0]}
            </MDTypography>
          )}
        </MDBox>

        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Maximum Carryover
          </MDTypography>
          <MDInput
            type="number"
            name="maximum_carryover"
            fullWidth
            required
            value={modalData.maximum_carryover}
            onChange={handleModalChange}
          />
          {error && error.maximum_carryover && (
            <MDTypography variant="caption" color="error">
              {error.maximum_carryover[0]}
            </MDTypography>
          )}
        </MDBox>
      </DialogContent>

      <DialogActions>
        <MDButton
          variant="gradient"
          size="small"
          color="info"
          sx={{ background: "#00274D" }}
          onClick={handleModalSubmit}
        >
          Submit
        </MDButton>
        <MDButton
          variant="gradient"
          size="small"
          color="black"
          sx={{ background: "grey" }}
          onClick={onClose}
        >
          Cancel
        </MDButton>
      </DialogActions>
      <LoadingDialog open={loading} />
    </Dialog>
  );
};

export default EditLeaveType;

import React, { useState, useEffect,useCallback } from "react";
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Autocomplete, Card, CardContent, TextField } from '@mui/material';
import API_BASE_URL from "api";
import axios from "axios";
import LoadingDialog from "components/Loading/LoadingDialog";

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Authorization': `Bearer ${localStorage.getItem('authToken')}`, 
    'Content-Type': 'application/json',
  },
});

const MarkList = () => {
    const [years, setYears] = useState([]);
    const [semesters, setSemesters] = useState(null);
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [markList, setMarkList] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedSemester, setSelectedSemester] = useState(null);
    const targetId = localStorage.getItem("targetId");
  
    const token = localStorage.getItem("authToken");

    useEffect(() => {
        const fetchData = async () => {
          try {
            const [coursesData, yearsData,semestersData] = await Promise.all([
              axiosInstance.get(`/instructor/${targetId}/courses`),
              axiosInstance.get('/years'),
              axiosInstance.get('/semesters'),
            ]);
            const coursesArray = coursesData.data[0].course_history.map(course => ({
              id: course.course_id,
              name: course.course?.course_name || 'Unknown Course'
            }));
            setCourses(coursesArray);
            setYears(yearsData.data);
            setSemesters(semestersData.data);
          } catch (error) {
            console.error("Error fetching courses and years:", error);
          }
        };
        setLoading(false);
    
        fetchData();
      }, [token,targetId]);

    const fetchMarkList = useCallback(async (modalData) => {
      try {
        const response = await axiosInstance.get(`${API_BASE_URL}/student/marklist`, {
          params: modalData,
          headers: { Authorization: `Bearer ${token}` }
        });
        setMarkList(response.data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    },[token]);
    

    // Auto-submit when all fields are selected
    useEffect(() => {
      if (selectedCourse && selectedYear && selectedSemester) {
        const modalData = {
          course_id: selectedCourse.id,
          year: selectedYear.year_name,
          semester: selectedSemester.semester_code,
        };
        fetchMarkList(modalData);
        
      }
    },[fetchMarkList,selectedCourse,selectedYear,selectedSemester]);

  
    const generateScoreColumns = (scoreFields, prefix) => {
      return Object.keys(scoreFields).map((key) => ({
        Header: `${prefix} ${key.replace('_', ' ')}`,
        accessor: `${prefix === 'Assignment' ? 'assignment_scores' : 'scores'}.${key}`,
      }));
    };

    const sampleMark = markList.length > 0 ? markList[0] : {
      scores: {
        quiz: 0,
        mid: 0,
        final: 0,
      },
      assignment_scores: {
        individual: 0,
        group: 0,
      },
    };

    const baseColumns = [
      {
        Header: 'Student Name',
        accessor: (row) => `${row.student_name.first_name} ${row.student_name.last_name}`,
      },
      {
        Header: 'Total',
        accessor: 'total',
      },
      {
        Header: 'Grade',
        accessor: 'grade',
      },
    ];

    const columns = [
      ...baseColumns,
      ...generateScoreColumns(sampleMark.scores, 'Score'),
      ...generateScoreColumns(sampleMark.assignment_scores, 'Assignment'),
    ];

    return (
      <DashboardLayout>
        <Card>
          <CardContent>
            <MDBox>
              <MDTypography variant="h5" fontWeight="medium">
                Mark List
              </MDTypography>

              <MDBox>
            <DataTable
                    table={{
                      columns,
                      rows: markList,
                    }}
                    canSearch={true}
                    sortable={true}
                    rowsPerPage={5}
                    showPagination
                    widgets={
                      <MDBox display="flex">
                        {/* Course Autocomplete */}
                        <Autocomplete
                          fullWidth
                          sx={{ mx: 2, my: 2, width: '200px' }}
                          options={courses}
                          getOptionLabel={(option) => option.name || ''}
                          onChange={(event, newValue) => setSelectedCourse(newValue)}
                          value={selectedCourse}
                          renderInput={(params) => <TextField {...params} label="Course" />}
                        />
                        {/* Year Autocomplete */}
                        <Autocomplete
                          fullWidth
                          sx={{ mx: 2, my: 2, width: '200px' }}
                          options={years}
                          getOptionLabel={(option) => option.year_name || ''}
                          onChange={(event, newValue) => setSelectedYear(newValue)}
                          value={selectedYear}
                          renderInput={(params) => <TextField {...params} label="Year" />}
                        />
                        {/* Semester Autocomplete */}
                        <Autocomplete
                          fullWidth
                          sx={{ mx: 2, my: 2, width: '200px' }}
                          options={semesters}
                          getOptionLabel={(option) => option.semester_code || ''}
                          onChange={(event, newValue) => setSelectedSemester(newValue)}
                          value={selectedSemester}
                          renderInput={(params) => <TextField {...params} label="Semester" />}
                        />
                      </MDBox>
                    }
                  />
                    {!loading && markList.length === 0 && (
          <MDTypography    variant="h5" display="block" align="center" my={2}  sx={{
            fontStyle: "italic",
            fontWeight: "bold",
            color: "#9e9e9e", // Light grey color
            opacity: 0.7,
          }}>
            No marklist available
          </MDTypography>
        )}
              </MDBox>
            </MDBox>
          </CardContent>
        </Card>
        <LoadingDialog open={loading} />
      </DashboardLayout>
    );
}

export default MarkList;

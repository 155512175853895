import Icon from "@mui/material/Icon";
import AnAuthenticatedDashboard from "layouts/anauthenticatedDashboard";
import Applications from "layouts/applications/page1";
import ApplicationsPage2 from "layouts/applications/page2";
import ApplicationsPage3 from "layouts/applications/page3";
import ApplicationsPage4 from "layouts/applications/page4";
import SignIn from "layouts/authentication/sign-in";
import Dashboard from "layouts/dashboard";
import HrDashboard from "layouts/hrDashboard";
import Employees from "layouts/hrDashboard/employees";
import AddChildren from "layouts/hrDashboard/employees/employeeCreate/children";
import AddEducationalBackground from "layouts/hrDashboard/employees/employeeCreate/educationalBackground";
import EmployeeCreatePage1 from "layouts/hrDashboard/employees/employeeCreate/page1";
import EmployeementDetail from "layouts/hrDashboard/employees/employeeDetail";
import Position from "layouts/hrDashboard/positions";
import Staff from "layouts/hrDashboard/staff";
import AvailablePrograms from "layouts/programs";
import RegistrarDashboard from "layouts/registrarDashboard";
import ApplicantManagement from "layouts/registrarDashboard/applicantManagement";
import ViewRegistrarDetail from "layouts/registrarDashboard/applicantManagement/viewDetail";
import OthersDetail from "layouts/registrarDashboard/applicantManagement/viewDetail/others";
import CourseAcademics from "layouts/registrarDashboard/courseAcademics";
import StudentDashboard from "layouts/studentDashboard";
import Announcement from "layouts/studentDashboard/announcement";
import Assessment from "layouts/studentDashboard/assessment";
import Attendance from "layouts/studentDashboard/attendance";
import Calendar from "layouts/studentDashboard/calander";
import Clearance from "layouts/studentDashboard/clearance";
import Elibrary from "layouts/Elibrary";
import EnrolledCourses from "layouts/studentDashboard/EnrolledCourses";
import Exam from "layouts/studentDashboard/exam";
import GradeReport from "layouts/studentDashboard/GradeReport";
import MyAccount from "layouts/studentDashboard/myaccount";
import OnlineLearning from "layouts/studentDashboard/onlineLearning";
import StudentMaterial from "layouts/studentDashboard/studentMaterial";
import TakeExam from "layouts/studentDashboard/takeExam";

import { Equalizer, Folder, Person, SwapHoriz } from "@mui/icons-material";
import Materials from "layouts/academicDean/materials";
import Asset from "layouts/adminDashboard/assets";
import Bincard from "layouts/adminDashboard/bincard";
import DetailBincardTransaction from "layouts/adminDashboard/bincard/detialTransaction";
import Rooms from "layouts/adminDashboard/dormitory";
import FeeManagement from "layouts/adminDashboard/Finance/feeMangement";
import FinancePayroll from "layouts/adminDashboard/Finance/payroll";
import PurchaseList from "layouts/adminDashboard/Finance/purchase";
import RegisteredStudents from "layouts/adminDashboard/Finance/registeredStudents";
import InstructorAssessment from "layouts/adminDashboard/instructor/assessment";
import InstructorAttendance from "layouts/adminDashboard/instructor/attendance";
import InstructorDashboard from "layouts/adminDashboard/instructor/dashboard";
import Exams from "layouts/adminDashboard/instructor/exam";
import AddExam from "layouts/adminDashboard/instructor/exam/addExam";
import ViewExam from "layouts/adminDashboard/instructor/exam/viewExam";
import MarkList from "layouts/adminDashboard/instructor/markList";
import Material from "layouts/adminDashboard/instructor/material";
import ViewCourse from "layouts/adminDashboard/instructor/viewCourses";
import ViewStudent from "layouts/adminDashboard/instructor/viewStudents";
import Category from "layouts/adminDashboard/inventory/categories";
import GoodIssuingVoucher from "layouts/adminDashboard/inventory/goodIssuingVoucher";
import GoodReceiveVoucher from "layouts/adminDashboard/inventory/goodRecieveVoucher";
import Location from "layouts/adminDashboard/inventory/location";
import StudentPayment from "layouts/adminDashboard/inventory/payments";
import PurchaseRequest from "layouts/adminDashboard/inventory/purchaseRequest";
import Store from "layouts/adminDashboard/inventory/stores";
import Permission from "layouts/adminDashboard/security/permissions";
import Role from "layouts/adminDashboard/security/roles";
import Users from "layouts/adminDashboard/security/user";
import StoreRequest from "layouts/adminDashboard/storeRequest";
import IntroPage from "layouts/applications/IntroPage";
import ClearanceApproval from "layouts/clearanceApproval";
import EmployeeLeaveBalances from "layouts/employeeDashboard/leaveBalances";
import LeaveRequest from "layouts/employeeDashboard/leaveRequest";
import LoanRequest from "layouts/employeeDashboard/loanRequest";
import LeaveBalances from "layouts/hrDashboard/leaveBalances";
import LeaveRequests from "layouts/hrDashboard/leaveRequests";
import LeaveType from "layouts/hrDashboard/leaveType";
import Loans from "layouts/hrDashboard/loans";
import Payroll from "layouts/hrDashboard/payroll";
import Remunerations from "layouts/hrDashboard/remuneration";
import PrincipalDashboard from "layouts/principalDashboard";
import EmployeesList from "layouts/principalDashboard/employees";
import EmployeementListDetail from "layouts/principalDashboard/employees/employeeDetail";
import ViewInventory from "layouts/principalDashboard/inventory";
import StudentList from "layouts/principalDashboard/students";
import ViewStudentDetail from "layouts/principalDashboard/students/viewDetail";
import CourseHistory from "layouts/registrarDashboard/courseHistory";
import GradeReportForNonStudent from "layouts/registrarDashboard/gradeReport";
import RegisteredStudentList from "layouts/registrarDashboard/registeredStudentLIst";
import WithdrawRequest from "layouts/studentDashboard/withdrawl";
import TeacherEvaluation from "layouts/studentDashboard/teacherEvaluation";
import EmployeeDashboard from "layouts/employeeDashboard";
import FinanceDashboard from "layouts/adminDashboard/Finance/dashboard";
import ResetPassword from "utils/resetPassword";
import Zoom from "layouts/adminDashboard/instructor/zoom";
import Evaluation from "layouts/registrarDashboard/evaluation";

var roles = localStorage.getItem("role");

const HRRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">school</Icon>,
    route: "/dashboard",
    component: <HrDashboard />,
  },
  {
    type: "collapse",
    name: "Employees",
    key: "employees",
    icon: <Icon fontSize="small">account_circle</Icon>,
    route: "/employees",
    component: <Employees />,
  },
  {
    type: "collapse",
    name: "Staff",
    key: "staff",
    icon: <Icon fontSize="small">people</Icon>, // People icon for staff
    route: "/staff",
    component: <Staff />,
  },
  {
    type: "collapse",
    name: "Position",
    key: "position",
    icon: <Icon fontSize="small">work_outline</Icon>, // Work outline icon for position
    route: "/position",
    component: <Position />,
  },
  {
    type: "collapse",
    name: "Leave Type",
    key: "leave-type",
    icon: <Icon fontSize="small">event_note</Icon>, // Event note icon for leave type
    route: "/leave-type",
    component: <LeaveType />,
  },
  {
    type: "collapse",
    name: "Leave Request",
    key: "leave-request",
    icon: <Icon fontSize="small">request_quote</Icon>, // Request quote icon for leave request
    route: "/leave-request",
    component: <LeaveRequests />,
  },
  {
    type: "collapse",
    name: "Leave Balance",
    key: "leave-balance",
    icon: <Icon fontSize="small">account_balance</Icon>, // Account balance icon for leave balance
    route: "/leave-balance",
    component: <LeaveBalances />,
  },
  {
    type: "collapse",
    name: "Payroll",
    key: "payroll",
    route: "/payroll",
    icon: <Icon fontSize="small">attach_money</Icon>, // Attach money icon for payroll
    component: <Payroll />,
  },
  {
    type: "collapse",
    name: "Loans",
    key: "loan",
    route: "/loan",
    icon: <Icon fontSize="small">account_balance_wallet</Icon>, // Wallet icon for loans
    component: <Loans />,
  },
  {
    type: "collapse",
    name: "Remuneration",
    key: "remuneration",
    route: "/remuneration",
    icon: <Icon fontSize="small">payments</Icon>, // Payments icon for remuneration
    component: <Remunerations />,
  },

  {
    key: "employees_detail",
    route: "/employees/:id",
    component: <EmployeementDetail />,
  },
  {
    key: "employee_create_1",
    route: "/employees/create/1",
    component: <EmployeeCreatePage1 />,
  },
  {
    key: "add_children",
    route: "/addChildren",
    component: <AddChildren />,
  },
  {
    key: "add_education",
    route: "/addEducationBackground",
    component: <AddEducationalBackground />,
  },
];
const studentRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">school</Icon>,
    route: "/dashboard",
    component: <StudentDashboard />,
  },

  {
    type: "collapse",
    name: "Enrolled Courses",
    key: "enrolledCourses",
    icon: <Icon fontSize="small">menu_book</Icon>,
    route: "/enrolledCourses",
    component: <EnrolledCourses />,
  },
  {
    type: "collapse",
    name: "Grade Report",
    key: "gradeReport",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/gradeReport",
    component: <GradeReport />,
  },
  {
    type: "collapse",
    name: "Assessment",
    key: "student-assessment",
    icon: <Icon fontSize="small">assessment</Icon>,
    route: "/student-assessment",
    component: <Assessment />,
  },

  {
    type: "collapse",
    name: "Online Learning",
    key: "online_learning",
    icon: <Icon fontSize="small">computer</Icon>,
    route: "/onlineLearning",
    component: <OnlineLearning />,
  },
  {
    type: "collapse",
    name: "My Account",
    key: "myAccount",
    icon: <Icon fontSize="small">account_circle</Icon>,
    route: "/myAccount",
    component: <MyAccount />,
  },
  {
    type: "collapse",
    name: "Exam",
    key: "exam",
    icon: <Icon fontSize="small">book</Icon>,
    route: "/exam",
    component: <Exam />,
  },
  {
    name: "Take Exam",
    key: "takeExam",
    icon: <Icon fontSize="small">book</Icon>,
    route: "/exam/:id",
    component: <TakeExam />,
  },
  {
    type: "collapse",
    name: "Student Material",
    key: "studentMaterial",
    icon: <Icon fontSize="small">folder</Icon>,
    route: "/studentMaterial",
    component: <StudentMaterial />,
  },
  {
    type: "collapse",
    name: "Attendance",
    key: "attendance",
    icon: <Icon fontSize="small">fact_check</Icon>,
    route: "/attendance",
    component: <Attendance />,
  },

  {
    type: "collapse",
    name: "Clearance",
    key: "clearance",
    icon: <Icon fontSize="small">assignment_turned_in</Icon>, // Icon for clearance or approval
    route: "/clearance",
    component: <Clearance />,
  },
  {
    type: "collapse",
    name: "Withdraw Request",
    key: "withdraw-request",
    icon: <Icon fontSize="small">request_quote</Icon>, // Icon for withdraw-request
    route: "/withdraw-request",
    component: <WithdrawRequest />,
  },
  {
    type: "collapse",
    name: "Instructor Evaluation",
    key: "teacher-evaluation",
    icon: <Icon fontSize="small">assessment</Icon>, // Icon for instructor evaluation
    route: "/teacher-evaluation",
    component: <TeacherEvaluation />,
  },
  
];

const instructorRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <InstructorDashboard />,
  },
  {
    type: "collapse",
    name: "Course",
    key: "assigned-courses",
    icon: <Icon fontSize="small">book</Icon>, // Updated to book icon for courses
    route: "/assigned-courses",
    component: <ViewCourse />,
  },
  {
    type: "collapse",
    name: "Mark List",
    key: "mark-list",
    icon: <Icon fontSize="small">assignment</Icon>, // Updated to assignment icon
    route: "/mark-list",
    component: <MarkList />,
  },
  {
    type: "collapse",
    name: "Assessment",
    key: "instructor-assessment",
    icon: <Icon fontSize="small">assignment_turned_in</Icon>, // Updated to assignment turned in icon
    route: "/instructor-assessment",
    component: <InstructorAssessment />,
  },
  {
    type: "collapse",
    name: "Online Teaching",
    key: "zoom",
    icon: <Icon fontSize="small">video_call</Icon>, // Updated to video call icon
    route: "/zoom",
    component: <Zoom />,
  },
  {
    type: "collapse",
    name: "Student",
    key: "assigned-students",
    icon: <Icon fontSize="small">group</Icon>, // Updated to group icon for students
    route: "/assigned-students",
    component: <ViewStudent />,
  },
  {
    type: "collapse",
    name: "Material",
    key: "material",
    icon: <Icon fontSize="small">description</Icon>, // Updated to description icon for materials
    route: "/material",
    component: <Material />,
  },
  {
    type: "collapse",
    name: "Attendance",
    key: "attendance",
    icon: <Icon fontSize="small">check_circle</Icon>, // Updated to check circle icon for attendance
    route: "/attendance",
    component: <InstructorAttendance />,
  },
  {
    type: "collapse",
    name: "Exam",
    key: "exam",
    icon: <Icon fontSize="small">assessment</Icon>, // Updated to assessment icon for exams
    route: "/exam",
    component: <Exams />,
  },
  {
    name: "View Exam",
    key: "view-exam",
    icon: <Icon fontSize="small">visibility</Icon>, // Updated to visibility icon for viewing exam
    route: "/view-exam/:id",
    component: <ViewExam />,
  },
  {
    name: "Add Exam",
    key: "add-exam",
    icon: <Icon fontSize="small">add_circle</Icon>, // Updated to add circle icon for adding exams
    route: "/add-exam",
    component: <AddExam />,
  },
];


const registrarRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <RegistrarDashboard />,
  },

  {
    type: "collapse",
    name: "Applicant Students",
    key: "applicantMangement",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/applicantMangement",
    component: <ApplicantManagement />,
  },

  {
    type: "collapse",
    name: "Course Academics",
    key: "coursesAcademics",
    icon: <Icon fontSize="small">books</Icon>,
    route: "/coursesAcademics",
    component: <CourseAcademics />,
  },
  {
    type: "collapse",
    name: "Course History",
    key: "course-history",
    icon: <Icon fontSize="small">menu_book</Icon>,
    route: "/course-history",
    component: <CourseHistory />,
  },
  {
    type: "collapse",
    name: "Students",
    key: "students",
    icon: <Icon fontSize="small">school</Icon>,
    route: "/students",
    component: <RegisteredStudentList />,
  },
  {
    type: "collapse",
    name: "Evaluation",
    key: "evaluation",
    icon: <Icon fontSize="small">school</Icon>,
    route: "/evaluation",
    component: <Evaluation />,
  },
    {
    type: "collapse",
    name: "Grade Reports",
    key: "gradeReports",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/gradeReports",
    component: <GradeReportForNonStudent />,
  },
  {
    name: "ViewRegistrarDetail",
    key: "ViewRegistrarDetail",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/viewApplicantDetail",
    component: <ViewRegistrarDetail />,
  },

  {
    name: "OthersApplicantDetail",
    key: "OthersApplicantDetail",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/othersApplicantDetail",
    component: <OthersDetail />,
  },
  {
    name: "Courses",
    key: "Courses",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/courses",
    component: <OthersDetail />,
  },
];

const adminRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },

  // registrar
  {
  type: "collapse",
  name: "Registrar",
  key: "registrar",
  icon: <Icon fontSize="small">school</Icon>, 
  route: "/registrar",
  subroutes: [
  
    {
      type: "collapse",
      name: "Applicant Students",
      key: "applicantMangement",
      icon: <Icon fontSize="small">group</Icon>,
      route: "/applicantMangement",
      component: <ApplicantManagement />,
    },
  
    {
      type: "collapse",
      name: "Course Academics",
      key: "coursesAcademics",
      icon: <Icon fontSize="small">books</Icon>,
      route: "/coursesAcademics",
      component: <CourseAcademics />,
    },
    {
      type: "collapse",
      name: "Course History",
      key: "course-history",
      icon: <Icon fontSize="small">menu_book</Icon>,
      route: "/course-history",
      component: <CourseHistory />,
    },
    {
      type: "collapse",
      name: "Students",
      key: "students",
      icon: <Icon fontSize="small">school</Icon>,
      route: "/students",
      component: <RegisteredStudentList />,
    },
      {
      type: "collapse",
      name: "Grade Reports",
      key: "gradeReports",
      icon: <Icon fontSize="small">assignment</Icon>,
      route: "/gradeReports",
      component: <GradeReportForNonStudent />,
    },

  ]},
  {
    name: "ViewRegistrarDetail",
    key: "ViewRegistrarDetail",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/viewApplicantDetail",
    component: <ViewRegistrarDetail />,
  },

  {
    name: "OthersApplicantDetail",
    key: "OthersApplicantDetail",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/othersApplicantDetail",
    component: <OthersDetail />,
  },
  {
    name: "Courses",
    key: "Courses",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/courses",
    component: <OthersDetail />,
  },
// human resource
    {
      type: "collapse",
      name: "Human Resource",
      key: "human-resource",
      icon: <Icon fontSize="small">work</Icon>,
      route: "/human-resource",
      subroutes: [
 
    {
      type: "collapse",
      name: "Employees",
      key: "employees",
      icon: <Icon fontSize="small">account_circle</Icon>,
      route: "/employees",
      component: <Employees />,
    },
    {
      type: "collapse",
      name: "Staff",
      key: "staff",
      icon: <Icon fontSize="small">people</Icon>, // People icon for staff
      route: "/staff",
      component: <Staff />,
    },
    {
      type: "collapse",
      name: "Position",
      key: "position",
      icon: <Icon fontSize="small">work_outline</Icon>, // Work outline icon for position
      route: "/position",
      component: <Position />,
    },
    {
      type: "collapse",
      name: "Leave Type",
      key: "leave-type",
      icon: <Icon fontSize="small">event_note</Icon>, // Event note icon for leave type
      route: "/leave-type",
      component: <LeaveType />,
    },
    {
      type: "collapse",
      name: "Leave Request",
      key: "leave-request",
      icon: <Icon fontSize="small">request_quote</Icon>, // Request quote icon for leave request
      route: "/leave-request",
      component: <LeaveRequests />,
    },
    {
      type: "collapse",
      name: "Leave Balance",
      key: "leave-balance",
      icon: <Icon fontSize="small">account_balance</Icon>, // Account balance icon for leave balance
      route: "/leave-balance",
      component: <LeaveBalances />,
    },
    {
      type: "collapse",
      name: "Payroll",
      key: "payroll",
      route: "/payroll",
      icon: <Icon fontSize="small">attach_money</Icon>, // Attach money icon for payroll
      component: <Payroll />,
    },
    {
      type: "collapse",
      name: "Loans",
      key: "loan",
      route: "/loan",
      icon: <Icon fontSize="small">account_balance_wallet</Icon>, // Wallet icon for loans
      component: <Loans />,
    },
    {
      type: "collapse",
      name: "Remuneration",
      key: "remuneration",
      route: "/remuneration",
      icon: <Icon fontSize="small">payments</Icon>, // Payments icon for remuneration
      component: <Remunerations />,
    },
  ]
},
{
  key: "employees_detail",
  route: "/employees/:id",
  component: <EmployeementDetail />,
},
{
  key: "employee_create_1",
  route: "/employees/create/1",
  component: <EmployeeCreatePage1 />,
},
{
  key: "add_children",
  route: "/addChildren",
  component: <AddChildren />,
},
{
  key: "add_education",
  route: "/addEducationBackground",
  component: <AddEducationalBackground />,
},
// finance
  {
    type: "collapse",
    name: "Finance",
    key: "finance",
    icon: <Icon fontSize="small">storage</Icon>,
    route: "/finance",
    // component: <Inventory />,
    subroutes: [
      {
        type: "collapse",
        name: "Fees",
        key: "fees",
        icon: <Icon fontSize="small">category</Icon>,
        route: "/fees",
        component: <FeeManagement />,
      },
      {
        type: "collapse",
        name: "Purchase Request",
        key: "purchase-request",
        icon: <Icon fontSize="small">assignment</Icon>, // Icon for requests or tasks
        route: "/purchase-request",
        component: <PurchaseRequest />,
      },
      {
        type: "collapse",
        name: "Purchase List",
        key: "purchase-list",
        icon: <Icon fontSize="small">assignment</Icon>, // Icon for requests or tasks
        route: "/purchase-list",
        component: <PurchaseList />,
      },
      {
        // type: "collapse",
        name: "Student Payment",
        key: "payments",
        icon: <Icon fontSize="small">money</Icon>,
        route: "/payments",
        component: <StudentPayment />,
      },
      {
        // type: "collapse",
        name: "Payroll",
        key: "finance-payroll",
        icon: <Icon fontSize="small">money</Icon>,
        route: "/finance-payroll",
        component: <FinancePayroll />,
      },
      {
        // type: "collapse",
        name: "Students",
        key: "registered-students",
        icon: <Icon fontSize="small">money</Icon>,
        route: "/registered-students",
        component: <RegisteredStudents />,
      },
    ],
  },
// inventory
  {
    type: "collapse",
    name: "Inventory",
    key: "inventory",
    icon: <Icon fontSize="small">storage</Icon>,
    route: "/inventory",
    // component: <Inventory />,
    subroutes: [
      {
        // type: "collapse",
        name: "Category",
        key: "category",
        icon: <Icon fontSize="small">category</Icon>,
        route: "/category",
        component: <Category />,
      },
      {
        // type: "collapse",
        name: "Location",
        key: "location",
        icon: <Icon fontSize="small">location_on</Icon>,
        route: "/location",
        component: <Location />,
      },
      {
        // type: "collapse",
        name: "Store",
        key: "store",
        icon: <Icon fontSize="small">store</Icon>,
        route: "/store",
        component: <Store />,
      },
      {
        type: "collapse",
        name: "Asset",
        key: "assets",
        icon: <Folder fontSize="small" />,
        route: "/assets",
        component: <Asset />,
      },
      {
        type: "collapse",
        name: "Bincard",
        key: "asset-bincard",
        icon: <Equalizer fontSize="small" />,
        route: "/asset-bincard",
        component: <Bincard />,
      },
      {
        type: "collapse",
        name: "Asset Transaction",
        key: "detail-bincard",
        icon: <SwapHoriz fontSize="small" />,
        route: "/detail-bincard",
        component: <DetailBincardTransaction />,
      },

      {
        type: "collapse",
        name: "Store Request",
        key: "store-request",
        icon: <Icon fontSize="small">assignment</Icon>, // Icon for requests or tasks
        route: "/store-request",
        component: <StoreRequest />,
      },
      {
        type: "collapse",
        name: "Good Receiving",
        key: "good-receive",
        icon: <Icon fontSize="small">inventory_2</Icon>, // Icon representing receiving inventory
        route: "/good-receive",
        component: <GoodReceiveVoucher />,
      },
      {
        type: "collapse",
        name: "Good Issuing",
        key: "good-issuing",
        icon: <Icon fontSize="small">local_shipping</Icon>, // Icon for issuing goods or shipping
        route: "/good-issuing",
        component: <GoodIssuingVoucher />,
      },
    ],
  },
  {
    name: "ViewRegistrarDetail",
    key: "ViewRegistrarDetail",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/viewApplicantDetail/:id",
    component: <ViewRegistrarDetail />,
  },
  {
    type: "collapse",
    name: "Dormitory",
    key: "dormitory",
    icon: <Icon fontSize="small">home</Icon>, 
    route: "/dormitory",
    subroutes: [
      {
        name: "Rooms",
        key: "rooms",
        icon: <Icon fontSize="small">room</Icon>,
        route: "/rooms",
        component: <Rooms />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Security",
    key: "security",
    icon: <Icon fontSize="small">lock</Icon>,
    route: "/security",
    subroutes: [
      {
        // type: "collapse",
        name: "Permission",
        key: "permission",
        icon: <Icon fontSize="small">lock</Icon>,
        route: "/permission",
        component: <Permission />,
      },
      {
        // type: "collapse",
        name: "Role",
        key: "role",
        icon: <Person fontSize="small" />,
        route: "/role",
        component: <Role />,
      },
      {
        // type: "collapse",
        name: "Users",
        key: "user-create",
        icon: <Person fontSize="small" />,
        route: "/user-create",
        component: <Users />,
      },
    ],
  },
];
const unauthenticatedRoute = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <AnAuthenticatedDashboard />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
     {
      type: "collapse",
      name: "Available Programs",
      key: "program",
      icon: <Icon fontSize="small">library_books</Icon>,
      route: "/program",
      component: <AvailablePrograms />,
    },
    {
      type: "collapse",
      name: "Online Application",
      key: "application-intro",
      icon: <Icon fontSize="small">assignment</Icon>,
      route: "/application-intro",
      component: <IntroPage />,
    },
  {
    // type: "collapse",
    name: "Online Application",
    key: "application",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/application",
    component: <Applications />,
  },
  {
    // type: "collapse",
    name: "ApplicationsPage2",
    key: "application",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/applicationPage2",
    component: <ApplicationsPage2 />,
  },
  {
    // type: "collapse",
    name: "ApplicationsPage3",
    key: "application3",
    icon: <Icon fontSize="small">assignment_ind</Icon>,
    route: "/applicationPage3",
    component: <ApplicationsPage3 />,
  },
  {
    // type: "collapse",
    name: "ApplicationsPage4",
    key: "application4",
    icon: <Icon fontSize="small">article</Icon>,
    route: "/applicationPage4",
    component: <ApplicationsPage4 />,
  },
  {
    name: "Reset",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/password-reset/:token",
    component: <ResetPassword />,
  },
];

const commonRoutes = [
  {
    name: "Reset",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/password-reset/:token",
    component: <ResetPassword />,
  },
  {
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Announcement",
    key: "announcement",
    icon: <Icon fontSize="small">campaign</Icon>, // Icon for announcements or notifications
    route: "/announcement",
    component: <Announcement />,
  },
  {
    type: "collapse",
    name: "Calander",
    key: "calander",
    icon: <Icon fontSize="small">assessment</Icon>, // Icon for assessments or evaluations
    route: "/calander",
    component: <Calendar />,
  },

  {
    type: "collapse",
    name: "Elibrary",
    key: "elibrary",
    icon: <Icon fontSize="small">book</Icon>,
    route: "/elibrary",
    component: <Elibrary />,
  },
];

const employeeRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <EmployeeDashboard />,
  },
  {
    type: "collapse",
    name: "Student Clearances",
    key: "studentClearances",
    icon: <Icon fontSize="small">check_circle</Icon>,
    route: "/studentClearances",
    component: <ClearanceApproval />,
  },
  {
    type: "collapse",
    name: "Leave Request",
    key: "employee-leave-request",
    icon: <Icon fontSize="small">request_quote</Icon>, 
    route: "/employee-leave-request",
    component: <LeaveRequest />,
  },
  {
    type: "collapse",
    name: "Leave Balance",
    key: "employee-leave-balance",
    icon: <Icon fontSize="small">monetization_on</Icon>, 
    route: "/employee-leave-balance",
    component: <EmployeeLeaveBalances />,
  },
  {
    type: "collapse",
    name: "Loan Request",
    key: "loan-request",
    icon: <Icon fontSize="small">attach_money</Icon>, 
    route: "/loan-request",
    component: <LoanRequest />,
  },
];

const academicDeanRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <RegistrarDashboard />,
  },

  {
    type: "collapse",
    name: "Applicant Students",
    key: "applicantMangement",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/applicantMangement",
    component: <ApplicantManagement />,
  },

  {
    type: "collapse",
    name: "Course Academics",
    key: "coursesAcademics",
    icon: <Icon fontSize="small">books</Icon>,
    route: "/coursesAcademics",
    component: <CourseAcademics />,
  },
  {
    type: "collapse",
    name: "Course History",
    key: "course-history",
    icon: <Icon fontSize="small">menu_book</Icon>,
    route: "/course-history",
    component: <CourseHistory />,
  },
  {
    type: "collapse",
    name: "Students",
    key: "students",
    icon: <Icon fontSize="small">school</Icon>,
    route: "/students",
    component: <RegisteredStudentList />,
  },
  {
    name: "ViewRegistrarDetail",
    key: "ViewRegistrarDetail",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/viewApplicantDetail",
    component: <ViewRegistrarDetail />,
  },

  {
    name: "OthersApplicantDetail",
    key: "OthersApplicantDetail",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/othersApplicantDetail",
    component: <OthersDetail />,
  },
  {
    name: "Courses",
    key: "Courses",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/courses",
    component: <OthersDetail />,
  },
  {
    type: "collapse",
    name: "materials",
    key: "materials",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/materials",
    component: <Materials />,

  },
];

const principalRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <PrincipalDashboard />,
  },
  {
    type: "collapse",
    name: "Employees",
    key: "employees-list",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/employees-list",
    component: <EmployeesList />,
  },
  {
    type: "collapse",
    name: "Students",
    key: "student-list",
    icon: <Icon fontSize="small">school</Icon>,
    route: "/student-list",
    component: <StudentList />,
  },
  {
    name: "ViewStudentDetail",
    key: "ViewStudentDetail",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/viewStudentDetail",
    component: <ViewStudentDetail />,
  },
  {
    key: "employees_list",
    route: "/employees-list/:id",
    component: <EmployeementListDetail />,
  },
  {
    type: "collapse",
    name: "Inventory",
    key: "View-inventory",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/view-inventory",
    component: <ViewInventory />,
  },
];

const financeRoutes = [
 
      {
        type: "collapse",
        name: "Dashboard",
        key: "dashboard",
        icon: <Icon fontSize="small">dashboard</Icon>,
        route: "/dashboard",
        component: <FinanceDashboard />,
      },
      {
        type: "collapse",
        name: "Fees",
        key: "fees",
        icon: <Icon fontSize="small">category</Icon>,
        route: "/fees",
        component: <FeeManagement />,
      },
      {
        type: "collapse",
        name: "Purchase Request",
        key: "purchase-request",
        icon: <Icon fontSize="small">assignment</Icon>, 
        route: "/purchase-request",
        component: <PurchaseRequest />,
      },
      {
        type: "collapse",
        name: "Purchase List",
        key: "purchase-list",
        icon: <Icon fontSize="small">assignment</Icon>, 
        route: "/purchase-list",
        component: <PurchaseList />,
      },
      {
        type: "collapse",
        name: "Student Payment",
        key: "payments",
        icon: <Icon fontSize="small">money</Icon>,
        route: "/payments",
        component: <StudentPayment />,
      },
      {
        type: "collapse",
        name: "Payroll",
        key: "finance-payroll",
        icon: <Icon fontSize="small">money</Icon>,
        route: "/finance-payroll",
        component: <FinancePayroll />,
      },
      {
        type: "collapse",
        name: "Students",
        key: "registered-students",
        icon: <Icon fontSize="small">money</Icon>,
        route: "/registered-students",
        component: <RegisteredStudents />,
      },
    

  {
    type: "collapse",
    name: "Inventory",
    key: "inventory",
    icon: <Icon fontSize="small">storage</Icon>,
    route: "/inventory",
    // component: <Inventory />,
    subroutes: [
      {
        // type: "collapse",
        name: "Category",
        key: "category",
        icon: <Icon fontSize="small">category</Icon>,
        route: "/category",
        component: <Category />,
      },
      {
        // type: "collapse",
        name: "Location",
        key: "location",
        icon: <Icon fontSize="small">location_on</Icon>,
        route: "/location",
        component: <Location />,
      },
      {
        // type: "collapse",
        name: "Store",
        key: "store",
        icon: <Icon fontSize="small">store</Icon>,
        route: "/store",
        component: <Store />,
      },
      {
        type: "collapse",
        name: "Asset",
        key: "assets",
        icon: <Folder fontSize="small" />,
        route: "/assets",
        component: <Asset />,
      },
      {
        type: "collapse",
        name: "Bincard",
        key: "asset-bincard",
        icon: <Equalizer fontSize="small" />,
        route: "/asset-bincard",
        component: <Bincard />,
      },
      {
        type: "collapse",
        name: "Asset Transaction",
        key: "detail-bincard",
        icon: <SwapHoriz fontSize="small" />,
        route: "/detail-bincard",
        component: <DetailBincardTransaction />,
      },

      {
        type: "collapse",
        name: "Store Request",
        key: "store-request",
        icon: <Icon fontSize="small">assignment</Icon>, 
        route: "/store-request",
        component: <StoreRequest />,
      },
      {
        type: "collapse",
        name: "Good Receiving",
        key: "good-receive",
        icon: <Icon fontSize="small">inventory_2</Icon>, 
        route: "/good-receive",
        component: <GoodReceiveVoucher />,
      },
      {
        type: "collapse",
        name: "Good Issuing",
        key: "good-issuing",
        icon: <Icon fontSize="small">local_shipping</Icon>,
        route: "/good-issuing",
        component: <GoodIssuingVoucher />,
      },
    ],
  },
];

const inventoryRoutes = [
 
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <FinanceDashboard />,
  },

   {
    type: "collapse",
    name: "Category",
    key: "category",
    icon: <Icon fontSize="small">category</Icon>,
    route: "/category",
    component: <Category />,
  },
  {
    type: "collapse",
    name: "Location",
    key: "location",
    icon: <Icon fontSize="small">location_on</Icon>,
    route: "/location",
    component: <Location />,
  },
  {
    type: "collapse",
    name: "Store",
    key: "store",
    icon: <Icon fontSize="small">store</Icon>,
    route: "/store",
    component: <Store />,
  },
  {
    type: "collapse",
    name: "Asset",
    key: "assets",
    icon: <Folder fontSize="small" />,
    route: "/assets",
    component: <Asset />,
  },
  {
    type: "collapse",
    name: "Bincard",
    key: "asset-bincard",
    icon: <Equalizer fontSize="small" />,
    route: "/asset-bincard",
    component: <Bincard />,
  },
  {
    type: "collapse",
    name: "Asset Transaction",
    key: "detail-bincard",
    icon: <SwapHoriz fontSize="small" />,
    route: "/detail-bincard",
    component: <DetailBincardTransaction />,
  },

  {
    type: "collapse",
    name: "Store Request",
    key: "store-request",
    icon: <Icon fontSize="small">assignment</Icon>, 
    route: "/store-request",
    component: <StoreRequest />,
  },
  {
    type: "collapse",
    name: "Good Receiving",
    key: "good-receive",
    icon: <Icon fontSize="small">inventory_2</Icon>, 
    route: "/good-receive",
    component: <GoodReceiveVoucher />,
  },
  {
    type: "collapse",
    name: "Good Issuing",
    key: "good-issuing",
    icon: <Icon fontSize="small">local_shipping</Icon>,
    route: "/good-issuing",
    component: <GoodIssuingVoucher />,
  },
];


let routes = [];

if (roles === "student") {
  routes = studentRoutes;
} else if (roles === "admin") {
  routes = adminRoutes;
} else if (roles === "registrar") {
  routes = registrarRoutes;
} else if (roles === "human resource") {
  routes = HRRoutes;
} else if (roles === "employee") {
  routes = employeeRoutes;
} else if (roles === "instructor") {
  routes = instructorRoutes;
} else if (roles === "academic dean") {
  routes = academicDeanRoutes;
} else if (roles === "principal") {
  routes = principalRoutes;
} else if (roles === "finance") {
  routes = financeRoutes;
}
else if (roles === "inventory") {
  routes = inventoryRoutes;
}
else {
  routes = unauthenticatedRoute;
}

if (roles != null) {
  commonRoutes.forEach((route) => {
    routes.push(route);
  });

  if (roles !== "student" && roles !== "human resource" ) {
    employeeRoutes.forEach((route) => {
      if (route.key !== "dashboard") {

        routes.push(route);
      }
    });
  }
}

export default routes;

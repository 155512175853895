import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Avatar } from '@mui/material';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { STORAGE_BASE_URL } from 'api';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { useEffect, useState,useCallback } from 'react';
import { axiosInstance } from 'api2';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CourseTaken from './courseTaken';
import { IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import swal from 'sweetalert';
import {Box} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import MDButton from 'components/MDButton';
import FileViewer from "components/FileViewer";
import LoadingDialog from 'components/Loading/LoadingDialog';

const RegisteredStudents = () => {
  const[applicants,setApplicants] = useState([])
  const [transactionData, setTransactionData] = useState(null);
  const [openView, setOpenView] = useState(false);
  const [courseHistory,setCourseHistory] = useState([])
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const handleViewFile = (filePath) => {
    const extension = filePath.split('.').pop().toLowerCase();
    setSelectedFile(filePath);
    setFileType(extension === "pdf" ? "pdf" : ["jpg", "jpeg", "png", "gif"].includes(extension) ? "image" : "other");
    setOpenDialog(true);
  };



  const handleClose = () => {
    setOpen(false);
    setTransactionData(null); // Clear the data when closing
};


 
  const fetchRegisteredStudents = useCallback(async () => {
 setLoading(true);
    try {
      const response = await axiosInstance.get(`/unapproved-registrations`);
    
      
      setApplicants(response.data);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  },[]);
  useEffect(() => {
    fetchRegisteredStudents();
  }, [fetchRegisteredStudents]);

  const handleCloseDialog = () => {
    setOpenView(false);
    setOpenDialog(false);
    setSelectedFile(null);
    setFileType("");

  };
  
 

  const handleViewDetail = (data) => {
   
    setCourseHistory(data.course_history)
    setOpenView(true);

  }

  const handleTransaction = async (payment) => {    

    if (payment != null && payment.transaction_id !== null ){
      setLoading(true);
      try {
         if (payment.payment_method === "Chapa") {

        const response = await axiosInstance.get(`/callback/${payment.transaction_id}`);    

        setTransactionData(response.data.data); 
        setOpen(true);
        setLoading(false);
   
         }
         else{
          setTransactionData(payment); 
          setLoading(false);


         }

      } catch (err) {
        console.error(err);
      }
    }
  };





  // eslint-disable-next-line no-sparse-arrays
  const columns = [
    {
      accessor: 'action',
      Header: '', 
      width: 40,
      Cell: ({ row }) => (
        <PopupState variant="popover"   popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                <MoreVertIcon />
              </Button>
              <Menu {...bindMenu(popupState)}>
              <MenuItem onClick={() => {
              handleViewDetail(row.original);
              popupState.close();
            }} style={{ color: '#1976d2' }}>
              <VisibilityIcon style={{ marginRight: 8 }} /> View Details
            </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      ),
      showSortIcons: false,
    },
    {
      Header: "Profile",
      id: "profile",
      accessor: row  => (
        <Avatar src={`${STORAGE_BASE_URL}/${row.profile_picture}`} />
      ),
      sortable: false,
      showSortIcons: false
    },
    {
      Header: 'Name',
      accessor: 'payment.full_name', 
      id: 'full_name', 
      sorted: "none",
    },
    { Header: 'Year', accessor: 'payment.year',showSortIcons: false, },
    { Header: 'Semester', accessor: 'payment.semester',showSortIcons: false, },
   
    { Header: 'Payment Date', accessor: 'payment.payment_date',showSortIcons: false, },
    { Header: 'Payment Amount', accessor: 'payment.payment_amount',showSortIcons: false, },
    { Header: 'Fee Type', accessor: 'payment.fee_type',showSortIcons: false, },
    { Header: 'Payment Method', accessor: 'payment.payment_method',showSortIcons: false, },
    { Header: 'transaction id', 
      accessor: row => (
          <MDButton onClick={() => handleTransaction(row.payment.transaction_id)}>
            {row.payment.transaction_id ? row.payment.transaction_id : 'No Transaction'}
          </MDButton>
        )
      ,showSortIcons: false, },

  { Header: 'receipt_path', 
      accessor: row => (
          <MDTypography variant="caption">
              {row.payment.payment_method === 'Chapa' ? (
                  'No Receipt Available'
              ) : (
                <MDButton
            variant="body2"
            color="primary"
            component="a"
            onClick={() => handleViewFile(row.payment.receipt_path)}
            style={{ cursor: 'pointer' }}
            sx={{ 
              cursor: 'pointer',
              textDecoration: 'underline',
              boxShadow: 1, // Adds a default shadow
              transition: 'box-shadow 0.3s ease-in-out', // Smooth transition effect
              '&:hover': {
                color: 'secondary.main',
                boxShadow: 4, // Increases shadow intensity on hover
              }
            }}
          >
            View Receipt
          </MDButton>
                  
              )}
          </MDTypography>
      ),

      showSortIcons: false, },
   
    {
      Header: 'Actions',
      accessor: 'id',
      showSortIcons: false,
      Cell: ({ row }) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const [approvalStatus, setApprovalStatus] = useState(null);
    
        const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
        };
    
        const handleClose = () => {
          setAnchorEl(null);
        };
    
        const handleApproval = async (data,status) => {
          
          const confirm = await swal({
            title: `Are you sure you want to ${status === 'Approved' ? 'approve' : 'set to pending'} this payment?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          });
    
          if (confirm) {
            try {
              const response = await axiosInstance.post('/registration/finance-approve', {
                student_id: data.student_id,
                year: data.year,
                semester: data.semester,
                status: status
              });
    
              if (response.status === 200) {
                setApprovalStatus(status); 
                fetchRegisteredStudents()
                swal({
                  title: `Payment ${status} successfully!`,
                  icon: "success",
                });
              }
            } catch (error) {
              swal({
                title: `${error.response?.data?.message || 'An error occurred'}`,
                icon: "error",
              });
            }
          }
        };
        return (
          <Box display="flex" justifyContent="space-between" gap={2}>
            {approvalStatus === null ? (
              <>
                <IconButton onClick={handleClick}>
                  <MenuIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                >
                  <MenuItem
                    onClick={() => handleApproval(row.original.payment, 'Approved')}
                    sx={{ color: '#4caf50' }} // Green text for approval
                  >
                    <CheckIcon /> Approve
                  </MenuItem>
                 
                </Menu>
              </>
            ) : (
              <Box
                component="button"
                style={{
                  backgroundColor: approvalStatus === 'Approved' ? '#4caf50' : '#f44336',
                  color: '#fff',
                  border: 'none',
                  padding: '8px 16px',
                  cursor: 'pointer',
                }}
              >
                {approvalStatus}
              </Box>
            )}
          </Box>
        );
      },
    },
  ];
  
 
  return (
    <DashboardLayout>
       <>      <MDBox>
        <Card>
          <CardContent>
            <MDTypography variant="h5" fontWeight="medium">
              Students
            </MDTypography>
            <MDBox mt={1}>
              {loading ? (
                <MDBox display="flex" justifyContent="center" alignItems="center" height="100%">
                  <CircularProgress />
                </MDBox>
              ) : (
                <DataTable
                  table={{
                    columns,
                    rows: applicants,
                  }}
                  canSearch={true}
                  sortable={true}
                  rowsPerPage={5}
                  showPagination
                 
                />
              )}
            </MDBox>
            {
                courseHistory &&(
                  <CourseTaken coursesTaken={courseHistory} openView={openView} onClose={handleCloseDialog}/>
                )
              }
          </CardContent>
        </Card>
      </MDBox>
       <Dialog open={open} onClose={handleClose}>
       <DialogTitle>Application Fee Details</DialogTitle>
       {/* <Typography variant="body1"><strong> {transactionMessage} </strong></Typography> */}

       <DialogContent>
           {transactionData && (
               <>
                  <DialogContent>
{transactionData && (
<>
   <MDTypography variant="body1"><strong>Full Name:</strong> {transactionData.first_name + ' ' + transactionData.last_name}</MDTypography>
   <MDTypography variant="body1"><strong>Amount:</strong> {transactionData.amount}</MDTypography>
   <MDTypography variant="body1"><strong>Charge:</strong> {transactionData.charge}</MDTypography>
   <MDTypography variant="body1"><strong>Created At:</strong> {new Date(transactionData.created_at).toLocaleString()}</MDTypography>
   <MDTypography variant="body1"><strong>Currency:</strong> {transactionData.currency}</MDTypography>
   <MDTypography variant="body1"><strong>Customization Title:</strong> {transactionData.customization?.title}</MDTypography>
   <MDTypography variant="body1"><strong>Customization Description:</strong> {transactionData.customization?.description}</MDTypography>
   <MDTypography variant="body1"><strong>Email:</strong> {transactionData.email}</MDTypography>
   <MDTypography variant="body1"><strong>Method:</strong> {transactionData.method}</MDTypography>
   <MDTypography variant="body1"><strong>Mode:</strong> {transactionData.mode}</MDTypography>
   <MDTypography variant="body1"><strong>Reference:</strong> {transactionData.reference}</MDTypography>
   <MDTypography variant="body1"><strong>Status:</strong> {transactionData.status}</MDTypography>
   <MDTypography variant="body1"><strong>Transaction Reference:</strong> {transactionData.tx_ref}</MDTypography>
</>
)}
</DialogContent>
               </>
           )}
       </DialogContent>
       <DialogActions>
           <Button onClick={handleClose} color="primary">Close</Button>
       </DialogActions>
       <FileViewer
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        filePath={selectedFile}
        fileType={fileType}
      />
   </Dialog>
   </>

<LoadingDialog open={loading} />
    </DashboardLayout>
  )
}

export default RegisteredStudents
import { Add, Delete } from '@mui/icons-material';
import {
  Alert,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField
} from '@mui/material';
import API_BASE_URL from 'api';
import axios from 'axios';
import MDBox from "components/MDBox";
import MDButton from 'components/MDButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import { useEffect, useState ,useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { addCourseHistory, deleteCourseHistory } from '../../../redux/features/courseHistorySlice';
import LoadingDialog from 'components/Loading/LoadingDialog';


const CourseHistoryForm = ({ open, onClose, onAdd }) => {
  const dispatch = useDispatch();
  const coursehistory = useSelector((state) => state.course_history);
  const [loading2, setLoading2] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedInstructor, setSelectedInstructor] = useState(null);
  const [remark, setRemark] = useState('');
  const [semesters, setSemesters] = useState([]);
  const [years, setYears] = useState([]);
  const [courses, setCourses] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [semester, setSemester] = useState(null);
  const [year, setYear] = useState(null);
  const [displayCourseHistory, setDisplayCourseHistory,] = useState([]);
  const [error,setError]=useState(null)
  const token = localStorage.getItem("authToken");

  const axiosInstance = useMemo(() => {
    return axios.create({
      baseURL: API_BASE_URL,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`, // Fetch the token from localStorage
        'Content-Type': 'application/json',
      },
    });
  }, []); 
  
  
  useEffect(() => {
    try{
    const fetchData = async () => {
      const [semestersData, yearsData, coursesData, instructorsData] = await Promise.all([
        axiosInstance.get('/semesters'),
        axiosInstance.get('/years'),
        axiosInstance.get('/courses'),
        axiosInstance.get('/instructors'),
      ]);
      setSemesters(semestersData.data);
      setYears(yearsData.data);
      setCourses(coursesData.data);
      setInstructors(instructorsData.data);
    };

    fetchData();
  }catch(err){
    console.log("error:",err)

  }

  },[axiosInstance]);
  const handleAddCourse = () => {
    
    if (selectedCourse) {
      const newCourseHistory = {
        semester:semester.semester_code,
        year:year.year_name,
        course_id: selectedCourse.id, 
        instructor_id: selectedInstructor ? selectedInstructor.id : null, 
        remark,
      };
      dispatch(addCourseHistory(newCourseHistory));
      
      const displayEntry = {
        semester:semester.semester_code,
        year:year.year_name,
        course_id:selectedCourse.id,
        course_number: selectedCourse.course_number,
        course_name: selectedCourse.course_name, 
        credit_hour: selectedCourse.credit_hour, 
        instructor_name: selectedInstructor ? selectedInstructor.full_name : "TBA", 
        remark,
      };


      // Update display history
      setDisplayCourseHistory((prev) => [...prev, displayEntry]);

      setSelectedCourse(null);
      setSelectedInstructor(null);
    }
  };
  const handleDeleteCourse = (index) => {
    setError(null)
    const courseIdToDelete = displayCourseHistory[index].course_id; 
    
    setDisplayCourseHistory((prev) => prev.filter((_, i) => i !== index));

    dispatch(deleteCourseHistory(courseIdToDelete));
  };
  const columns = [
    { Header: 'Semester', accessor: 'semester', sortable: true,showSortIcons:false },
    { Header: 'Year', accessor: 'year', sortable: true,showSortIcons:false  },
    { Header: 'Course Number', accessor: 'course_number', sortable: true,showSortIcons:false  },
    { Header: 'Course Name', accessor: 'course_name', sortable: true },
    { Header: 'Credit Hour', accessor: 'credit_hour', sortable: true,showSortIcons:false  },
    { Header: 'Instructor', accessor: 'instructor_name', sortable: true,showSortIcons:false  },
    { Header: 'Remark', accessor: 'remark', sortable: true,showSortIcons:false  },
    {
      Header: 'Actions',
      showSortIcons:false ,
      accessor: (row, index) => (
        <IconButton onClick={() => handleDeleteCourse(index)}>
          <Delete />
        </IconButton>
      ),
    },
  ];



  const formatErrorMessages = (errors) => {
    if (!errors) return [];
    
    return Object.entries(errors).flatMap(([key, messages]) => {
      return messages.map(message => {
        return `${message}`;
      });
    });
  };

  

  const handleSubmit = async () => {
    setLoading2(true); 
    try {
      const response = await axios.post(`${API_BASE_URL}/course-history`, coursehistory, {
        headers: { Authorization: `Bearer ${token}` },
      });
    
      if (response.status === 200) {
        onAdd()
        onClose();
        swal({
          title: "course history Created Successfully!",
          icon: "success",
        });

        setSelectedCourse(null);
        setCourses([]);
        setInstructors([])
        setSelectedInstructor(null);
        setRemark('');
        setSemester(null);
        setYear(null);
        setDisplayCourseHistory([]);
        setError(null)
        
      }
    } catch (error) {
      
      if (error.response.status === 422) {
        const { errors } = error.response.data;
        const formattedErrors = formatErrorMessages(errors);
        console.log("error:",formattedErrors[0]);
        setError(formattedErrors[0])
      }
    }finally {
      setLoading2(false); // Stop loading
    }
  }

  return (
    <DashboardLayout>

      <Dialog open={open} onClose={onClose} maxWidth="lg">
              <DialogTitle textAlign={"center"}><Add /> Add Course History
      </DialogTitle>
      {error && (
        <MDBox mx={2} mt={2} mb={2}>
          <Alert severity="error" variant="filled">
            {error}
          </Alert>
        </MDBox>
      )}
      <DialogContent>
            <Grid container spacing={3} mt={1}>
              {/* First Row of Inputs */}
              <Grid item xs={12} md={6} lg={4}>            <Autocomplete
              options={semesters} // Example semesters
              getOptionLabel={(option) => option.semester_code}
              onChange={(event, newValue) => setSemester(newValue)}
              value={semester}
              renderInput={(params) => <TextField {...params} label="Semester" />}
            />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>  
            <Autocomplete
              options={years} // Example semesters
              getOptionLabel={(option) => option.year_name}
              onChange={(event, newValue) => setYear(newValue)}
              value={year}
              renderInput={(params) => <TextField {...params} label="Year" />}
            />
             </Grid>
             <Grid item xs={12} md={6} lg={4}>  
                      <TextField
            label="Remark"
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
            fullWidth
          />
          </Grid>
            
          </Grid>
          <Grid container spacing={3}  mt={1}>
              {/* First Row of Inputs */}
              <Grid item xs={12} md={6} lg={6}>             <Autocomplete
              options={courses}
              getOptionLabel={(option) => `${option.course_number} - ${option.course_name}`}
              onChange={(event, newValue) => {
                setSelectedCourse(newValue);
                setError(null); // Reset error state on change
              }}
              value={selectedCourse}
              renderInput={(params) => <TextField {...params} label="Course" />}
            />
             </Grid>

             <Grid item xs={12} md={6} lg={4} >  
            <Autocomplete
              options={instructors}
              getOptionLabel={(option) => option.full_name}
              onChange={(event, newValue) => setSelectedInstructor(newValue)}
              value={selectedInstructor}
              renderInput={(params) => <TextField {...params} label="Instructor" />}
            />
            </Grid>
            <Grid item xs={12} md={6} lg={2}>  
            <MDButton   variant="gradient"
                       size="small"
                       color="info"
                       sx={{background:"#00274D",mY:3}}  onClick={handleAddCourse}>
              Add Course
            </MDButton>
            </Grid>
            </Grid>
            <Grid container spacing={3}  mt={1}>
              {/* First Row of Inputs */}
              <Grid item xs={12} md={12} lg={12}> 
              <DataTable
            table={{
              columns,
              rows: displayCourseHistory,
            }}
            canSearch={true}
            sortable={true}
            rowsPerPage={5}
            showPagination
          />
          </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>

        <MDButton   variant="gradient"
                       size="small"
                       color="info"
                       sx={{background:"#00274D"}} onClick={handleSubmit} >Submit</MDButton>

        <MDButton   variant="gradient"
                       size="small"
                       color="black"
                       sx={{background:"grey"} } onClick={onClose} >Cancel</MDButton>
      </DialogActions>
      <LoadingDialog open={loading2}/>
      </Dialog>
      </DashboardLayout>  );
};

export default CourseHistoryForm;
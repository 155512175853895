import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from 'components/MDBox';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { useEffect, useState,useCallback  } from "react";
import swal from 'sweetalert';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import UserCreate from "./userCreate";
import UserEdit from "./userEdit";
import LoadingDialog from 'components/Loading/LoadingDialog';

const Users = () => {
    const token =  localStorage.getItem("authToken");
    const [deleting, setDeleting] = useState(false);
    const [loading, setLoading] = useState(true);
  
    const [openEdit, setOpenEdit] = useState(false);
    const[users,setUsers] = useState([])
    const [openAdd, setOpenAdd] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const handleOpenEdit = (user) => {
    
      setSelectedUser(user);
      setOpenEdit(true);
    };



    const fetchUsers = useCallback(async () => {
        try {
          const response = await axios.get(`${API_BASE_URL}/users`, {
            headers: { "Authorization": `Bearer ${token}` }
          });

         
        
          setUsers(response.data);
        } catch (err) {
          console.error("Failed to fetch Users:", err);
        }finally {
          setLoading(false); // Set loading to false after fetching
        }
      },[token]);

    useEffect(() => {
       
        fetchUsers();
      },[fetchUsers]);

     

      const handleAddUsers = () => {
        fetchUsers();
      };

      const handleEditUser = () => {
        fetchUsers()
      }

      const handleDeleteUser = async (id) => {
        const confirm = await swal({
          title: "Are you sure?",
          text: "You will not be able to recover this users!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        });
    
        if (confirm) {
          setDeleting(true);
        
            try {
              const response = await axios.delete(`${API_BASE_URL}/users/${id}`, {
                headers: { "Authorization": `Bearer ${token}` },
              });
      
              if (response.status === 204) {
                setUsers((prevUsers) => prevUsers.filter((user) => user.id !== id));
                swal({
                  title: "User Deleted Successfully!",
                  icon: "success",
                });
              }
            } catch (error) {
              swal({
                title: `${error.response?.data?.message || "Error occurred"}`,
                icon: "error",
              });
            } finally {
              setDeleting(false); // Stop the deleting process
            }
        }
      };

      const handleToggleActive = async (user) => {
        const updatedStatus = user.is_active === 1 ? 0 : 1;
        const action = updatedStatus === 1 ? 'activate' : 'deactivate';
      
        // Show confirmation dialog
        const confirm = await swal({
          title: `Are you sure you want to ${action} this user?`,
          text: `This will ${action} the user's account.`,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        });
      
        if (confirm) {
          try {
            console.log("updatedStatus:", updatedStatus);
      
            const response = await axios.put(
              `${API_BASE_URL}/update-status/${user.id}`,
              { is_active: updatedStatus }, // Send the updated status to the backend
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );
      
            if (response.status === 200) {
              // Update the user's status in the users state
              setUsers((prevUsers) =>
                prevUsers.map((u) =>
                  u.id === user.id ? { ...u, is_active: updatedStatus } : u
                )
              );
              
              swal({
                title: `User ${action === 'activate' ? 'activated' : 'deactivated'} successfully!`,
                icon: "success",
              });
            }
          } catch (error) {
            console.error("Failed to update user status:", error);
            // Show error alert
            swal({
              title: "Error",
              text: "Failed to update user status. Please try again.",
              icon: "error",
            });
          }
        }
      };
      
      

      const columns = [
        {
          accessor: 'action',
          Header: '',  // You can leave this blank if you don't want a header
          width: 40,  // Explicit width in pixels
          padding:0,
          Cell: ({ row }) => (
            <PopupState popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                    <MoreVertIcon />
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem onClick={() => {
                      handleOpenEdit(row.original);
                      popupState.close();
                    }}  style={{ color: '#1976d2' }}>
                      <EditIcon style={{ marginRight: 8 }} /> Edit
                    </MenuItem>
                    <MenuItem onClick={() => {
                      handleDeleteUser(row.original.id);
                      popupState.close();
                    }}  style={{ color: '#d32f2f' }} >
                 
                <>
                  <DeleteIcon style={{ marginRight: 8 }} /> Delete
                </>
              
                    </MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          ),
          showSortIcons: false,
          
          
        },
        { Header: 'Users Name', accessor: 'user_name' },
        { Header: 'Full Name', accessor: 'full_name',showSortIcons: false, },
        {
          Header: 'Email',
          accessor: 'email',
          showSortIcons: false,
        
        },
        { Header: 'Role', accessor: 'roles[0].name',showSortIcons: false, },
        {
            Header: 'Date Registered',
            accessor: 'date_registered',
            showSortIcons: false,
            Cell: ({ value }) => {
              // Convert the date to a more readable format
              const formattedDate = new Date(value).toLocaleDateString('en-GB', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              });
              return formattedDate;
            },
          },
          {
            Header: 'Is Active',
            accessor: 'is_active',
            Cell: ({ row }) => (
              row.original.roles[0].name !== 'admin' ? (
                <Switch
                  checked={row.original.is_active === 1}
                  onChange={() => handleToggleActive(row.original)}
                />
              ) : null // Don't show the switch if the user role is 'admin'
            ),
            showSortIcons: false,
          },
       
      ];

      const handleCloseDialog = () => {
        setOpenAdd(false);
        setOpenEdit(false);
        // setSelectedStaff(null);
      };


    
  return (
    <DashboardLayout>
    <Card>
        <CardContent>
            <MDBox>
            <MDTypography variant="h5" fontWeight="medium">
               Users
             </MDTypography>
             <MDBox>
            
                    <DataTable
                      table={{
                        columns,
                        rows: users,
                      }}
                      canSearch={true}
                      sortable={true}
                      pagination
                      rowsPerPage={5}
                      showPagination
                      widgets = {
                        <MDBox mx={2}>
                       <MDButton
                       variant="gradient"
                       ml="auto"
                           color="info"
                           size="small"
                           onClick={() => setOpenAdd(true)}
                           sx={{background:"#00274D", my:4,mx:3}}
                     >
                       <span>Add </span>
                     </MDButton>
                     </MDBox>
                      }
                    />
                    
             </MDBox>
             <UserCreate open={openAdd} onClose={handleCloseDialog} onAdd={handleAddUsers} />
            
            {selectedUser && (
            <UserEdit
                open={openEdit}
                onClose={handleCloseDialog}
                user={selectedUser}
                onEdit={handleEditUser}
            />
            )}
            
            
            </MDBox>
        </CardContent>
    </Card>
    <LoadingDialog open={deleting || loading}/>
</DashboardLayout>
  )
}

export default Users
import React, { useState ,useEffect} from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MDBox from 'components/MDBox';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import API_BASE_URL from "api";
import axios from "axios";

import swal from 'sweetalert';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddFeeMangement from "./addfee";
import EditFee from "./editFee";
import LoadingDialog from "components/Loading/LoadingDialog";

const FeeManagement = () => {
    console.log("finance")
    const [search] = useState('');
  const token =  localStorage.getItem("authToken");
 
  const [showAlert, setShowAlert] = useState(false);

  const [openEdit, setOpenEdit] = useState(false);
  const[fees,setFee] = useState([])
  const [selectedFee, setSelectedFee] = useState(null);
  const [openAdd, setOpenAdd] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let timer;
    if (showAlert) {
      timer = setTimeout(() => {
        setShowAlert(false);
      }, 5000); // 5000 milliseconds = 5 seconds
    }
    return () => clearTimeout(timer); // Clean up the timer
  }, [showAlert]);

  useEffect(() => {
    const fetchFee = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${API_BASE_URL}/fees`, {
          headers: { "Authorization": `Bearer ${token}` }
        });
        console.log("fees:",response.data)

        setFee(response.data);
      } catch (err) {
        console.error(err);
      }  finally {
        setLoading(false); 
      } 
      
    };
    fetchFee();
  }, [token]);

  const handleAddFee = (newFee) => {
    // console.log("Adding status...");
    setFee((prevFee) => [...prevFee, newFee]);
  };
  

  const handleEditFee = (updatedFee) => {
    
    setFee(fees.map((c) =>
      c.id === updatedFee.id ? updatedFee : c
    ));
  };


  const handleDeleteCategory = async (id) => {
    const confirm = await swal({
      title: "Are you sure?",
      text: "You will not be able to recover this Fee!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (confirm) {
      try {
        const response = await axios.delete(`${API_BASE_URL}/fees/${id}`,
          {
          headers: { "Authorization": `Bearer ${token}` }
        }
      );
        if(response.status === 204){
          setFee(fees.filter((c) => c.id !== id));
          swal({
            title: "Fee Deleted Successfully!",
            // text: "Course Created Successfully!",
            icon: "success"
          });
        }
      } catch (error) {
      
        swal({
          title: `${error.response.data.message}`,
          // text: "Course Created Successfully!",
          icon: "warning"
        });
      }
    }
  };

  const handleOpenEdit = (fee) => {
    setSelectedFee(fee);
    setOpenEdit(true);
  };

  const handleCloseDialog = () => {
    setOpenAdd(false);
    setOpenEdit(false);
    setSelectedFee(null);
  };

  const filteredData = search
  ? fees && fees.filter(c => 
      c.name &&
      c.name.toLowerCase().includes(search.toLowerCase())
    )
  : fees;

  const columns = [
    {
      accessor: 'action',
      Header: '',  // You can leave this blank if you don't want a header
      width: 40,  // Explicit width in pixels
      padding:0,
      Cell: ({ row }) => (
        <PopupState popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                <MoreVertIcon />
              </Button>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={() => {
                  handleOpenEdit(row.original);
                  popupState.close();
                }}  style={{ color: '#1976d2' }}>
                  <EditIcon style={{ marginRight: 8 }} /> Edit
                </MenuItem>
                <MenuItem onClick={() => {
                  handleDeleteCategory(row.original.id);
                  popupState.close();
                }}  style={{ color: '#d32f2f' }}>
                  <DeleteIcon style={{ marginRight: 8 }} /> Delete
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      ),
      showSortIcons: false,
      
      
    },
    { Header: 'Fee Type', accessor: 'fee_type',showSortIcons: false, },
    { Header: 'Fee Amount', accessor: 'fee_amount', },
    // { Header: 'Application For', accessor: 'application_for',showSortIcons: false, },
    { Header: 'Frequency', accessor: 'frequency',showSortIcons: false, },
    { Header: 'Description', accessor: 'description',showSortIcons: false, },
    { Header: 'Applicable For', accessor: 'applicable_for',showSortIcons: false, },
    
   
   
  ];

  return (
    <DashboardLayout>
    <Card>
      <CardContent>
        <MDBox>

        <MDTypography variant="h5" fontWeight="medium">
          Fees
        </MDTypography>
        <MDBox>
                <DataTable
                  table={{
                    columns,
                    rows: filteredData,
                  }}
                  canSearch={true}
                  sortable={true}
                  pagination
                  rowsPerPage={5}
                  showPagination
                  widgets = {
                    <MDBox mx={2}>
                  <MDButton
                  variant="gradient"
                  ml="auto"
                      color="info"
                      size="small"
                      onClick={() => setOpenAdd(true)}
                      sx={{background:"#00274D", my:4,mx:3}}
                >
                  <span>Add Fees</span>
                </MDButton>
                </MDBox>
                  }
                />
                  {!loading && filteredData.length === 0 && (
          <MDTypography    variant="h5" display="block" align="center" my={2}  sx={{
            fontStyle: "italic",
            fontWeight: "bold",
            color: "#9e9e9e", 
            opacity: 0.7,
          }}>
            No data available
          </MDTypography>
        )}
        </MDBox>
        <AddFeeMangement open={openAdd} onClose={handleCloseDialog} onAdd={handleAddFee} />

        {selectedFee && (
          <EditFee
              open={openEdit}
              onClose={handleCloseDialog}
              fees={selectedFee}
              onEdit={handleEditFee}
          />
          )}
          
        
       
        
        </MDBox>
      </CardContent>
    </Card>
    <LoadingDialog open={loading} />

  </DashboardLayout>
  )
}

export default FeeManagement
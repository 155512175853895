import React, { useState, useEffect,useCallback } from 'react';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { useNavigate } from 'react-router-dom';
import {
 
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import EditIcon from '@mui/icons-material/Edit';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useParams } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { axiosInstance } from 'api2';
import swal from "sweetalert";
import LoadingDialog from 'components/Loading/LoadingDialog';

const ViewExam = () => {
  const { id } = useParams();
   const [selectedExam, setSelectedExam] = useState(null);
  const [editing, setEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const [editingQuestion, setEditingQuestion] = useState(null);
  const [loading, setLoading] = useState(false); 
  const [loading2, setLoading2] = useState(false); 
  const navigate = useNavigate();



  const fetchExams = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`/exams/${id}`);
       setSelectedExam(response.data);
    } catch (error) {
      console.error('Error fetching exams:', error);
    }
  },[id]);

  useEffect(() => {
    fetchExams();
  },[fetchExams]);

  // Handle Edit Exam Info
  const handleEditExamInfo = () => {
    setEditing(true);
  };

  const handleExamChange = (e) => {
    setSelectedExam({
      ...selectedExam,
      [e.target.name]: e.target.value,
    });
  };

  const handleSaveExamInfo = async () => {
    setLoading2(true);
    try {
      const response =await axiosInstance.put(`/exams/${selectedExam.id}`, selectedExam);
      if (response.status === 200) {
        swal({
          title: "Exam Info Updated Successfully!",
          // text: "Course Created Successfully!",
          icon: "success"
        });
        setEditing(false);
      }
    } catch (error) {
      console.error('Error updating exam info:', error);
    }  finally {
      setLoading2(false); // Stop loading
    }
  };

  // Handle Edit Question
  const handleEditQuestion = (question) => {
    setEditingQuestion(question);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingQuestion(null);
  };

  const handleSaveQuestion = async () => {
    try {
      setLoading(true);
      
      const { created_at, updated_at, exam_id, ...questionData } = editingQuestion;
      console.log("questionData:",questionData)
      const response = await axiosInstance.put(`/exams/${id}/questions`, questionData);
      if (response.status === 200) {
      swal({
        title: "Question Updated Successfully!",
        // text: "Course Created Successfully!",
        icon: "success"
      });
      fetchExams();
      handleClose();
    }
    } catch (error) {
      console.error('Error updating question:', error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleQuestionChange = (e) => {
    setEditingQuestion({
      ...editingQuestion,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <DashboardLayout>
      <Card>
      <CardContent>
        <MDButton
          variant="gradient"
          ml="auto"
              color="info"
              size="small"
              onClick={() => navigate("/exam")}
              sx={{background:"#00274D", my:2,mx:3}}
          >
          <span>Back</span>
          </MDButton>
          <MDBox p={3}>
            <MDTypography variant="h5">View Exam</MDTypography>
            {selectedExam && (
              <>
                {/* Editable Exam Information */}
                <MDBox display="flex" justifyContent="space-between" alignItems="center">
                  {editing ? (
                    <MDBox>
                      <TextField
                        label="Exam Name"
                        name="name"
                        value={selectedExam.name}
                        onChange={handleExamChange}
                        margin="normal"
                        fullWidth
                      />
                      <TextField
                        label="Date"
                        name="date"
                        type="date"
                        value={selectedExam.date}
                        onChange={handleExamChange}
                        margin="normal"
                        fullWidth
                      />
                      <TextField
                        label="Duration"
                        name="duration"
                        value={selectedExam.duration}
                        onChange={handleExamChange}
                        margin="normal"
                        fullWidth
                      />
                      <FormControl fullWidth mb={3}>
                        <InputLabel id="exam-type-label" sx={{ 
        backgroundColor: '#f5f5f5', 
        px: 1,
        my: 0.5,
        transform: 'translate(14px, -9px) scale(0.75)',
        '&.Mui-focused': {
          transform: 'translate(14px, -9px) scale(0.75)',
        },
        '&.MuiInputLabel-shrink': {
          transform: 'translate(14px, -9px) scale(0.75)',
        }
      }}  >Exam Type</InputLabel>
                        <Select
                           labelId="exam-type-label"
                           id="exam_type"
                           name="exam_type"
                          value={selectedExam.exam_type}
                          onChange={handleExamChange}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#f5f5f5",
                            padding: "10px",
                            borderRadius: 1,
                            "& .MuiSelect-select": {
                              display: "flex",
                              alignItems: "center",
                              paddingRight: "32px",
                            },
                          }}
                          IconComponent={() => (
                            <ArrowDropDownIcon
                              sx={{
                                position: "absolute",
                                right: 8,
                                pointerEvents: "none",
                              }}
                            />
                          )}
                        >
                          <MenuItem value="quiz">Quiz</MenuItem>
                          <MenuItem value="final">Final</MenuItem>
                          <MenuItem value="midterm">Midterm</MenuItem>
                        </Select>
                      </FormControl>

                      <MDBox display="flex" justifyContent="flex-end" gap={2} my={2}>
                        <MDButton onClick={handleSaveExamInfo} color="info" my={3} size="small">
                         Save
                        </MDButton>
                        <MDButton   variant="gradient"
                         size="small"
                         color="black"
                         sx={{background:"grey"}} onClick={() => setEditing(false)}>Cancel</MDButton>
                      </MDBox>
                    </MDBox>
                  ) : (
                    <MDBox>
                      <MDTypography variant="body2" mt={2}>
                      <strong>Exam Name: </strong>{selectedExam.name} <EditIcon onClick={handleEditExamInfo} cursor="pointer" mx={2}/>
                      </MDTypography>
                      <MDTypography variant="body2">
                      <strong>Date: </strong>
                        {selectedExam.date}
                      </MDTypography>
                      <MDTypography variant="body2">
                      <strong> Duration: </strong>
                      {selectedExam.duration}
                      </MDTypography>
                      <MDTypography variant="body2">
                      <strong>Type: </strong>
                         {selectedExam.exam_type}
                      </MDTypography>
                    
                    </MDBox>
                  )}
                </MDBox>
                {/* Questions */}
                {selectedExam.questions.map((question, index) => (
                  <MDBox key={question.id} mt={3}>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={11}>
                        <MDTypography variant="h5">
                          {index + 1}. {question.question}
                        </MDTypography>
                      </Grid>
                      <Grid item xs={1}>
                        <MDButton onClick={() => handleEditQuestion(question)}>
                          <EditIcon />
                        </MDButton>
                      </Grid>
                    </Grid>
                    <RadioGroup
                      aria-label={`question-${question.id}`}
                      name={`question-${question.id}`}
                      value={question.correct_answer}
                    >
                      <FormControlLabel value="a" control={<Radio />} label={question.option_a} />
                      <FormControlLabel value="b" control={<Radio />} label={question.option_b} />
                      <FormControlLabel value="c" control={<Radio />} label={question.option_c} />
                      <FormControlLabel value="d" control={<Radio />} label={question.option_d} />
                    </RadioGroup>
                  </MDBox>
                ))}
              </>
            )}
          </MDBox>
      </CardContent>
      </Card>

      {/* Dialog for Editing a Question */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Question</DialogTitle>
        <DialogContent>
          {editingQuestion && (
            <>
              <TextField
                autoFocus
                margin="dense"
                name="question"
                label="Question"
                type="text"
                fullWidth
                value={editingQuestion.question}
                onChange={handleQuestionChange}
              />
              <TextField
                margin="dense"
                name="option_a"
                label="Option A"
                type="text"
                fullWidth
                value={editingQuestion.option_a}
                onChange={handleQuestionChange}
              />
              <TextField
                margin="dense"
                name="option_b"
                label="Option B"
                type="text"
                fullWidth
                value={editingQuestion.option_b}
                onChange={handleQuestionChange}
              />
              <TextField
                margin="dense"
                name="option_c"
                label="Option C"
                type="text"
                fullWidth
                value={editingQuestion.option_c}
                onChange={handleQuestionChange}
              />
              <TextField
                margin="dense"
                name="option_d"
                label="Option D"
                type="text"
                fullWidth
                value={editingQuestion.option_d}
                onChange={handleQuestionChange}
              />

<FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="correct_answer-label" sx={{ 
        backgroundColor: '#f5f5f5', 
        px: 1,
        my: 0.5,
        transform: 'translate(14px, -9px) scale(0.75)',
        '&.Mui-focused': {
          transform: 'translate(14px, -9px) scale(0.75)',
        },
        '&.MuiInputLabel-shrink': {
          transform: 'translate(14px, -9px) scale(0.75)',
        }
      }}>Correct Answer</InputLabel>
          <Select
            labelId="correct_answer-label"
            id="correct_answer"
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#f5f5f5",
              padding: "10px",
              borderRadius: 1,
              "& .MuiSelect-select": {
                display: "flex",
                alignItems: "center",
                paddingRight: "32px",
              },
            }}
            name="correct_answer"
            value={editingQuestion.correct_answer}
            onChange={handleQuestionChange}
          >
            <MenuItem value="a">A</MenuItem>
            <MenuItem value="b">B</MenuItem>
            <MenuItem value="c">C</MenuItem>
            <MenuItem value="d">D</MenuItem>
          </Select>
        </FormControl>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose} color="secondary">
            Cancel
          </MDButton>
          <MDButton onClick={handleSaveQuestion} color="info">
            Save
          </MDButton>
        </DialogActions>
        <LoadingDialog open={loading || loading2} />
      </Dialog>
    </DashboardLayout>
  );
};

export default ViewExam;
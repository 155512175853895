import React, { useState,useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { axiosInstance } from "api2";
import swal from "sweetalert";
import AddIcon from '@mui/icons-material/Add';
import { Autocomplete, TextField } from '@mui/material';

const AddLeaveRequest = ({ open, onClose, onAdd }) => {
  // Initial state for the modal data with the new fields
  const targetId = localStorage.getItem('targetId');
  
  const [leaveTypes,setLeaveTypes] = useState([])
const [modalData, setModalData] = useState({
    employee_id:targetId,
    leave_type_id: 0,
    start_date: '',
    end_date: '',
    reason: '',
    attachment: '',
  });



  useEffect(() => {
    const fetchData = async () => {
      try {
        const [typesData] = await Promise.all([axiosInstance.get('/leave-types')]);
        setLeaveTypes(typesData.data);
      } catch (error) {
        console.error("Error fetching leavetypes:", error);
      }
    };

    fetchData();
  
 
  }, []); // Dependency is set properly
  
  
  // Error state
  const [error, setError] = useState({});
  
  // Handle modal input changes
  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData({
      ...modalData,
      [name]: value,
    });
    setError({
      ...error,
      [name]: "", 
    });
  };
  
  // Handle file input change for attachment
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setModalData({
      ...modalData,
      [name]: files[0], // Store the selected file
    });
    setError({
      ...error,
      [name]: "",
    });
  };
  
  // Submit handler
  const handleModalSubmit = async () => {
    try {
        
      const formData = new FormData();
      for (const key in modalData) {
        formData.append(key, modalData[key]); // Append all fields to formData
      }
  
      const response = await axiosInstance.post(`/leaves`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response.status === 201) {
        onAdd(response.data);
        onClose();
        swal({
          title: "Leave Request Created Successfully!",
          icon: "success",
        });
  
        // Reset form fields
        setModalData({
         
          leave_type_id: 0,
          start_date: '',
          end_date: '',
          reason: '',
          attachment: '',
        });
        setError({});
      
    } }catch (error) {
      if (error.response?.status === 400) {
        const errors = error.response.data.message;
        setError(errors); 
      }
    }
  };
  
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
  <DialogTitle textAlign="center">
    <AddIcon /> Add Leave Request
  </DialogTitle>
  {/* {error && (
  <MDBox mx={2} mt={2} mb={2}>
    <Alert severity="error" variant="filled">
      {Object.keys(error).map((key) => (
        <div key={key}>{error[key]}</div>  // Display each error message
      ))}
    </Alert>
  </MDBox>
)} */}

  <DialogContent>

    

    {/* Leave Type ID */}
{/* Leave Type ID */}
<MDBox mb={2}>
  <MDTypography variant="caption" color="text" fontWeight="medium">
    Leave Type ID
  </MDTypography>
  <Autocomplete
    fullWidth
    options={leaveTypes} // Assuming leaveTypes is an array of objects with the detailed structure
    getOptionLabel={(option) => option.leave_type_name || ''} // Use leave_type_name for display
    onChange={(event, newValue) => {
      setModalData((prevData) => ({
        ...prevData,
        leave_type_id: newValue ? newValue.id : null, // Set the ID based on the selected option
      }));
    }}
    value={leaveTypes.find(type => type.id === modalData.leave_type_id) || null} // Ensure the selected value matches the ID
    renderInput={(params) => (
      <TextField 
        {...params} 
        label="Leave Types" 
        error={!!error.leave_type_id} 
        helperText={error.leave_type_id ? error.leave_type_id[0] : ''}
      />
    )}
  />
</MDBox>
    {/* Start Date */}
    <MDBox mb={2}>
      <MDTypography variant="caption" color="text" fontWeight="medium">
        Start Date
      </MDTypography>
      <MDInput
        type="date"
        name="start_date"
        fullWidth
        required
        value={modalData.start_date || ''}
        onChange={handleModalChange}
       
      />
  
    </MDBox>

    {/* End Date */}
    <MDBox mb={2}>
      <MDTypography variant="caption" color="text" fontWeight="medium">
        End Date
      </MDTypography>
      <MDInput
        type="date"
        name="end_date"
        fullWidth
        required
        value={modalData.end_date || ''}
        onChange={handleModalChange}
    
      />

    </MDBox>

    {/* Reason */}
    <MDBox mb={2}>
      <MDTypography variant="caption" color="text" fontWeight="medium">
        Reason
      </MDTypography>
      <MDInput
        type="text"
        name="reason"
        fullWidth
        required
        value={modalData.reason}
        onChange={handleModalChange}
        
      />

    </MDBox>

    {/* Attachment (File Upload) */}
    <MDBox mb={2}>
      <MDTypography variant="caption" color="text" fontWeight="medium">
        Attachment
      </MDTypography>
      <MDInput
        type="file"
        name="attachment"
        fullWidth
        required
        onChange={handleFileChange} 
       
      />
 
    </MDBox>

  </DialogContent>

  <DialogActions>
        <MDButton
          variant="gradient"
          size="small"
          color="info"
          sx={{ background: "#00274D" }}
          onClick={handleModalSubmit}
        >
          Submit
        </MDButton>
        <MDButton
          variant="gradient"
          size="small"
          color="black"
          sx={{ background: "grey" }}
          onClick={onClose}
        >
          Cancel
        </MDButton>
      </DialogActions>
</Dialog>

  )
}

export default AddLeaveRequest
import React, { useState ,useEffect} from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MDBox from 'components/MDBox';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import API_BASE_URL from "api";
import axios from "axios";
import swal from 'sweetalert';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LoadingDialog from "components/Loading/LoadingDialog";

const Exams = () => {
    const [exams,setExams] =  useState([])
    const token =  localStorage.getItem("authToken");
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true); 
    
    const [showAlert, setShowAlert] = useState(false);

    const handleViewDetails = (data) => {
      navigate(`/view-exam/${data.id}`);

    }
    useEffect(() => {
        const fetchExams = async () => {
          try {
            const response = await axios.get(`${API_BASE_URL}/exams`, {
              headers: { "Authorization": `Bearer ${token}` }
            });
            setExams(response.data);
          } catch (err) {
            console.error("Failed to fetch Exams:", err);
          } finally {
            setLoading(false)
          }
        };
        fetchExams();
      }, [token]);
    
      useEffect(() => {
        let timer;
        if (showAlert) {
          timer = setTimeout(() => {
            setShowAlert(false);
          }, 5000); // 5000 milliseconds = 5 seconds
        }
        return () => clearTimeout(timer); // Clean up the timer
      }, [showAlert]);

      const handleDeleteExam = async (id) => {
        const confirm = await swal({
          title: "Are you sure?",
          text: "You will not be able to recover this exams!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        });
    
        if (confirm) {
          try {
            const response = await axios.delete(`${API_BASE_URL}/exams/${id}`,
              {
              headers: { "Authorization": `Bearer ${token}` }
            }
          );
            if(response.status === 204){
              setExams(exams.filter((exam) => exam.id !== id));
              swal({
                title: "Exam Deleted Successfully!",
                // text: "Course Created Successfully!",
                icon: "success"
              });
            }
          } catch (error) {
          
            swal({
              title: `${error.response.data.message}`,
              // text: "Course Created Successfully!",
              icon: "warning"
            });
          }
        }
      };

      const columns = [
        {
          accessor: 'action',
          Header: '',  // You can leave this blank if you don't want a header
          width: 40,  // Explicit width in pixels
          padding:0,
          Cell: ({ row }) => (
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                    <MoreVertIcon />
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    
                    <MenuItem onClick={() => {
                      handleDeleteExam(row.original.id);
                      popupState.close();
                    }}  style={{ color: '#d32f2f' }}>
                      <DeleteIcon style={{ marginRight: 8 }} /> Delete
                    </MenuItem>
                    <MenuItem onClick={() => {
              handleViewDetails(row.original);
              popupState.close();
            }} style={{ color: '#1976d2' }}>
              <VisibilityIcon style={{ marginRight: 8 }} /> View Details
            </MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          ),
          showSortIcons: false,
          
          
        },
        { Header: 'Exam Name', accessor: 'name' },
        { Header: 'Exam Date', accessor: 'date',showSortIcons: false, },
        { Header: 'Duration', accessor: 'duration',showSortIcons: false, },
        { Header: 'Exam Type', accessor: 'exam_type',showSortIcons: false, },
        
        { Header: 'Course Number', accessor: 'course.course_number',showSortIcons: false, },
        { Header: 'Course Name', accessor: 'course.course_name',showSortIcons: false, },
        { Header: 'Instructor Name', accessor: 'instructor.full_name',showSortIcons: false, },
        { Header: 'Questions', accessor: 'questions.length',showSortIcons: false, },
        // {
        //   Header: 'Staff Head',
        //   accessor: 'staff_head',
        //   showSortIcons: false,
        //   Cell: ({ value }) => {
        //     const employee = getEmployeeById(value);
        //     return employee 
        //       ? `${employee.first_name} ${employee.last_name}`
        //       : '';
        //   },
        // },
       
       
      ];
    
  return (
    <DashboardLayout>
    <Card>
      <CardContent>
        <MDBox>
        <MDTypography variant="h5" fontWeight="medium">
           Exam
         </MDTypography>
         <MDBox mt={2}>

                <DataTable
                  table={{
                    columns,
                    rows: exams,
                  }}
                  canSearch={true}
                  sortable={true}
                  pagination
                  rowsPerPage={5}
                  showPagination
                  widgets = {
                    <MDBox mx={2}>
                   <MDButton
                   variant="gradient"
                   ml="auto"
                       color="info"
                       size="small"
                       onClick={() => navigate("/add-exam")}
                       sx={{background:"#00274D", my:4,mx:3}}
                 >
                   <span>Add</span>
                 </MDButton>
                 </MDBox>
                  }
                />
                    {!loading && exams.length === 0 && (
          <MDTypography    variant="h5" display="block" align="center" my={2}  sx={{
            fontStyle: "italic",
            fontWeight: "bold",
            color: "#9e9e9e", // Light grey color
            opacity: 0.7,
          }}>
            No assessment available
          </MDTypography>
        )}
         </MDBox>
        
        
        
        
        </MDBox>
      </CardContent>
    </Card>
    <LoadingDialog open={loading} />
</DashboardLayout>
  )
}

export default Exams
// exam_name,exam_date,durations,exam_type,course_number,course_name,instructor_name,questions.length
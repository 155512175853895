import React,{ useEffect, useState } from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { axiosInstance } from 'api2';
import FileViewer from "components/FileViewer";
import MDButton from 'components/MDButton';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import MDBox from 'components/MDBox';
import LoadingDialog from 'components/Loading/LoadingDialog';


function formatNumber(num) {
  // Ensure the input is a number
  const numberValue = parseFloat(num);
  if (isNaN(numberValue)) {
      return 'Invalid number';
  }
  return numberValue.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
  });
}

const InstructorDashboard = () => {
  const targetId = localStorage.getItem('targetId');
  const [data,setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [assessments,setAssessments] = useState([])
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [announcements,setAnnouncements] = useState([])

  
 
  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const response = await axiosInstance.get(`/instructor-dashboard/${targetId}`);
        const data = await response.data;

        setData(data);
        setAssessments(data.assessment)
        setAnnouncements(data.announcements)
        
      } catch (error) {
        console.error('Error fetching student data:', error);
      }finally {
        setLoading(false); // Stop loading once data is fetched
      }
    };
  
    fetchStudentData();
  }, [targetId]);

  const handleViewFile = (filePath) => {
    const extension = filePath.split('.').pop().toLowerCase();
    setSelectedFile(filePath);
    setFileType(extension === "pdf" ? "pdf" : ["jpg", "jpeg", "png", "gif"].includes(extension) ? "image" : "other");
    setOpenDialog(true);
  };



  const courses = data.courses || [];
  // Utility function to format numbers with commas
const formatNumbers = (number) => {
  number = Number(number);
  if (typeof number !== "number") {
    throw new Error("Input must be a number");
  }
  return number.toLocaleString(); // or use new Intl.NumberFormat().format(number);
};


  const total = [
    {
      color: "primary",
      icon: "school_icon", // or any suitable icon for employees
      label: "Total Students",
      count: formatNumbers(data.totalStudents),
     
    },
    {
      color: "secondary",
      icon: "star_icon", // or any suitable icon for payroll
      label: "Average Rating",
      count: formatNumber(data.average_point), // Payroll count
    
    },
    {
      color: "success",
      icon: "book_icon", // or any suitable icon for leave requests
      label: "Total courses",
      count: formatNumbers(data.totalCourses)
     
    },
    {
      color: "error",
      icon: "calendar_today_icon", // or any suitable icon for leave requests
      label: "Total Balance",
      count: formatNumbers(data.total_leave_days)
     
    },
  ];


  return (
    <DashboardLayout>
     
          <Box sx={{ padding: 2}}>
           
              <Grid container spacing={3}>
                {total.map((item, index) => {
                  return (
                    <Grid item xs={true} key={index}>
                      <MDBox mb={1.5}>
                        <ComplexStatisticsCard
                          color={item.color}
                          icon={item.icon}
                          title={item.label}
                          count={item.count}
          
          
                        />
                      </MDBox>
                    </Grid>
                  );
                })}
              </Grid>
            {/* Assignments and Top Students Section */}
            <Grid container spacing={2} sx={{ marginTop: 2 }}>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
          {/* Assignments Section */}
          <Grid item xs={12}>
            <Box elevation={2} sx={{ padding: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
              <Typography variant="h4" sx={{ marginBottom: 2 }}>Assignments</Typography>
              <Grid container spacing={2}>
                {assessments.length > 0 ? (
                  assessments.map((assessment, index) => (
                    <Grid item xs={12} sm={6} md={4} key={assessment.id}>
                      <Paper
                        elevation={2}
                        sx={{
                          padding: 2,
                          my: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          borderTop: `4px solid ${index % 3 === 0 ? '#ff0000' : index % 3 === 1 ? '#00ff00' : '#0000ff'}`
                        }}
                      >
                        <div>
                          <Typography variant="h5">{assessment.assessment_name}</Typography>
                          <Typography variant="body2">Due {new Date(assessment.due_date).toLocaleDateString()}</Typography>
                        </div>
          
                        <MDButton
                          variant="outlined"
                          size="small"
                          color="primary"
                          component="a"
                          onClick={() => handleViewFile(assessment.file_path)}
                          sx={{
                            marginTop: 2,
                            textDecoration: 'underline',
                            boxShadow: 1,
                            transition: 'box-shadow 0.3s ease-in-out',
                            '&:hover': {
                              color: 'secondary.main',
                              boxShadow: 4,
                            }
                          }}
                        >
                          View File
                        </MDButton>
                      </Paper>
                    </Grid>
                  ))
                ) : (
                  <Typography variant="h5">No assignments available.</Typography>
                )}
              </Grid>
            </Box>
          </Grid>
          {/* Top Students Section */}
          <Grid item xs={12}>
            {/* <Paper elevation={2} sx={{ padding: 2 }}> */}
              <Typography variant="h4" sx={{ marginBottom: 2 }}>Courses</Typography>
              <Grid container spacing={2}>
                {courses.map((course, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card>
                      <CardContent>
                      <Typography variant="h6" fontWeight="medium">{course.course_name}</Typography>
                      <Typography variant="body2"  color="text.secondary"> Course No: {course.course_number}</Typography>
                      <Typography variant="body2"  color="text.secondary">Credits: {course.credit_hour}</Typography>
                     
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            {/* </Paper> */}
          </Grid>
              </Grid>
            </Grid>
          
            {/* Announcements Section */}
            <Grid item xs={12} md={4}>
              <Box elevation={2} sx={{ padding: 2, }}>
          <Typography variant="h4" sx={{ marginBottom: 2 }}>Announcements</Typography>
          {announcements.length > 0 ? (
            announcements.map((item) => (
              <Box key={item.id} sx={{ mb: 2, p: 2, bgcolor: '#ffffff', borderRadius: 1, boxShadow: 1 }}>
                <Typography variant="h6" fontWeight="medium">{item.title}</Typography>
                <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                  <strong>Announced By:</strong> {item.announced_by} | <strong>Date:</strong> {new Date(item.announcement_date).toLocaleDateString()}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.primary' }}>{item.content}</Typography>
              </Box>
            ))
          ) : (
            <Typography variant="h5">No announcements available.</Typography>
          )}
              </Box>
            </Grid>
          </Grid>
          
            <FileViewer
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            filePath={selectedFile}
            fileType={fileType}
          />
          </Box>
          <LoadingDialog open={loading} />
     
    </DashboardLayout>
  );
};

export default InstructorDashboard;


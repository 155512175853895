import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  Document,
  Image,
  Page,
  PDFDownloadLink,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import API_BASE_URL, { STORAGE_BASE_URL } from "api";
import axios from "axios";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useCallback, useEffect, useRef, useState } from "react";
import LoadingDialog from "components/Loading/LoadingDialog";



const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    "Content-Type": "application/json",
  },
});


const GradeReport = () => {
  const targetId = localStorage.getItem("targetId");
  const token = localStorage.getItem("authToken");
  const [courses, setCourses] = useState([]);
  const [student, setStudent] = useState({});
  const [gradeInfo, setGradeInfo] = useState([]);
  const [loading,setLoading] = useState(true);

  const fetchCourses = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`/grade-report/${targetId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setStudent(response.data.student);
      setCourses(response.data.courses);
    } catch (err) {
      console.error(err);
    }
  }, [token, targetId]);

  const fetchGradeInfo = useCallback(async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/grades-info`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setGradeInfo(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false)
    }
  }, [token]);

  useEffect(() => {
    fetchCourses();
    fetchGradeInfo();
  }, [fetchCourses, fetchGradeInfo]);

  const groupCoursesByYearAndSemester = (courses) => {
    return courses.reduce((acc, course) => {
      const key = `${course.year}-${course.semester}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(course);


      return acc;
    }, {});
  };

  const groupedCourses = groupCoursesByYearAndSemester(courses);

  const calculateGPA = (courses) => {
    const groupedCourses = groupCoursesByYearAndSemester(courses);
    const semesters = Object.keys(groupedCourses).sort();

    let cumulativePoints = 0;
    let cumulativeCredits = 0;
    const gpaResults = {};

    for (const semester of semesters) {
      const semesterCourses = groupedCourses[semester];

      const totalPoints = semesterCourses.reduce(
        (acc, course) => acc + course.grade.point * course.credit_hour,
        0
      );
      const totalCredits = semesterCourses.reduce(
        (acc, course) => acc + course.credit_hour,
        0
      );
      const semesterGPA = totalCredits
        ? (totalPoints / totalCredits).toFixed(2)
        : 0;

      cumulativePoints += totalPoints;
      cumulativeCredits += totalCredits;
      const cumulativeGPA = cumulativeCredits
        ? (cumulativePoints / cumulativeCredits).toFixed(2)
        : 0;

      gpaResults[semester] = {
        semesterGPA: semesterGPA,
        cumulativeGPA: cumulativeGPA,
        totalCredits: totalCredits,
        totalPoints: totalPoints,
      };
    }

    return gpaResults;
  };

  const cgpa = calculateGPA(courses);

  let cumulativeCredits = 0; // Track cumulative credits globally
  let cumulativePoints = 0; // Track cumulative points globally

  const renderCourseGroup = (year, semester, courses) => {
    const totalCredits = courses.reduce(
      (sum, course) => sum + course.credit_hour,
      0
    );
    const totalPoints = courses.reduce(
      (sum, course) => sum + course.grade.point,
      0
    );

    // Update cumulative totals
    cumulativeCredits += totalCredits;
    cumulativePoints += totalPoints;

    const semesterGPA = cgpa[`${year}-${semester}`]?.semesterGPA || "N/A";
    const cumulativeGPA = cgpa[`${year}-${semester}`]?.cumulativeGPA || "N/A";

    return (
      <Box key={`${year}-${semester}`} sx={{ padding: 2 }}>
        <Typography variant="h6" gutterBottom sx={{ color: "#222" }}>
          {year} - {semester}
        </Typography>
        <div className="data-grid-wrapper">
          <DataGrid
            rows={[
              ...courses.map((course) => ({
                id: course.registration_details.id,
                course_number: course.course_number,
                course_name: course.course_name,
                credit_hour: course.credit_hour,
                grade: course.grade.grade,
                point: course.grade.point,
              })),
              {
                id: "total", // Unique ID for the total row
                course_number: `Semester GPA: ${semesterGPA}`, // Display Semester GPA in the Course No column
                course_name: "Sum Totals", // Display "Sum Totals" in the Name column
                credit_hour: totalCredits, // Total credits for the semester
                grade: "", // Empty for this row
                point: totalPoints, // Total points for the semester
              },
              {
                id: "cumulative", // Unique ID for the cumulative total row
                course_number: `Cumulative GPA: ${cumulativeGPA}`, // Display Cumulative GPA in the Course No column
                course_name: "Cumulative Totals", // Display "Cumulative Totals" in the Name column
                credit_hour: cumulativeCredits, // Cumulative credits
                grade: "", // Empty for this row
                point: cumulativePoints, // Cumulative points
              },
            ]}
            columns={[
              { field: "course_number", headerName: "Course No", width: 200 }, // GPA values will appear here
              { field: "course_name", headerName: "Name", width: 300 }, // Sum and Cumulative totals will appear here
              { field: "grade", headerName: "Grade", width: 100 },
              { field: "credit_hour", headerName: "Credit", width: 100 },
              { field: "point", headerName: "Point", width: 100 },
            ]}
            pageSize={5}
            hideFooter={true}
            rowsPerPageOptions={[5]}
            sx={{
              backgroundColor: "white",
              border: "none",
              "& .MuiDataGrid-cell": {
                border: "none",
              },
              "& .MuiDataGrid-row": {
                fontWeight: "normal", // Remove bold styling for all rows
                backgroundColor: "transparent", // Transparent background
              },
            }}
          />
        </div>

        <hr
          style={{
            margin: "10px 0",
            border: "none",
            borderTop: "1px solid #222",
          }}
        />
      </Box>
    );
  };

  const cardRef = useRef();

  const styles = StyleSheet.create({
    page: {
      padding: 30,
      fontSize: 10,
      borderBottom: "5px solid black",
    },
    header: {
      borderBottom: "3px solid black",
      paddingBottom: "10px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    boldText: {
      fontWeight: "bold",
    },
    tableContainer: {
      display: "flex",
      flexDirection: "column",
      borderRight: "none",
    },
    tableRow: {
      display: "flex",
      flexDirection: "row",
      borderRight: "none",
      paddingBottom: "8px",
    },
    tableHeader: {
      backgroundColor: "#f4f4f4",
      fontWeight: "bold",
      borderRight: "none",
    },
    tableCell: {
      flex: 1, // Ensures that cells are distributed equally
      borderRight: "none",
    },
    lastCell: {
      borderRight: "none",
    },
  });

  const MyDocument = () => (
    <Document>
      <Page
        size="A4"
        orientation="landscape"
        style={[styles.page, { display: "flex" }]}
      >
        {/* Header */}
        <View style={styles.header}>
          {/* Logo and college name */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <Image
              style={{
                width: "50px",
                height: "50px",
              }}
              src={{
                uri: "/mks-logo.png",
              }}
            ></Image>
            <View>
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  marginBottom: "10px",
                }}
              >
                Meserete Kristos College
              </Text>
              <Text>Student Record</Text>
            </View>
          </View>

          {/* Student info */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
          >
             <Image
              style={{
                width: "50px",
                height: "50px",
              }}
              src={{
                uri: `${STORAGE_BASE_URL}/${student.profile_picture}`, method: "GET"
              }}
            ></Image>

            <View>
              <View style={{ display: "flex", flexDirection: "col", gap: "3px" }}>
                <Text>Name:</Text>
                <Text>Student ID:</Text>
                <Text>Sex:</Text>
                <Text>Birth Date:</Text>
              </View>
              <View style={{ display: "flex", flexDirection: "col", gap: "3px" }}>
                <Text>{`${student.first_name} ${student.last_name}`}</Text>
                <Text>{student.id}</Text>
                <Text>{student.gender}</Text>
                <Text>{student.date_of_birth}</Text>
              </View>
            </View>
          </View>

          {/* Program info */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <View style={{ display: "flex", flexDirection: "col", gap: "3px" }}>
              <Text>Program of Study:</Text>
              <Text>Major:</Text>
              <Text>Graduation Date:</Text>
            </View>
            <View style={{ display: "flex", flexDirection: "col", gap: "3px" }}>
              <Text>{student.program}</Text>
              <Text>{student.major}</Text>
              <Text>{student.graduation_date}</Text>
            </View>
          </View>
        </View>

        {/* Course list */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            paddingTop: "10px",
            flexGrow: 1,
          }}
        >
          {Object.keys(groupedCourses).length > 0 &&
            Object.keys(groupedCourses).map((semester) => (
              <View
                style={{
                  width: "45%",
                  borderTop: "1pt solid blue",
                  marginBottom: 10,
                }}
              >
                <Text
                  style={{
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    fontWeight: "bold",
                  }}
                >
                  {semester}
                </Text>
                <View style={styles.tableContainer}>
                  {groupedCourses[semester].map((course) => (
                    <View>
                      <View style={styles.tableRow}>
                        <Text style={styles.tableCell}>
                          {course.course_number}
                        </Text>
                        <Text style={[styles.tableCell, { minWidth: "90px" }]}>
                          {course.course_name}
                        </Text>
                        <Text style={styles.tableCell}>
                          {course.course_hour}
                        </Text>
                        <Text style={[styles.tableCell, { maxWidth: "30px" }]}>
                          {course.grade.credit}
                        </Text>
                        <Text style={[styles.tableCell, { maxWidth: "30px" }]}>
                          {course.grade.grade}
                        </Text>
                        <Text style={[styles.tableCell, { maxWidth: "30px" }]}>
                          {course.grade.point}
                        </Text>
                      </View>
                    </View>
                  ))}
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "30px",
                    borderTop: "0.5px solid gray",
                    paddingTop: "5px",
                    justifyContent: "flex-end",
                  }}
                >
                  <View>
                    <Text>{`Cumulative GPA: ${
                      calculateGPA(courses)[semester].cumulativeGPA
                    }`}</Text>
                    <Text>{`Semester GPA: ${
                      calculateGPA(courses)[semester].semesterGPA
                    }`}</Text>
                  </View>
                  <View>
                    <Text>{`Total Credits: ${
                      calculateGPA(courses)[semester].totalCredits
                    }`}</Text>
                    <Text>{`Total Points ${
                      calculateGPA(courses)[semester].totalPoints
                    }`}</Text>
                  </View>
                </View>
              </View>
            ))}
        </View>

        {/* Footer */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingTop: "20px",
            borderTop: "3px solid black",
            paddingBottom: "10px",
          }}
        >
          <View
            style={{
              width: "70%",
            }}
          >
            <Text
              style={{
                marginBottom: "10px",
              }}
            >
              Registrar's Signature:____________________________
            </Text>
            <Text>
              This record is valid only with th registrar's signature and the
              Meserete Kristos College seal.
            </Text>
            <Text>
              {`Grade Points: ${gradeInfo
                .map((item) => `${item.grade} = ${item.point}`)
                .join(", ")} * = Repeated course P = pass`}
            </Text>
            <Text>
              Grading: A = Excellent, B = above average, C = satisfactory, D =
              less than average, F = failing, WP = withdrawal pass, WF =
              withdrawal failing, I = incomplete
            </Text>
          </View>
          <View
            style={{
              width: "20%",
            }}
          >
            <Text>Office of the Registrar</Text>
            <Text>P.O. BOX 101488</Text>
            <Text>Addis Ababa, Ethiopia</Text>
            <Text>{`Date fo Issue: ${new Date()}`}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );


  return (
    <DashboardLayout>
      <Card>
        <CardContent>
          <PDFDownloadLink
            document={<MyDocument />}
            fileName={`${student.first_name}-${student.last_name}-grade-report.pdf`}
          >
            {({ blob, url, loading, error }) => (
              <Button
                style={{
                  margin: "20px",
                  padding: "5px 20px",
                  backgroundColor: "#00274D",
                  color: "#FFFFFF",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  fontSize: "13px",
                  transition: "background-color 0.3s",
                }}
                disabled={loading}
                onMouseOver={(e) =>
                  (e.currentTarget.style.backgroundColor = "#0056b3")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.backgroundColor = "#00274D")
                }
              >
                {loading ? "Loading..." : "Download PDF"}
              </Button>
            )}
          </PDFDownloadLink>

          <MDBox ref={cardRef} sx={{ overflow: "hidden" }}>
            <>
              <Grid container spacing={3} sx={{ padding: 2 }}>
                <Grid item xs={12} md={3}>
                  <MDBox display="flex" alignItems="center" gap={2}>
                    <Avatar
                      sx={{ width: 50, height: 50, marginRight: "20px" }}
                      src={`/mks-logo.png`}
                    />
                    <MDBox>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ color: "#222" }}
                      >
                        MKS SEMINARY
                      </Typography>
                    </MDBox>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={4}>
                  <MDBox display="flex" gap={2}>
                    <Avatar
                      sx={{ width: 100, height: 100, marginRight: "20px" }}
                      src={`${STORAGE_BASE_URL}/${student.profile_picture}`}
                    />
                    <MDBox>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ color: "#222" }}
                      >
                        Name: {student.first_name} {student.last_name}
                      </Typography>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ color: "#222" }}
                      >
                        Student Id: {student.id}
                      </Typography>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ color: "#222" }}
                      >
                        Sex: {student.gender}
                      </Typography>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ color: "#222" }}
                      >
                        Birth Date: {student.date_of_birth}
                      </Typography>
                    </MDBox>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ color: "#222" }}
                  >
                    Program of Study: {student.program}
                  </Typography>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ color: "#222" }}
                  >
                    Major: {student.major}
                  </Typography>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ color: "#222" }}
                  >
                    Graduation Date: {student.graduation_date}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ padding: 2 }}>
                {Object.keys(groupedCourses).length > 0 &&
                  Object.keys(groupedCourses).map((key) => {
                    let year, semester;
                    const parts = key.split("-");
                    if (parts.length >= 2) {
                      year = parts[0];
                      semester = parts.slice(1).join("-");
                    } else {
                      year = "Unknown";
                      semester = "Unknown";
                    }

                    return (
                      <Grid item xs={12} md={6} key={key}>
                        {renderCourseGroup(year, semester, groupedCourses[key])}
                      </Grid>
                    );
                  })
                }
                
              </Grid>
            </>
          </MDBox>
        </CardContent>
        <LoadingDialog open={loading} />
      </Card>
    </DashboardLayout>
  );
};

export default GradeReport;

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import API_BASE_URL from "api";
import axios from "axios";
import MDBox from 'components/MDBox';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { useEffect, useState } from "react";
import swal from 'sweetalert';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddStaffDialog from "./addStaff";
import EditStaffDialog from "./editStaff";
import LoadingDialog from "components/Loading/LoadingDialog";

const Staff = () => {
    const [search] = useState('');
    const token =  localStorage.getItem("authToken");
    
    const [showAlert, setShowAlert] = useState(false);
  
    const [openEdit, setOpenEdit] = useState(false);
    const[staff,setStaff] = useState([])
    const [selectedStaff, setSelectedStaff] = useState(null);
    const [openAdd, setOpenAdd] = useState(false);

    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deletingStatus, setDeletingStatus] = useState(false); 

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/employees`, {
          headers: { "Authorization": `Bearer ${token}` }
        });
        setEmployees(response.data);
      } catch (err) {
        console.error("Failed to fetch employees:", err);
      }
    };
    fetchEmployees();
  }, [token]);

  const getEmployeeById = (id) => {
    return employees.find(emp => emp.id === id) || null;
  };


    useEffect(() => {
        let timer;
        if (showAlert) {
          timer = setTimeout(() => {
            setShowAlert(false);
          }, 5000); // 5000 milliseconds = 5 seconds
        }
        return () => clearTimeout(timer); // Clean up the timer
      }, [showAlert]);
    
      useEffect(() => {
        const fetchStaff = async () => {
          try {
            const response = await axios.get(`${API_BASE_URL}/staff`, {
              headers: { "Authorization": `Bearer ${token}` }
            });
           
    
            setStaff(response.data);
          } catch (err) {
            console.error(err);
          } 
          finally{
            setLoading(false);
          }

        };
        fetchStaff();
      }, [token]);
    

      const handleAddStaff = (newStaff) => {
        
        setStaff((prevStaff) => [...prevStaff, newStaff]);
      };
      
    
      const handleEditStaff= (updatedStaff) => {
        setStaff(staff.map((staff) =>
          staff.id === updatedStaff.id ? updatedStaff: staff
        ));
      };
    

      const handleDeleteStaff = async (id) => {
        const confirm = await swal({
          title: "Are you sure?",
          text: "You will not be able to recover this status!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        });
    
        if (confirm) {
          setDeletingStatus(true);
          try {
            const response = await axios.delete(`${API_BASE_URL}/staff/${id}`,
              {
              headers: { "Authorization": `Bearer ${token}` }
            }
          );
            if(response.status === 204){
              setStaff(staff.filter((staff) => staff.id !== id));
              swal({
                title: "Staff Deleted Successfully!",
                // text: "Course Created Successfully!",
                icon: "success"
              });
            }
          } catch (error) {
          
            swal({
              title: `${error.response.data.message}`,
              icon: "warning"
            });
          }finally {
            setDeletingStatus(false); // End loading for deletion
          } 
        }
      };

      const handleOpenEdit = (staff) => {
        setSelectedStaff(staff);
        setOpenEdit(true);
      };
    
      const handleCloseDialog = () => {
        setOpenAdd(false);
        setOpenEdit(false);
        setSelectedStaff(null);
      };

      const filteredData = staff.filter(s => {
        return (
          s.name.toLowerCase().includes(search.toLowerCase())   
        );
      });
      const columns = [
        {
          accessor: 'action',
          Header: '',  
          width: 40, 
          padding:0,
          Cell: ({ row }) => (
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                    <MoreVertIcon />
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem onClick={() => {
                      handleOpenEdit(row.original);
                      popupState.close();
                    }}  style={{ color: '#1976d2' }}>
                      <EditIcon style={{ marginRight: 8 }} /> Edit
                    </MenuItem>
                    <MenuItem onClick={() => {
                      handleDeleteStaff(row.original.id);
                      popupState.close();
                    }}  style={{ color: '#d32f2f' }}>
                      <DeleteIcon style={{ marginRight: 8 }} /> Delete
                    </MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          ),
          showSortIcons: false,
          
          
        },
        { Header: 'Staff Name', accessor: 'name' },
        { Header: 'description', accessor: 'description',showSortIcons: false, },
        {
          Header: 'Staff Head',
          accessor: 'staff_head',
          showSortIcons: false,
          Cell: ({ value }) => {
            const employee = getEmployeeById(value);
            return employee 
              ? `${employee.first_name} ${employee.last_name}`
              : '';
          },
        },
    
      ];
  return (
    <DashboardLayout>
        <MDBox>
        <MDTypography variant="h5" fontWeight="medium">
           Staff
         </MDTypography>
         <MDBox>
                <DataTable
                  table={{
                    columns,
                    rows: filteredData,
                  }}
                  canSearch={true}
                  sortable={true}
                  rowsPerPage={5}
                  showPagination
                  widgets = {
                    <MDBox mx={2}>
                   <MDButton
                   variant="gradient"
                   ml="auto"
                       color="info"
                       size="small"
                       onClick={() => setOpenAdd(true)}
                       sx={{background:"#00274D", my:4,mx:3}}
                 >
                   <span>Add</span>
                 </MDButton>
                 </MDBox>
                  }
                />
         </MDBox>
         <AddStaffDialog open={openAdd} onClose={handleCloseDialog} onAdd={handleAddStaff} />
        
        {selectedStaff && (
        <EditStaffDialog
            open={openEdit}
            onClose={handleCloseDialog}
            staff={selectedStaff}
            onEdit={handleEditStaff}
        />
        )}
        
        <LoadingDialog open={loading || deletingStatus} />
        </MDBox>
    </DashboardLayout>
  )
}

export default Staff
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Avatar } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import API_BASE_URL, { STORAGE_BASE_URL } from 'api';
import axios from 'axios';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DataTable from 'examples/Tables/DataTable';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const EmployeesList = () => {
  const [search] = useState('');
  const[employees,setEmployees] = useState([])
  const navigate = useNavigate();
  const token =  localStorage.getItem("authToken");




  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/employees`, 
          
          {
          headers: { "Authorization": `Bearer ${token}` }
        });


      
        setEmployees(response.data);
      } catch (err) {
      } finally {
      }
    };
    fetchEmployees();
  }, [token]);
  
  const filteredData = employees.filter(registrar => {
    return (
      registrar.first_name.toLowerCase().includes(search.toLowerCase()) ||
      registrar.email.toLowerCase().includes(search.toLowerCase()) 
     
    );

  });


 
  const columns = [ 
    {
      accessor: 'action',
      Header: '', 
      width:40,
      Cell: ({ row }) => (
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button color='secondary' {...bindTrigger(popupState)} style={{ padding: 0, minWidth: 0 }}>
                <MoreVertIcon />
              </Button>
              <Menu {...bindMenu(popupState)}>

             <MenuItem onClick={() => {
    navigate(`/employees-list/${row.original.id}`);
}}>View Detail</MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      ),
      showSortIcons: false,
      
    },
    {
      Header: "Profile",
      id: "profile",
      accessor: row  => (
        <Avatar src={`${STORAGE_BASE_URL}/${row.profile_picture}`} />
      ),
      sortable: false,
      showSortIcons: false
    },
    {
      Header: 'Name',
      accessor: row => `${row.first_name} ${row.last_name}`, // Custom accessor for full name
      id: 'full_name', // Optional: Use an id for the column
      sorted: "none",
    },
    { Header: 'Telephone', accessor: 'phone', showSortIcons: false },
    { Header: 'Gender', accessor: 'gender',showSortIcons: false },
    { Header: 'Email', accessor: 'email',showSortIcons: false },
    { Header: 'Employement Date', accessor: 'employment_date',showSortIcons: false },

    { Header: 'Position', accessor: 'position',showSortIcons: false },
    { Header: 'Employement Type', accessor: 'employment_type',showSortIcons: false },

  ];
  
  return (
    
    <DashboardLayout>
            <MDBox>
            <Card >
          <CardContent>
            <MDTypography variant="h5" fontWeight="medium">
              Employees
            </MDTypography>
              <MDBox mt={1}>
                <DataTable
                  table={{
                    columns,
                    rows: filteredData,
                  }}
                  canSearch={true}
                  sortable={true}
                  // pagination
                  rowsPerPage={5}
                  showPagination
                  widgets = {
                    <MDBox ms={2}>
                   
                 
                  </MDBox>
                  }
                />
              </MDBox>
          </CardContent>
          </Card>
        </MDBox>
    </DashboardLayout>
  )
}

export default EmployeesList;
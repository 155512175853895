import React,{useState} from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import DataTable from 'examples/Tables/DataTable';
import DialogActions from "@mui/material/DialogActions";
import MDButton from "components/MDButton";
import StoreRequestApprovalModal from '../approval';

const ViewRequest = ({openView, onClose,detailData}) => {
   
      
    const columns = [
        { Header: 'Asset Code', accessor: 'asset.code' }, 
        { Header: 'Asset Name', accessor: 'asset.name' },    
        { Header: 'Quantity', accessor: 'quantity' },
        { Header: 'Remark', accessor: 'remark' },
      ];

      const rows = detailData.lines ? detailData.lines.map(line => ({
        ...line,
        asset: line.asset
    })) : [];

    const [openApprove, setOpenApprove] = useState(false);

     const handleApprove = () => {
        setOpenApprove(true);
     }
     const handleApproveClose = () => {
        setOpenApprove(false);
        onClose();
     }
  return (
    <Dialog open={openView} onClose={onClose} maxWidth="md" fullWidth>
    <DialogTitle textAlign="center">
        View Details
    </DialogTitle>
    <DialogContent>
        <DataTable
            table={{
                columns,
                rows
            }}
            canSearch={true}
            sortable={true}
            pagination
            rowsPerPage={5}
            showPagination
        />
            </DialogContent>
            <DialogActions>
        { detailData.approved_by ? null :   <MDButton
          variant="gradient"
          size="small"
          color="info"
          sx={{ background: "#00274D" }}
          onClick={handleApprove}
        >
          Approve
        </MDButton>}
        <MDButton
        variant="gradient"
        size="small"
        color="black"
        sx={{ background: "grey" }}
        onClick={onClose}
        >
        Close
        </MDButton>
        </DialogActions>
        <StoreRequestApprovalModal open={openApprove} detailData={detailData}  onClose={handleApproveClose} />

        </Dialog>
        )

        }

export default ViewRequest
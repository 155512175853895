import { Box, CircularProgress, Dialog, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { DataGrid } from "@mui/x-data-grid";
import {
    Document,
    Image,
    PDFDownloadLink,
    Page,
    StyleSheet,
    Text,
    View,
} from "@react-pdf/renderer";
import { STORAGE_BASE_URL } from "api";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";

export default function GradeReportDetails({ student, courses, open, onClose }) {
    const [selectedSemester, setSelectedSemester] = useState(null);

    

    const groupCoursesByYearAndSemester = (courses) => {
        return courses.reduce((acc, course) => {
            const key = `${course.year} - ${course.semester}`;
            if (!acc[key]) {
                acc[key] = { courses: [] };
            }
            acc[key].courses.push(course);
            return acc;
        }, {});
    };

    const groupedCourses = groupCoursesByYearAndSemester(courses);

    const calculatePointsAndGPA = (groupedCourses) => {
        let cumulativePoints = 0;
        let cumulativeCredits = 0;

        Object.keys(groupedCourses).forEach((key, index) => {
            const semesterData = groupedCourses[key];
            let totalCredits = 0;
            let totalPoints = 0;

            semesterData.courses.forEach((course) => {
                totalCredits += course.credit_hour;
                totalPoints += course.grade ? course.grade.point : 1 * course.credit_hour;
            });

            // Semester GPA calculation
            const semesterGPA = totalCredits ? (totalPoints / totalCredits).toFixed(2) : "N/A";

            // Previous semester calculation
            groupedCourses[key].previousCredits = cumulativeCredits
            groupedCourses[key].previousPoints = cumulativePoints;
            if (index > 0) {
                const previousKey = Object.keys(groupedCourses)[index - 1];
                semesterData.previousGPA = groupedCourses[previousKey].cumulativeGPA;
            } else {
                semesterData.previousGPA = "N/A"; // No previous semester for the first entry
            }

            // Update cumulative points and credits
            cumulativePoints += totalPoints;
            cumulativeCredits += totalCredits;

            // Cumulative GPA calculation
            const cumulativeGPA = cumulativeCredits ? (cumulativePoints / cumulativeCredits).toFixed(2) : "N/A";

            // Store the calculated values back into the group
            groupedCourses[key].totalCredits = totalCredits;
            groupedCourses[key].totalPoints = totalPoints;
            groupedCourses[key].semesterGPA = semesterGPA;
            groupedCourses[key].cumulativeGPA = cumulativeGPA;
            groupedCourses[key].cumulativePoints = cumulativePoints;
            groupedCourses[key].cumulativeCredits = cumulativeCredits;
        });
        
         console.log(groupedCourses);
         
        return groupedCourses;
    };

    const calculatedGroups = calculatePointsAndGPA(groupedCourses);

    useEffect(() => {
        if (!selectedSemester && Object.keys(groupedCourses).length > 0) {
            setSelectedSemester(Object.keys(groupedCourses)[0]); // Set the first semester by default
        }
    }, [groupedCourses, selectedSemester]);

    const renderCourseGroup = (year, semester, semesterData) => {
        return (
            <Box key={`${year} - ${semester}`} sx={{ padding: 2 }}>
                <Typography variant="h6" gutterBottom sx={{ color: "#222" }}>
                    {year} - {semester}
                </Typography>
                <div className="data-grid-wrapper">
                    <DataGrid
                        rows={[
                            ...semesterData.courses.map((course, index) => ({
                                id: index,
                                course_number: course.course_number,
                                course_name: course.course_name,
                                credit_hour: course.credit_hour,
                                grade: course.grade ? course.grade.grade : "N/A",
                                point: course.grade ?  course.grade.point : "N/A",
                            })),
                            {
                                id: "total",
                                course_number: `Semester GPA: ${semesterData.semesterGPA}`,
                                course_name: "Sum Totals",
                                credit_hour: semesterData.totalCredits,
                                grade: "",
                                point: semesterData.totalPoints,
                            },
                            {
                                id: "cumulative",
                                course_number: `Cumulative GPA: ${semesterData.cumulativeGPA}`,
                                course_name: "Cumulative Totals",
                                credit_hour: semesterData.cumulativeCredits,
                                grade: "",
                                point: semesterData.cumulativePoints,
                            },
                        ]}
                        columns={[
                            { field: "course_number", headerName: "Course No", width: 200 },
                            { field: "course_name", headerName: "Name", width: 300 },
                            { field: "grade", headerName: "Grade", width: 100 },
                            { field: "credit_hour", headerName: "Credit", width: 100 },
                            { field: "point", headerName: "Point", width: 100 },
                        ]}
                        pageSize={5}
                        hideFooter={true}
                        rowsPerPageOptions={[5]}
                        sx={{
                            backgroundColor: "white",
                            border: "none",
                            "& .MuiDataGrid-cell": { border: "none" },
                            "& .MuiDataGrid-row": { fontWeight: "normal", backgroundColor: "transparent" },
                        }}
                    />
                </div>

                <hr
                    style={{
                        margin: "10px 0",
                        border: "none",
                        borderTop: "1px solid #222",
                    }}
                />
            </Box>
        );
    };

    const styles = StyleSheet.create({
        page: {
            padding: 30,
            fontSize: 10,
            borderBottom: "5px solid black",
        },
        header: {
            borderBottom: "3px solid black",
            paddingBottom: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-end",
        },
        tableContainer: {
            display: "flex",
            flexDirection: "column",
            paddingTop: "10px",
            paddingBottom: "10px",
            borderBottom: "1px solid gray",
        },
        tableRow: {
            display: "flex",
            flexDirection: "row",
            paddingBottom: "12px",
        },
        tableCell: {
            flex: 1,
        },
        summaryTable: {
            width: "50%",
            marginLeft: "auto"
        },
        summaryCell: {
            textAlign: "right",
        }
    });

    const MyDocument = ({ student, groupedCourses, selectedSemester }) => (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.header}>
                    <View style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                        <Image style={{ width: "50px", height: "50px" }} src={{ uri: "/mks-logo.png" }} />
                        <View>
                            <Text style={{ fontWeight: "bold", fontSize: "16px", marginBottom: "10px" }}>
                                Meserete Kristos College
                            </Text>
                            <Text>Student Grade Report</Text>
                        </View>
                    </View>
                    <Text>{selectedSemester && selectedSemester}</Text>
                </View>

                {student && (
                    <View style={{ display: "flex", flexDirection: "row", gap: "20px", alignItems: "center", borderBottom: "1px solid gray" }}>
                        <Image style={{ width: "50px", height: "50px" }} src={{ uri: `${STORAGE_BASE_URL}/${student.profile_picture}`, method: "GET" }} />
                        <View style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                            <View style={{ display: "flex", flexDirection: "col", gap: "3px" }}>
                                <Text>Name:</Text>
                                <Text>Student ID:</Text>
                            </View>
                            <View style={{ display: "flex", flexDirection: "col", gap: "3px" }}>
                                <Text>{`${student.first_name} ${student.last_name}`}</Text>
                                <Text>student.id</Text>
                            </View>
                        </View>
                        <View style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                            <View style={{ display: "flex", flexDirection: "col", gap: "3px" }}>
                                <Text>Program:</Text>
                                <Text>Class:</Text>
                            </View>
                            <View style={{ display: "flex", flexDirection: "col", gap: "3px" }}>
                                <Text>{student.program.program_name}</Text>
                                <Text>{student.class}</Text>
                            </View>
                        </View>
                    </View>
                )}

                <View style={styles.tableContainer}>
                    <View style={[styles.tableRow]}>
                        <Text style={styles.tableCell}>Course</Text>
                        <Text style={[styles.tableCell, { flex: 3 }]}>Course Name</Text>
                        <Text style={[styles.tableCell, { textAlign: 'center' }]}>Credit Hours</Text>
                        <Text style={[styles.tableCell, { textAlign: 'center' }]}>Grade</Text>
                        <Text style={[styles.tableCell, { textAlign: 'right' }]}>Points</Text>
                    </View>

                    {groupedCourses && selectedSemester && groupedCourses[selectedSemester].courses.map((item, index) => (
                        <View style={styles.tableRow} key={index}>
                            <Text style={styles.tableCell}>{item.course_number}</Text>
                            <Text style={[styles.tableCell, { flex: 3 }]}>{item.course_name}</Text>
                            <Text style={[styles.tableCell, { textAlign: 'center' }]}>{item.credit_hour}</Text>
                            <Text style={[styles.tableCell, { textAlign: 'center' }]}>{item.grade ? item.grade.grade :"N/A"}</Text>
                            <Text style={[styles.tableCell, { textAlign: 'right' }]}>{item.grade ? item.grade.point :"N/A" }</Text>
                        </View>
                    ))}
                </View>

                {/* Summary section */}
                {groupedCourses && selectedSemester && <View style={[styles.tableContainer, styles.summaryTable]}>
                    <View style={styles.tableRow}>
                        <Text style={[styles.tableCell, styles.summaryCell]}></Text>
                        <Text style={[styles.tableCell, styles.summaryCell]}>Credit Hours</Text>
                        <Text style={[styles.tableCell, styles.summaryCell]}>Points</Text>
                        <Text style={[styles.tableCell, styles.summaryCell]}>GPA</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={[styles.tableCell, styles.summaryCell]}>Current</Text>
                        <Text style={[styles.tableCell, styles.summaryCell]}>{groupedCourses[selectedSemester].totalCredits}</Text>
                        <Text style={[styles.tableCell, styles.summaryCell]}>{groupedCourses[selectedSemester].totalPoints}</Text>
                        <Text style={[styles.tableCell, styles.summaryCell]}>{groupedCourses[selectedSemester].semesterGPA}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={[styles.tableCell, styles.summaryCell]}>Previous</Text>
                        <Text style={[styles.tableCell, styles.summaryCell]}>{groupedCourses[selectedSemester].previousCredits}</Text>
                        <Text style={[styles.tableCell, styles.summaryCell]}>{groupedCourses[selectedSemester].previousPoints}</Text>
                        <Text style={[styles.tableCell, styles.summaryCell]}>{groupedCourses[selectedSemester].previousGPA}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={[styles.tableCell, styles.summaryCell]}>Cumulative</Text>
                        <Text style={[styles.tableCell, styles.summaryCell]}>{groupedCourses[selectedSemester].cumulativeCredits}</Text>
                        <Text style={[styles.tableCell, styles.summaryCell]}>{groupedCourses[selectedSemester].cumulativePoints}</Text>
                        <Text style={[styles.tableCell, styles.summaryCell]}>{groupedCourses[selectedSemester].cumulativeGPA}</Text>
                    </View>
                </View>}
            </Page>
        </Document>
    );

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xl">
            <DialogTitle>{`Grade Reports - ${student.first_name} ${student.last_name}`}</DialogTitle>
            <DialogContent>
                <FormControl>
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                        Select Semester
                    </MDTypography>
                    <Select
                        name="year_id"
                        value={selectedSemester}
                        onChange={(e) => setSelectedSemester(e.target.value)} // Trigger state update
                        style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                        {Object.keys(groupedCourses).map((item, index) => {
                            return (
                                <MenuItem key={index} value={item}>
                                    {item}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>

                {calculatedGroups && selectedSemester && calculatedGroups[selectedSemester] && (
                    renderCourseGroup(
                        selectedSemester.split("-")[0],
                        selectedSemester.split("-")[1],
                        calculatedGroups[selectedSemester] // Pass the updated semester data
                    )
                )}

                {courses.length === 0 && <Box display="flex" justifyContent="center" py={5}><CircularProgress /></Box>}
            </DialogContent>

            <DialogActions>
                <PDFDownloadLink
                    document={
                        <MyDocument
                            student={student}
                            groupedCourses={groupedCourses}
                            selectedSemester={selectedSemester}
                        />
                    }
                    fileName={`Grade_Report_${student.first_name}_${student.last_name}.pdf`}

                >
                    <MDButton
                        style={{
                            margin: "20px",
                            padding: "5px 20px",
                            backgroundColor: "#00274D",
                            color: "#FFFFFF",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                            fontSize: "13px",
                            transition: "background-color 0.3s",
                        }}
                        onMouseOver={(e) =>
                            (e.currentTarget.style.backgroundColor = "#0056b3")
                        }
                        onMouseOut={(e) =>
                            (e.currentTarget.style.backgroundColor = "#00274D")
                        }
                    >
                    Download PDF
                    </MDButton>
                </PDFDownloadLink>
            </DialogActions>
        </Dialog>
    );
}
